import React, { Component } from "react";
import {
  Paper,
  CssBaseline,
  FormControl,
  TextField,
  withStyles,
  MenuItem,
  InputLabel,
  Select
} from "@material-ui/core";

const styles = theme => ({
  main: {
    display: "flex",
    width: "100%",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(8)
  },
  paper: {
    marginTop: theme.spacing(),
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
  }
});

class JobBudget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      ...props.state
    };
  }

  onChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
    this.props.handleState(event);
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <main className={classes.main}>
          <CssBaseline />
          <Paper className={classes.paper}>
            <form>
              <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="age-simple"> Work Experience (Years) </InputLabel>
                <Select
                  autoWidth
                  value={this.state.experienceInMonths}
                  onChange={this.onChange}
                  inputProps={{
                    name: "experienceInMonths",
                    id: "experienceInMonths-simple"
                  }}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "right"
                    }
                  }}
                  style={{
                    textAlign: "left"
                  }}>
                  <MenuItem value={12}> 1 Yr </MenuItem>
                  <MenuItem value={24}> 2 Yrs </MenuItem>
                  <MenuItem value={36}> 3 yrs </MenuItem>
                  <MenuItem value={48}> 4 yrs </MenuItem>
                  <MenuItem value={60}> 5 yrs </MenuItem>
                  <MenuItem value={72}> 6 yrs </MenuItem>
                  <MenuItem value={84}> 7 yrs </MenuItem>
                  <MenuItem value={96}> 8 yrs </MenuItem>
                  <MenuItem value={108}> 9 yrs </MenuItem>
                  <MenuItem value={120}> 10 yrs or more...</MenuItem>
                </Select>
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <TextField
                  multiline={true}
                  rows={8}
                  variant="outlined"
                  placeholder="General"
                  name="general"
                  value={this.state.general.value}
                  onChange={this.onChange}
                />
              </FormControl>
            </form>
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}
export default withStyles(styles, {
  withTheme: true
})(JobBudget);
