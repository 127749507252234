import React from "react";
import { withAuthorization } from "../../Session";
import {
  CardHeader,
  CardContent,
  Card,
  withStyles,
  Avatar,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Button,
  Chip
} from "@material-ui/core";
import SchoolIcon from "@material-ui/icons/SchoolOutlined";
import AddressIcon from "@material-ui/icons/MyLocation";
import WorkIcon from "@material-ui/icons/WorkOutlineOutlined";
import SkillIcon from "@material-ui/icons/LanguageOutlined";
import ProfileIcon from "@material-ui/icons/PersonOutlined";
import ReferenceIcon from "@material-ui/icons/SentimentVerySatisfied";
import RightArrowIcon from "@material-ui/icons/PlayCircleOutline";
import CloseIcon from "@material-ui/icons/Cancel";
import moment from "moment";
import { withApi } from "../../Api";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import ReactPlayer from "react-player";

const styles = theme => ({
  card: {
    marginBottom: "10px",
    width: "98%",
    transitionDuration: "0.3s"
  },
  media: {},
  actions: {
    display: "flex"
  },
  avatar: {
    backgroundColor: "#B9090B"
  },
  phone: {
    marginLeft: "2%",
    float: "right",
    cursor: "none"
  },
  location: {
    cursor: "none",
    float: "left",
    marginRight: "2%"
  },
  right: {
    marginLeft: "2%",
    float: "right"
  }
});

class ProfileView extends React.Component {
  profileLoaded = false;
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      profile: null,
      videoProfileURL: ""
    };
  }

  componentDidMount() {
    this.profileLoaded = true;
    if (this.props.id !== null && this.props.id !== undefined) {
      this.props.api.getViewProfile(this.props.id).then(profile => {
        if (this.profileLoaded) {
          this.setState({
            profile: profile
          });
          if (this.state.profile.videoProfile !== "" && this.state.profile.videoProfile !== null) {
            this.setState({
              videoProfileURL: this.state.profile.videoProfile
            });
          }
        }
      });
    }
  }

  componentWillUnmount() {
    this.profileLoaded = false;
  }

  render() {
    const { classes } = this.props;
    const formattedPhoneObject =
      this.state.profile !== null && this.state.profile !== undefined && this.state.profile.phone !== null && this.state.profile.phone !== undefined
        ? parsePhoneNumberFromString(this.state.profile.phone, "US")
        : null;
    const formattedPhone = formattedPhoneObject ? formattedPhoneObject.formatInternational() : "";
    return (
      <React.Fragment>
        {this.state.profile !== null ? (
          <Dialog
            open={this.props.open ? true : false}
            fullWidth={true}
            classes={{
              paper: classes.dialogPaper
            }}
            transitionDuration={{
              enter: 1000,
              exit: 0
            }}
          >
            <DialogTitle disableTypography={true}>
              <Grid container alignItems="center" justify="center">
                <Grid item xs={11}>
                  <Typography variant="h5" style={{ textAlign: "center" }}>
                    {this.state.profile.firstName}&nbsp;{this.state.profile.lastName}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <CloseIcon aria-label="Close" color="primary" onClick={this.props.handleProfileClose()} style={{ float: "right" }} />
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent align="center">
              <Card className={classes.card}>
                <CardHeader
                  avatar={
                    <label htmlFor="profileImage">
                      {this.state.profile.avatar === "" ? (
                        <Avatar className={classes.avatar}>
                          <ProfileIcon fontSize="large" />
                        </Avatar>
                      ) : (
                        <Avatar alt="Profile" className={classes.profile} src={this.state.profile.avatar} />
                      )}
                    </label>
                  }
                  title={
                    <Typography className={classes.location} variant="h6">
                      Basic Info
                    </Typography>
                  }
                />
                <CardContent>
                  {this.state.videoProfileURL !== "" ? (
                    <Grid container alignContent="center" alignItems="center" direction="column">
                      <ReactPlayer url={this.state.videoProfileURL} controls="true" width="85%"/>
                    </Grid>
                  ) : null}
                  <Grid container alignContent="center" alignItems="center" direction="column">
                    <div
                      style={{
                        width: "50%",
                        background: "#FBEEE6",
                        padding: "10px",
                        borderRadius: "2%"
                      }}
                    >
                      <Grid item xs={12}>
                        <Typography variant="body1">
                          {this.state.profile.firstName}&nbsp;{this.state.profile.lastName}
                        </Typography>
                        <Typography variant="body1">{this.state.profile.profileTitle}</Typography>
                        <Typography variant="body1">{formattedPhone}</Typography>
                        <Typography variant="body1">{this.state.profile.email}</Typography>
                        <Typography variant="body1">
                          D.O.B&nbsp;
                          {this.state.profile.dob !== "" && this.state.profile.dob !== undefined ? this.state.profile.dob : "..."}
                        </Typography>
                      </Grid>
                    </div>
                  </Grid>
                </CardContent>
                <CardContent>
                  <Typography variant="body1">{this.state.profile.aboutMe}</Typography>
                </CardContent>
                <CardContent>
                  <Grid container spacing={1}>
                    {this.state.basicInfoDocs !== undefined
                      ? this.state.basicInfoDocs.map((item, index) => (
                          <Grid key={index} item xs={12} style={{ background: "#FBEEE6", margin: "5px" }}>
                            <Typography variant="body1">
                              {item.fileName}
                              <Button
                                color="primary"
                                variant="outlined"
                                style={{ float: "right", padding: "10px", borderRadius: "2%" }}
                                onClick={() => this.downloadProfileDocument(item.key)}
                              >
                                download
                              </Button>
                            </Typography>
                          </Grid>
                        ))
                      : null}
                  </Grid>
                </CardContent>
              </Card>
              <Card className={classes.card}>
                <CardHeader
                  avatar={
                    <label htmlFor="profileImage">
                      {
                        <Avatar className={classes.avatar}>
                          <AddressIcon fontSize="large" />
                        </Avatar>
                      }
                    </label>
                  }
                  title={
                    <Typography className={classes.location} variant="h6">
                      Address
                    </Typography>
                  }
                />
                <CardContent>
                  <Grid container justify="center" spacing={1}>
                    {this.state.profile.address !== undefined && this.state.profile.address !== null
                      ? this.state.profile.address.map((item, index) => (
                          <Grid key={index} item xs={12}>
                            <Typography variant="subtitle1" gutterBottom>
                              <IconButton color="primary">
                                <RightArrowIcon />
                              </IconButton>
                              &nbsp;
                              {item.address1} {item.address2} {item.city} {item.state} {item.country} {item.zip}
                            </Typography>
                          </Grid>
                        ))
                      : null}
                  </Grid>
                </CardContent>
              </Card>
              <Card className={classes.card}>
                <CardHeader
                  avatar={
                    <label htmlFor="profileImage">
                      {
                        <Avatar className={classes.avatar}>
                          <SchoolIcon fontSize="large" />
                        </Avatar>
                      }
                    </label>
                  }
                  title={
                    <Typography className={classes.location} variant="h6">
                      Education
                    </Typography>
                  }
                />
                <CardContent>
                  <Grid container justify="center" spacing={1}>
                    {this.state.profile.education !== undefined && this.state.profile.education !== null
                      ? this.state.profile.education.map((item, index) => (
                          <Grid key={index} item xs={12}>
                            <Typography variant="subtitle1" gutterBottom>
                              <IconButton color="primary">
                                <RightArrowIcon />
                              </IconButton>
                              &nbsp;
                              {item.schoolName},&nbsp;
                              {item.educationType === "HIGH_SCHOOL"
                                ? "High School"
                                : item.educationType === "VOCATIONAL_TRADE_SCHOOL"
                                ? "Vocational Trade School"
                                : item.educationType === "GED_PROGRAM"
                                ? "GED Program"
                                : item.educationType === "ASSOCIATE_DEGREE_PROGRAM"
                                ? "Associate Degree Program"
                                : item.educationType === "BACHELORS_DEGREE_PROGRAM"
                                ? "Bachelors Degree"
                                : item.educationType === "MASTERS_DEGREE_PROGRAM"
                                ? "Masters Degree"
                                : item.educationType === "DOCTORATE_PROGRAM"
                                ? "Doctorate Program"
                                : ""}
                              , &nbsp; {item.degree}, &nbsp; ({moment(item.startYear, "YYYY").format("YYYY")} &nbsp;-&nbsp;
                              {moment(item.endYear, "YYYY").format("YYYY")})
                            </Typography>
                          </Grid>
                        ))
                      : null}
                  </Grid>
                </CardContent>
                <CardContent>
                  <Grid container spacing={1}>
                    {this.state.educationDocs !== undefined
                      ? this.state.educationDocs.map((item, index) => (
                          <Grid key={index} item xs={12} style={{ background: "#FBEEE6", margin: "5px", padding: "10px", borderRadius: "2%" }}>
                            <Typography variant="body1">
                              {item.fileName}
                              <Button
                                color="primary"
                                variant="outlined"
                                style={{ float: "right" }}
                                onClick={() => this.downloadProfileDocument(item.key)}
                              >
                                download
                              </Button>
                            </Typography>
                          </Grid>
                        ))
                      : null}
                  </Grid>
                </CardContent>
              </Card>
              <Card className={classes.card}>
                <CardHeader
                  avatar={
                    <label htmlFor="profileImage">
                      {
                        <Avatar className={classes.avatar}>
                          <WorkIcon fontSize="large" />
                        </Avatar>
                      }
                    </label>
                  }
                  title={
                    <Typography className={classes.location} variant="h6">
                      Work Experience
                    </Typography>
                  }
                />
                <CardContent>
                  <Grid container spacing={1}>
                    {this.state.profile.experience !== undefined && this.state.profile.experience !== null
                      ? this.state.profile.experience.map((item, index) => (
                          <Grid key={index} item xs={12}>
                            <Typography variant="subtitle1" gutterBottom>
                              <IconButton color="primary">
                                <RightArrowIcon />
                              </IconButton>
                              &nbsp;
                              {item.company},&nbsp;{item.jobTitle},&nbsp; ( {moment(item.startDate).format("MMMM YYYY")} )
                            </Typography>
                          </Grid>
                        ))
                      : null}
                  </Grid>
                </CardContent>
                <CardContent>
                  <Grid container spacing={1}>
                    {this.state.workExpDocs !== undefined
                      ? this.state.workExpDocs.map((item, index) => (
                          <Grid key={index} item xs={12} style={{ background: "#FBEEE6", margin: "5px", padding: "10px", borderRadius: "2%" }}>
                            <Typography variant="body1">
                              {item.fileName}
                              <Button
                                color="primary"
                                variant="outlined"
                                style={{ float: "right" }}
                                onClick={() => this.downloadProfileDocument(item.key)}
                              >
                                download
                              </Button>
                            </Typography>
                          </Grid>
                        ))
                      : null}
                  </Grid>
                </CardContent>
              </Card>
              <Card className={classes.card}>
                <CardHeader
                  avatar={
                    <label htmlFor="profileImage">
                      {
                        <Avatar className={classes.avatar}>
                          <SkillIcon fontSize="large" />
                        </Avatar>
                      }
                    </label>
                  }
                  title={
                    <Typography className={classes.location} variant="h6">
                      Skills
                    </Typography>
                  }
                />
                <CardContent>
                  <Grid container alignContent="center" alignItems="center">
                    <Grid item xs={12}>
                      {this.state.profile.skill != null && this.state.profile.skill !== undefined
                        ? this.state.profile.skill.map((item, index) => (
                            <Chip size="small" key={index} variant="outlined" label={item.skillName} style={{ margin: "1px" }} />
                          ))
                        : ""}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card className={classes.card}>
                <CardHeader
                  avatar={
                    <label htmlFor="profileImage">
                      {
                        <Avatar className={classes.avatar}>
                          <ReferenceIcon fontSize="large" />
                        </Avatar>
                      }
                    </label>
                  }
                  title={
                    <Typography className={classes.location} variant="h6">
                      References
                    </Typography>
                  }
                />
                <CardContent>
                  <Grid container justify="center" spacing={1}>
                    {this.state.profile.references !== undefined && this.state.profile.references !== null
                      ? this.state.profile.references.map((item, index) => (
                          <Grid key={index} item xs={12}>
                            <Typography variant="subtitle1" gutterBottom>
                              <IconButton color="primary">
                                <RightArrowIcon />
                              </IconButton>
                              &nbsp;{item.firstName}&nbsp; {item.lastName},&nbsp;
                              {item.relationShip === "FORMER_EMPLOYER"
                                ? "Former Employer"
                                : item.relationShip === "COLLEAGUE"
                                ? "Colleague"
                                : item.relationShip === "TEACHER"
                                ? "Teacher"
                                : item.relationShip === "ADVISOR"
                                ? "Advisor"
                                : item.relationShip === "FORMER_SUPERVISOR"
                                ? "Former Supervisor"
                                : item.relationShip === "FRIEND"
                                ? "Friend"
                                : item.relationShip === "OTHER"
                                ? "Other"
                                : ""}
                              ,&nbsp;
                              {item.email},&nbsp;
                              {parsePhoneNumberFromString(item.phoneNumber, "US").formatInternational()}
                            </Typography>
                          </Grid>
                        ))
                      : null}
                  </Grid>
                </CardContent>
              </Card>
            </DialogContent>
          </Dialog>
        ) : null}
      </React.Fragment>
    );
  }
}

const condition = authUser => !!authUser;
export default withStyles(styles)(withApi(withAuthorization(condition)(ProfileView)));
