import React, { Component } from "react";
import { Route, withRouter, Switch } from "react-router-dom";
import AuthHeader from "../SignIn/authHeader";
import RegistrationSuccessful from "../SignIn/registrationSuccessful";
import ResetPasswordMessage from "../SignIn/resetPasswordMessage";
import LinkAccountMessage from "../SignIn/linkAccountMessage";
import ResetPassword from "../SignIn/resetPassword";
import RoleSelector from "../SignIn/roleSelector";
import Terms from "../SignIn/terms";
import Home from "../Home";
import NotFound from "../../common/notFound";
import * as Routes from "../../common/routes";
import { withAuthentication } from "../Session";
import { isMobile } from "react-device-detect";
import Images from "../../assets";
import { Typography } from "@material-ui/core";

class App extends Component {
  render() {
    return (
      <React.Fragment>
        {isMobile === true ? (
          <div style={{ textAlign: "center", padding: "2%" }}>
            <img src={Images.logo} width="15%" alt="bhiredinc" />
            <Typography variant="h1" style={{ padding: "2%" }}>
              Hello User, Please download BHIRED mobile app for best user experience.
            </Typography>
          </div>
        ) : (
          <Switch>
            <Route exact path={Routes.AUTH_HEADER} component={AuthHeader} />
            <Route exact path={Routes.REGISTRATION_SUCCESSFUL} component={RegistrationSuccessful} />
            <Route exact path={Routes.LINKED_ACCOUNT_MESSAGE} component={LinkAccountMessage} />
            <Route exact path={Routes.RESET_PASSWORD_MESSAGE} component={ResetPasswordMessage} />
            <Route exact path={Routes.RESET_PASSWORD} component={ResetPassword} />
            <Route exact path={Routes.TERMS_AND_CONDITIONS} component={Terms} />
            <Route exact path={Routes.ROLE_SELECTOR} component={RoleSelector} />
            <Route exact path={Routes.HOME} component={Home} />
            <Route exact path={Routes.DASHBOARD} component={Home} />
            <Route exact path={Routes.PROFILE} component={Home} />
            <Route exact path={Routes.JOBS} component={Home} />
            <Route exact path={Routes.CANDIDATES} component={Home} />
            <Route exact path={Routes.WALLET} component={Home} />
            <Route exact path={Routes.HIVE} component={Home} />
            <Route exact path={Routes.ASSESSMENTS} component={Home} />
            <Route component={NotFound} />
          </Switch>
        )}
      </React.Fragment>
    );
  }
}
export default withAuthentication(withRouter(App));
