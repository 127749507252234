import React from "react";
import { withAuthorization } from "../../Session";
import { CardHeader, CardContent, Card, withStyles, Avatar, IconButton, Typography, CssBaseline, Grid, Button, Chip } from "@material-ui/core";
import EditIcon from "@material-ui/icons/EditOutlined";
import SchoolIcon from "@material-ui/icons/SchoolOutlined";
import AddressIcon from "@material-ui/icons/MyLocation";
import WorkIcon from "@material-ui/icons/WorkOutlineOutlined";
import SkillIcon from "@material-ui/icons/LanguageOutlined";
import ProfileIcon from "@material-ui/icons/PersonOutlined";
import ReferenceIcon from "@material-ui/icons/SentimentVerySatisfied";
import RightArrowIcon from "@material-ui/icons/PlayCircleOutline";
import moment from "moment";
import { withApi } from "../../Api";
import LoadingIndicator from "../../../common/loading";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import EditProfile from "./editProfile";
import ReactPlayer from "react-player";

const styles = theme => ({
  root: {
    height: "100%",
    width: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    ...theme.mixins.toolbar
  },
  card: {
    marginBottom: "10px",
    width: "98%",
    transitionDuration: "0.3s"
  },
  media: {},
  actions: {
    display: "flex"
  },
  avatar: {
    backgroundColor: "#B9090B"
  },
  phone: {
    marginLeft: "2%",
    float: "right",
    cursor: "none"
  },
  location: {
    cursor: "none",
    float: "left",
    marginRight: "2%"
  },
  right: {
    marginLeft: "2%",
    float: "right"
  }
});

class ViewProfile extends React.Component {
  profileLoaded = false;
  constructor(props) {
    super(props);
    this.state = {
      profile: "",
      loading: true,
      profileOpen: false,
      activeStep: 0,
      basicInfoDocs: [],
      educationDocs: [],
      workExpDocs: [],
      videoProfileURL: ""
    };
  }

  handleClickOpen = step => {
    console.log(step);
    this.setState({
      loading: false,
      profileOpen: true,
      activeStep: step
    });
  };

  handleClickClose = () => {
    this.setState({
      profileOpen: false
    });
  };

  handleClickUpdate = () => {
    this.setState({
      profileOpen: false,
      loading: true
    });
    this.props.api.getFullProfile().then(profile => {
      this.setState({
        loading: false,
        profile: profile,
        profileOpen: false,
        activeStep: 0
      });
      if (this.state.profile.videoProfile !== "" && this.state.profile.videoProfile !== null) {
        this.setState({
          videoProfileURL: this.state.profile.videoProfile
        });
      }
    });
    this.props.api.getProfileDocuments("BASIC_INFO").then(basicInfoDocs => {
      this.setState({
        basicInfoDocs: basicInfoDocs
      });
    });
    this.props.api.getProfileDocuments("EDUCATION").then(educationDocs => {
      this.setState({
        educationDocs: educationDocs
      });
    });
    this.props.api.getProfileDocuments("WORK_EXP").then(workExpDocs => {
      this.setState({
        workExpDocs: workExpDocs
      });
    });
  };

  downloadProfileDocument = key => {
    this.props.api.downloadProfileDocument(key);
  };

  componentDidMount() {
    this.profileLoaded = true;
    this.props.api.getFullProfile().then(profile => {
      if (this.profileLoaded === true) {
        this.setState({
          loading: false,
          profile: profile,
          profileOpen: false,
          activeStep: 0,
          basicInfoDocs: [],
          educationDocs: [],
          workExpDocs: []
        });
        if (this.state.profile.videoProfile !== "" && this.state.profile.videoProfile !== null) {
          this.setState({
            videoProfileURL: this.state.profile.videoProfile
          });
        }
      }
      this.props.api.getProfileDocuments("BASIC_INFO").then(basicInfoDocs => {
        if (this.profileLoaded === true) {
          this.setState({
            basicInfoDocs: basicInfoDocs
          });
        }
      });
      this.props.api.getProfileDocuments("EDUCATION").then(educationDocs => {
        if (this.profileLoaded === true) {
          this.setState({
            educationDocs: educationDocs
          });
        }
      });
      this.props.api.getProfileDocuments("WORK_EXP").then(workExpDocs => {
        if (this.profileLoaded === true) {
          this.setState({
            workExpDocs: workExpDocs
          });
        }
      });
    });
  }

  componentWillUnmount() {
    this.profileLoaded = false;
  }

  render() {
    const { classes } = this.props;
    const formattedPhoneObject = this.state.loading === false ? parsePhoneNumberFromString(this.state.profile.phone, "US") : null;
    const formattedPhone = formattedPhoneObject ? formattedPhoneObject.formatInternational() : "";
    return (
      <React.Fragment>
        <LoadingIndicator display={this.state.loading} /> <CssBaseline />
        <main className={classes.root}>
          {this.state.loading === false ? (
            <React.Fragment>
              <EditProfile
                open={this.state.profileOpen}
                handleClickClose={this.handleClickClose}
                handleClickUpdate={this.handleClickUpdate}
                activeStep={this.state.activeStep}
              />
              <Card className={classes.card}>
                <CardHeader
                  avatar={
                    <label htmlFor="profileImage">
                      {this.state.profile.avatar === "" ? (
                        <Avatar className={classes.avatar}>
                          <ProfileIcon fontSize="large" />
                        </Avatar>
                      ) : (
                        <Avatar alt="Profile" className={classes.profile} src={this.state.profile.avatar} />
                      )}
                    </label>
                  }
                  action={
                    <IconButton onClick={() => this.handleClickOpen(0)}>
                      <EditIcon />
                    </IconButton>
                  }
                  title={
                    <Typography className={classes.location} variant="h6">
                      Basic Info
                    </Typography>
                  }
                />
                <CardContent>
                  {this.state.videoProfileURL !== "" ? (
                    <Grid container alignContent="center" alignItems="center" direction="column">
                      <ReactPlayer url={this.state.videoProfileURL} playing controls />
                    </Grid>
                  ) : null}
                  <Grid container alignContent="center" alignItems="center" direction="column">
                    <div
                      style={{
                        width: "50%",
                        background: "#FBEEE6",
                        margin: "10px",
                        padding: "10px",
                        borderRadius: "2%",
                        textAlign: "center"
                      }}
                    >
                      <Grid item xs={12}>
                        <Typography variant="body1">
                          {this.state.profile.firstName}&nbsp;{this.state.profile.lastName}
                        </Typography>
                        <Typography variant="body1">{this.state.profile.profileTitle}</Typography>
                        <Typography variant="body1">{formattedPhone}</Typography>
                        <Typography variant="body1">{this.state.profile.email}</Typography>
                        <Typography variant="body1">
                          D.O.B&nbsp;
                          {this.state.profile.dob !== "" && this.state.profile.dob !== undefined ? this.state.profile.dob : "..."}
                        </Typography>
                      </Grid>
                    </div>
                  </Grid>
                </CardContent>
                <CardContent>
                  <Typography variant="body1">{this.state.profile.aboutMe}</Typography>
                </CardContent>
                <CardContent>
                  <Grid container spacing={1}>
                    {this.state.basicInfoDocs !== undefined
                      ? this.state.basicInfoDocs.map((item, index) => (
                          <Grid key={index} item xs={12} style={{ background: "#FBEEE6", margin: "5px" }}>
                            <Typography variant="body1">
                              {item.fileName}
                              <Button
                                color="primary"
                                variant="outlined"
                                style={{ float: "right", padding: "10px", borderRadius: "2%" }}
                                onClick={() => this.downloadProfileDocument(item.key)}
                              >
                                download
                              </Button>
                            </Typography>
                          </Grid>
                        ))
                      : null}
                  </Grid>
                </CardContent>
              </Card>
              <Card className={classes.card}>
                <CardHeader
                  avatar={
                    <label htmlFor="profileImage">
                      {
                        <Avatar className={classes.avatar}>
                          <AddressIcon fontSize="large" />
                        </Avatar>
                      }
                    </label>
                  }
                  action={
                    <IconButton onClick={() => this.handleClickOpen(1)}>
                      <EditIcon />
                    </IconButton>
                  }
                  title={
                    <Typography className={classes.location} variant="h6">
                      Address
                    </Typography>
                  }
                />
                <CardContent>
                  <Grid container justify="center" spacing={1}>
                    {this.state.profile.address !== undefined && this.state.profile.address !== null
                      ? this.state.profile.address.map((item, index) => (
                          <Grid key={index} item xs={12}>
                            <Typography variant="subtitle1" gutterBottom>
                              <IconButton color="primary">
                                <RightArrowIcon />
                              </IconButton>
                              &nbsp;
                              {item.address1} {item.address2} {item.city} {item.state} {item.country} {item.zip}
                            </Typography>
                          </Grid>
                        ))
                      : null}
                  </Grid>
                </CardContent>
              </Card>
              <Card className={classes.card}>
                <CardHeader
                  avatar={
                    <label htmlFor="profileImage">
                      {
                        <Avatar className={classes.avatar}>
                          <SchoolIcon fontSize="large" />
                        </Avatar>
                      }
                    </label>
                  }
                  action={
                    <IconButton onClick={() => this.handleClickOpen(2)}>
                      <EditIcon />
                    </IconButton>
                  }
                  title={
                    <Typography className={classes.location} variant="h6">
                      Education
                    </Typography>
                  }
                />
                <CardContent>
                  <Grid container justify="center" spacing={1}>
                    {this.state.profile.education !== undefined && this.state.profile.education !== null
                      ? this.state.profile.education.map((item, index) => (
                          <Grid key={index} item xs={12}>
                            <Typography variant="subtitle1" gutterBottom>
                              <IconButton color="primary">
                                <RightArrowIcon />
                              </IconButton>
                              &nbsp;
                              {item.schoolName},&nbsp;
                              {item.educationType === "HIGH_SCHOOL"
                                ? "High School"
                                : item.educationType === "VOCATIONAL_TRADE_SCHOOL"
                                ? "Vocational Trade School"
                                : item.educationType === "GED_PROGRAM"
                                ? "GED Program"
                                : item.educationType === "ASSOCIATE_DEGREE_PROGRAM"
                                ? "Associate Degree Program"
                                : item.educationType === "BACHELORS_DEGREE_PROGRAM"
                                ? "Bachelors Degree"
                                : item.educationType === "MASTERS_DEGREE_PROGRAM"
                                ? "Masters Degree"
                                : item.educationType === "DOCTORATE_PROGRAM"
                                ? "Doctorate Program"
                                : ""}
                              , &nbsp; {item.degree}, &nbsp; ({moment(item.startYear, "YYYY").format("YYYY")} &nbsp;-&nbsp;
                              {moment(item.endYear, "YYYY").format("YYYY")})
                            </Typography>
                          </Grid>
                        ))
                      : null}
                  </Grid>
                </CardContent>
                <CardContent>
                  <Grid container spacing={1}>
                    {this.state.educationDocs !== undefined
                      ? this.state.educationDocs.map((item, index) => (
                          <Grid key={index} item xs={12} style={{ background: "#FBEEE6", margin: "5px", padding: "10px", borderRadius: "2%" }}>
                            <Typography variant="body1">
                              {item.fileName}
                              <Button
                                color="primary"
                                variant="outlined"
                                style={{ float: "right" }}
                                onClick={() => this.downloadProfileDocument(item.key)}
                              >
                                download
                              </Button>
                            </Typography>
                          </Grid>
                        ))
                      : null}
                  </Grid>
                </CardContent>
              </Card>
              <Card className={classes.card}>
                <CardHeader
                  avatar={
                    <label htmlFor="profileImage">
                      {
                        <Avatar className={classes.avatar}>
                          <WorkIcon fontSize="large" />
                        </Avatar>
                      }
                    </label>
                  }
                  action={
                    <IconButton onClick={() => this.handleClickOpen(3)}>
                      <EditIcon />
                    </IconButton>
                  }
                  title={
                    <Typography className={classes.location} variant="h6">
                      Work Experience
                    </Typography>
                  }
                />
                <CardContent>
                  <Grid container justify="center" spacing={0}>
                    {this.state.profile.experience !== undefined && this.state.profile.experience !== null
                      ? this.state.profile.experience.map((item, index) => (
                          <Grid key={index} item xs={12}>
                            <Typography variant="subtitle1" gutterBottom>
                              <IconButton color="primary">
                                <RightArrowIcon />
                              </IconButton>
                              &nbsp;
                              {item.company},&nbsp;{item.jobTitle},&nbsp; ( {moment(item.startDate).format("MMMM YYYY")} )
                            </Typography>
                          </Grid>
                        ))
                      : null}
                  </Grid>
                </CardContent>
                <CardContent>
                  <Grid container spacing={1}>
                    {this.state.workExpDocs !== undefined
                      ? this.state.workExpDocs.map((item, index) => (
                          <Grid key={index} item xs={12} style={{ background: "#FBEEE6", margin: "5px", padding: "10px", borderRadius: "2%" }}>
                            <Typography variant="body1">
                              {item.fileName}
                              <Button
                                color="primary"
                                variant="outlined"
                                style={{ float: "right" }}
                                onClick={() => this.downloadProfileDocument(item.key)}
                              >
                                download
                              </Button>
                            </Typography>
                          </Grid>
                        ))
                      : null}
                  </Grid>
                </CardContent>
              </Card>
              <Card className={classes.card}>
                <CardHeader
                  avatar={
                    <label htmlFor="profileImage">
                      {
                        <Avatar className={classes.avatar}>
                          <SkillIcon fontSize="large" />
                        </Avatar>
                      }
                    </label>
                  }
                  action={
                    <IconButton onClick={() => this.handleClickOpen(4)}>
                      <EditIcon />
                    </IconButton>
                  }
                  title={
                    <Typography className={classes.location} variant="h6">
                      Skills
                    </Typography>
                  }
                />
                <CardContent>
                  <Grid container alignContent="center" alignItems="center">
                    <Grid item xs={12}>
                      {this.state.profile.skill != null && this.state.profile.skill !== undefined
                        ? this.state.profile.skill.map((item, index) => (
                            <Chip size="small" key={index} variant="outlined" label={item.skillName} style={{ margin: "1px" }} />
                          ))
                        : ""}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card className={classes.card}>
                <CardHeader
                  avatar={
                    <label htmlFor="profileImage">
                      {
                        <Avatar className={classes.avatar}>
                          <ReferenceIcon fontSize="large" />
                        </Avatar>
                      }
                    </label>
                  }
                  action={
                    <IconButton onClick={() => this.handleClickOpen(5)}>
                      <EditIcon />
                    </IconButton>
                  }
                  title={
                    <Typography className={classes.location} variant="h6">
                      References
                    </Typography>
                  }
                />
                <CardContent>
                  <Grid container justify="center" spacing={1}>
                    {this.state.profile.references !== undefined && this.state.profile.references !== null
                      ? this.state.profile.references.map((item, index) => (
                          <Grid key={index} item xs={12}>
                            <Typography variant="subtitle1" gutterBottom>
                              <IconButton color="primary">
                                <RightArrowIcon />
                              </IconButton>
                              &nbsp;{item.firstName}&nbsp; {item.lastName},&nbsp;
                              {item.relationShip === "FORMER_EMPLOYER"
                                ? "Former Employer"
                                : item.relationShip === "COLLEAGUE"
                                ? "Colleague"
                                : item.relationShip === "TEACHER"
                                ? "Teacher"
                                : item.relationShip === "ADVISOR"
                                ? "Advisor"
                                : item.relationShip === "FORMER_SUPERVISOR"
                                ? "Former Supervisor"
                                : item.relationShip === "FRIEND"
                                ? "Friend"
                                : item.relationShip === "OTHER"
                                ? "Other"
                                : ""}
                              ,&nbsp;
                              {item.email},&nbsp;
                              {parsePhoneNumberFromString(item.phoneNumber, "US").formatInternational()}
                            </Typography>
                          </Grid>
                        ))
                      : null}
                  </Grid>
                </CardContent>
              </Card>
            </React.Fragment>
          ) : null}
        </main>
      </React.Fragment>
    );
  }
}

const condition = authUser => !!authUser;
export default withStyles(styles)(withApi(withAuthorization(condition)(ViewProfile)));
