import React from "react";
import AuthUserContext from "./context";
import { withApi } from "../Api";

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    userLoaded = false;
    constructor(props) {
      super(props);
      this.state = {
        authUser: null
      };
    }

    componentDidMount() {
      this.userLoaded = true;
      this.listener = this.props.api.auth.onAuthStateChanged(authUser => {
        if (this.userLoaded) {
          authUser
            ? this.setState({
                authUser
              })
            : this.setState({
                authUser: null
              });
        }
      });
    }

    componentWillUnmount() {
      this.userLoaded = false;
      this.listener();
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }
  return withApi(WithAuthentication);
};
export default withAuthentication;
