import React from "react";
import PropTypes from "prop-types";
import { CardContent, Card, withStyles, Typography, Grid, CssBaseline } from "@material-ui/core";
import grey from "@material-ui/core/colors/grey";
import ProfileComplete from "./profileComplete";
import RatingIcon from "@material-ui/icons/StarRate";
import EditProfile from "../Profile/editProfile";

const styles = theme => ({
  card: { width: "99.5%" },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    color: grey[800]
  },
  edit: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    cursor: "pointer",
    textAlign: "center"
  }
});

class ProfileCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profileOpen: false
    };
  }

  handleClickOpen = () => {
    this.setState({
      profileOpen: true
    });
  };

  handleClickClose = () => {
    this.setState({
      profileOpen: false
    });
  };

  handleClickUpdate = () => {
    this.setState({
      profileOpen: false
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <EditProfile
          open={this.state.profileOpen}
          handleClickClose={this.handleClickClose}
          handleClickUpdate={this.handleClickUpdate}
          activeStep={0}
        />
        <CssBaseline />
        <Card className={classes.card}>
          <CardContent>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography className={classes.heading}>Profile</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      color="primary"
                      className={classes.edit}
                      onClick={this.handleClickOpen}
                      disabled={this.state.profileOpen}>
                      EDIT
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <ProfileComplete profileCompletion={this.props.profileCompletion} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  className={classes.heading}
                  style={{
                    textAlign: "center"
                  }}>
                  Profile Views
                </Typography>
                <div
                  style={{
                    color: "#B9090B",
                    textAlign: "center"
                  }}>
                  43
                </div>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  className={classes.heading}
                  style={{
                    textAlign: "center"
                  }}>
                  Search Appearances
                </Typography>
                <div
                  style={{
                    color: "#B9090B",
                    textAlign: "center"
                  }}>
                  56
                </div>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  className={classes.heading}
                  style={{
                    textAlign: "center"
                  }}>
                  Reputation Score
                </Typography>
                <div
                  style={{
                    color: "#B9090B",
                    textAlign: "center"
                  }}>
                  <RatingIcon /> <RatingIcon /> <RatingIcon /> <RatingIcon /> <RatingIcon />
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </React.Fragment>
    );
  }
}

ProfileCard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ProfileCard);
