import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import Assessment from "./assessment";

const styles = theme => ({
  appBar: {
    width: "98%"
  }
});

class AssessmentHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0
    };
  }

  render() {
    return (
      <React.Fragment>
        <Assessment
        />
      </React.Fragment>
    );
  }
}

AssessmentHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, {
  withTheme: true
})(AssessmentHeader);
