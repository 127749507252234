import React, { Component } from "react";
import { AddCircle } from "@material-ui/icons";
import { withStyles, Button } from "@material-ui/core";
import References from "./references";

const styles = theme => ({
  main: {},
  fab: {}
});

class ReferencesWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      references: this.props.state
    };
  }

  onAddChild = () => {
    this.setState(
      prevState => ({
        references: [...prevState.references, this.props.defRefProps]
      }),
      () => {
        this.props.handleState(this.state.references);
      }
    );
  };

  onRemoveMe = removeMeIndex => {
    let eArray = this.state.references;
    var index = eArray.indexOf(removeMeIndex);
    eArray.splice(index, 1);
    this.setState(
      {
        references: eArray
      },
      () => {
        this.props.handleState(this.state.references);
      }
    );
  };

  handleState = (refItem, index) => {
    const eds = this.state.references.slice();
    eds[index] = refItem;
    this.setState(
      {
        references: eds
      },
      () => {
        this.props.handleState(this.state.references);
      }
    );
  };

  renderChildren = () => {
    const refArray = this.state.references;
    const children = refArray.map((refItem, i) =>
      refItem.active === true ? (
        <References
          key={["reference", i].join("_")}
          state={refItem}
          handleState={this.handleState}
          index={i}
          onRemoveMe={this.onRemoveMe}
          showRemoveMe={i > 0 ? true : false}
        />
      ) : null
    );
    return <div> {children} </div>;
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <main className={classes.main}>
          {this.renderChildren()}
          <Button color="primary" className={classes.fab} onClick={this.onAddChild}>
            <AddCircle />
            &nbsp; Add New
          </Button>
        </main>
        <div
          ref={el => {
            this.el = el;
          }}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ReferencesWrapper);
