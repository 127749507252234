import React from "react";
import { CssBaseline, withStyles, Grid } from "@material-ui/core";
import LoadingIndicator from "../../../common/loading";
import HiveCard from "./hiveCard";

const styles = theme => ({
  root: {
    maxHeight: "78vh",
    width: "100%",
    overflowY: "auto",
    overflowX: "hidden"
  }
});

class Hive extends React.Component {
  componentDidMount() {
    this.refs.iScroll.addEventListener("scroll", () => {
      if (this.refs.iScroll.scrollTop + this.refs.iScroll.clientHeight + 1 >= this.refs.iScroll.scrollHeight) {
        if (!this.props.hiveLoading && this.props.hiveResultSet.length) {
          if (parseInt(this.props.showingCount, 10) < parseInt(this.props.totalCount, 10)) {
            this.props.searchHive("BHired_CURRENT_QUERY");
          }
        }
      }
    });
    if (this.props.hiveResultSet.length === 0) {
      this.props.searchHive("");
    }
  }

  componentWillUnmount() {
    this.refs.iScroll.removeEventListener("scroll", this.onScroll, false);
  }

  render() {
    const { hiveResultSet, classes } = this.props;
    return (
      <React.Fragment>
        <React.Fragment>
          <LoadingIndicator display={this.props.hiveLoading} /> <CssBaseline />
          <main ref="iScroll" className={classes.root}>
            {hiveResultSet !== undefined ? (
              <Grid container className={classes.root} alignItems="center" justify="center" spacing={2}>
                {hiveResultSet.map((item, index) => (
                  <Grid key={index} item xs={6}>
                    <HiveCard candidate={item.bhiredSearchObject} social={item.bhiredObject} startChat={this.props.startChat}/>
                  </Grid>
                ))}
              </Grid>
            ) : null}
          </main>
        </React.Fragment>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)((Hive));
