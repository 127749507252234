import React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { AppBar, Tabs, Tab, Typography, withStyles, Grid } from "@material-ui/core";
import SignIn from "./signIn";
import SignUp from "./signUp";
import Images from "../../assets";

function TabContainer({ children, dir }) {
  return (
    <Typography
      component="div"
      dir={dir}
      style={{
        padding: 8 * 5,
      }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

const styles = (theme) => ({
  logo: {
    position: "absolute",
    zIndex: 9999,
    top: "7%",
    left: "50%",
    transform: "translate(-50%)",
    width: "7%",
    height: "7%",
  },
});

class AuthHeader extends React.Component {
  state = {
    value: 0,
  };

  handleChange = (event, value) => {
    this.setState({
      value,
    });
  };

  handleChangeIndex = (index) => {
    this.setState({
      value: index,
    });
  };

  render() {
    const { classes, theme } = this.props;
    return (
      <React.Fragment>
        <div>
          <div className={classes.logo}>
            <img src={Images.logo} alt="bhiredinc" />
          </div>
          <div>
            <AppBar position="sticky" color="inherit" className={classes.appBar}>
              <Tabs
                centered
                value={this.state.value}
                onChange={this.handleChange}
                indicatorColor="secondary"
                textColor="primary">
                <Tab label="Sign In" />
                <Tab label="Sign Up" />
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={this.state.value}
              onChangeIndex={this.handleChangeIndex}>
              <TabContainer dir={theme.direction}>
                <SignIn />
              </TabContainer>
              <TabContainer dir={theme.direction}>
                <SignUp />
              </TabContainer>
            </SwipeableViews>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

AuthHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {
  withTheme: true,
})(AuthHeader);
