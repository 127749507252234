import React, { Component } from "react";
import {
  Paper,
  CssBaseline,
  FormControl,
  TextField,
  withStyles,
  MenuItem,
  InputLabel,
  Select,
  ListItemText,
  Button
} from "@material-ui/core";
import { RemoveCircle } from "@material-ui/icons";

const styles = theme => ({
  main: {
    display: "flex",
    width: "100%",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(8),
    marginBottom: "10px"
  },
  paper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
  },
  itemText: {
    color: theme.palette.primary.main
  },
  itemTextSelected: {},
  checkBoxLabel: {
    fontSize: "16px"
  },
  remove: {}
});

class References extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.state
    };
  }

  onChange = event => {
    this.setState(
      {
        [event.target.name]: event.target.value
      },
      () => {
        this.props.handleState(this.state, this.props.index);
      }
    );
  };

  handleCheckChange = name => event => {
    this.setState(
      {
        [name]: event.target.checked
      },
      () => {
        this.props.handleState(this.state, this.props.index);
      }
    );
  };

  onRemoveMe = () => {
    if (this.state.key !== "") {
      this.setState(
        {
          active: false
        },
        () => {
          this.props.handleState(this.state, this.props.index);
        }
      );
    } else {
      this.props.onRemoveMe(this.props.index);
    }
  };

  validate = event => {
    if (
      "firstName" === event.target.name ||
      "lastName" === event.target.name ||
      "phoneNumber" === event.target.name ||
      "email" === event.target.name
    ) {
      if (this.state.firstName !== "") {
        this.setState({
          firstNameRequiredMessage: ""
        });
      } else {
        this.setState({
          firstNameRequiredMessage: "First Name Required"
        });
      }
      if (this.state.lastName !== "") {
        this.setState({
          lastNameRequiredMessage: ""
        });
      } else {
        this.setState({
          lastNameRequiredMessage: "Last Name Required"
        });
      }
      if (this.state.phoneNumber !== "") {
        this.setState({
          phoneNumberRequiredMessage: ""
        });
      } else {
        this.setState({
          phoneNumberRequiredMessage: "Phone Number Required"
        });
      }
      if (this.state.email !== "") {
        this.setState({
          emailRequiredMessage: ""
        });
      } else {
        this.setState({
          emailRequiredMessage: "Email Required"
        });
      }
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <main className={classes.main}>
          <CssBaseline />
          <Paper className={classes.paper}>
            <div>
              <Button className={classes.remove} color="primary" onClick={this.onRemoveMe}>
                <RemoveCircle />
                &nbsp; Remove
              </Button>
            </div>
            <form>
              <FormControl margin="normal" required fullWidth>
                <TextField
                  required
                  label="First Name"
                  placeholder="First Name"
                  name="firstName"
                  value={this.state.firstName}
                  onChange={this.onChange}
                  error={
                    this.state.firstNameRequiredMessage === "" || this.state.firstNameRequiredMessage === undefined
                      ? false
                      : true
                  }
                  helperText={this.state.firstNameRequiredMessage}
                  onKeyDown={this.validate}
                  onKeyUp={this.validate}
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <TextField
                  required
                  label="Last Name"
                  placeholder="Last Name"
                  name="lastName"
                  value={this.state.lastName}
                  onChange={this.onChange}
                  error={
                    this.state.lastNameRequiredMessage === "" || this.state.lastNameRequiredMessage === undefined
                      ? false
                      : true
                  }
                  helperText={this.state.lastNameRequiredMessage}
                  onKeyDown={this.validate}
                  onKeyUp={this.validate}
                />
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="relationShip-simple"> How do you know this person? </InputLabel>
                <Select
                  autoWidth
                  inputProps={{
                    name: "relationShip",
                    id: "relationShip-simple"
                  }}
                  value={this.state.relationShip}
                  name="relationShip"
                  onChange={this.onChange}
                  style={{
                    textAlign: "left"
                  }}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "center"
                    }
                  }}>
                  <MenuItem value="FORMER_EMPLOYER">
                    <ListItemText primary="Former Employer" className={classes.itemTextSelected} />
                  </MenuItem>
                  <MenuItem value="COLLEAGUE">
                    <ListItemText primary="Colleague" className={classes.itemTextSelected} />
                  </MenuItem>
                  <MenuItem value="TEACHER">
                    <ListItemText primary="Teacher" className={classes.itemTextSelected} />
                  </MenuItem>
                  <MenuItem value="ADVISOR">
                    <ListItemText primary="Advisor" className={classes.itemTextSelected} />
                  </MenuItem>
                  <MenuItem value="FORMER_SUPERVISOR">
                    <ListItemText primary="Former Supervisor" className={classes.itemTextSelected} />
                  </MenuItem>
                  <MenuItem value="FRIEND">
                    <ListItemText primary="Friend" className={classes.itemTextSelected} />
                  </MenuItem>
                  <MenuItem value="OTHER">
                    <ListItemText primary="Other" className={classes.itemTextSelected} />
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <TextField
                  required
                  label="Phone Number"
                  placeholder="Phone Number"
                  name="phoneNumber"
                  value={this.state.phoneNumber}
                  onChange={this.onChange}
                  error={
                    this.state.phoneNumberRequiredMessage === "" || this.state.phoneNumberRequiredMessage === undefined
                      ? false
                      : true
                  }
                  helperText={this.state.phoneNumberRequiredMessage}
                  onKeyDown={this.validate}
                  onKeyUp={this.validate}
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <TextField
                  required
                  label="Email Address"
                  placeholder="Email Address"
                  name="email"
                  value={this.state.email}
                  onChange={this.onChange}
                  error={
                    this.state.emailRequiredMessage === "" || this.state.emailRequiredMessage === undefined
                      ? false
                      : true
                  }
                  helperText={this.state.emailRequiredMessage}
                  onKeyDown={this.validate}
                  onKeyUp={this.validate}
                />
              </FormControl>
            </form>
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(References);
