import React, { Component } from "react";
import {
  Typography,
  Button,
  FormControl,
  withStyles,
  Radio,
  RadioGroup,
  FormControlLabel,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Switch
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Cancel";
import LoadingIndicator from "../../common/loading";
import { withApi } from "../Api";

const styles = theme => ({
  dialogPaper: {
    minHeight: "100vh",
    maxHeight: "100vh",
    minWidth: "50%",
    maxWidth: "50%"
  },
  root: {
    margin: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  closeButton: {
    position: "absolute",
    right: 0,
    top: 0,
    margin: "5px",
    cursor: "pointer"
  }
});

const INITIAL_STATE = {
  loading: true,
  emailNotifications: true,
  jobStatus: "Looking",
  paymentInfo: false
};

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentLoaded = false;

  handleSave = () => {
    this.props.api.saveUserPrefs(this.state);
    this.props.handleSettingsClose();
  };

  emailNotificationsToggled = () => {
    this.setState({ emailNotifications: !this.state.emailNotifications });
  };

  paymentInfoToggled = () => {
    this.setState({ paymentInfo: !this.state.paymentInfo });
  };

  handleCancel = () => {
    this.props.handleSettingsClose();
  };

  handleJobStatusChange = event => {
    this.setState({ jobStatus: event.target.value });
  };

  componentDidMount() {
    this.componentLoaded = true;
    this.props.api.getUserPrefs().then(prefs => {
      if (this.componentLoaded === true) {
        this.setState({
          loading: false,
          key: prefs.key,
          emailNotifications: prefs.emailNotifications,
          jobStatus: prefs.jobStatus,
          paymentInfo: prefs.paymentInfo
        });
      }
    });
  }

  componentWillUnmount() {
    this.componentLoaded = false;
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <LoadingIndicator display={this.state.loading} />
        <Dialog
          open={this.props.open ? true : false}
          aria-labelledby="bhiredinc"
          fullWidth={true}
          classes={{
            paper: classes.dialogPaper
          }}
          transitionDuration={{
            enter: 1000,
            exit: 500
          }}
        >
          <DialogTitle id="bhiredinc" align="center" className={classes.root}>
            <Grid container style={{ paddingTop: "2%" }}>
              <Grid item xs={7}>
                <Typography variant="h5" gutterBottom style={{ textAlign: "right" }}>
                  Settings
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <CloseIcon color="primary" className={classes.closeButton} onClick={this.handleCancel} />
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <DialogContent align="center" style={{ overflowX: "hidden" }}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  style={{
                    borderStyle: "solid",
                    borderWidth: "1px",
                    padding: "3%",
                    borderColor: "#f2f2f2",
                    background: "#F8F8F8"
                  }}
                >
                  <FormControlLabel
                    labelPlacement="start"
                    control={
                      <Switch
                        checked={this.state.emailNotifications}
                        value="emailNotifications"
                        color="primary"
                        onChange={this.emailNotificationsToggled}
                      />
                    }
                    label={<Typography variant="body1">Email Notifications</Typography>}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    marginTop: "5%",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    padding: "3%",
                    borderColor: "#f2f2f2",
                    background: "#F8F8F8"
                  }}
                >
                  <FormControl
                    component="fieldset"
                    style={{
                      textAlign: "left",
                      display: "start"
                    }}
                  >
                    <Typography variant="body1">Job Status</Typography>
                    <RadioGroup aria-label="jobStatus" name="jobStatus" value={this.state.jobStatus} onChange={this.handleJobStatusChange}>
                      <FormControlLabel
                        value="Actively Looking"
                        control={<Radio color="primary" checked={this.state.jobStatus === "Actively Looking"} />}
                        label={<Typography variant="body1">Actively Looking</Typography>}
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="Looking"
                        control={<Radio color="primary" checked={this.state.jobStatus === "Looking"} />}
                        label={<Typography variant="body1">Looking</Typography>}
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="Not Looking"
                        control={<Radio color="primary" checked={this.state.jobStatus === "Not Looking"} />}
                        label={<Typography variant="body1">Not Looking</Typography>}
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    marginTop: "5%",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    padding: "3%",
                    borderColor: "#f2f2f2",
                    background: "#F8F8F8"
                  }}
                >
                  <FormControlLabel
                    style={{ textAlign: "left", display: "start" }}
                    labelPlacement="start"
                    control={<Switch checked={this.state.paymentInfo} value="paymentInfo" color="primary" onChange={this.paymentInfoToggled} />}
                    label={<Typography variant="body1">Payment Info Set</Typography>}
                  />
                </Grid>
              </Grid>
              <div align="center" style={{ marginTop: "5%" }}>
                <Button variant="contained" onClick={this.handleCancel} color="primary">
                  Cancel
                </Button>
                <Button variant="contained" onClick={this.handleSave} color="primary" style={{ marginLeft: "5%" }}>
                  Save
                </Button>
              </div>
            </DialogContent>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(withApi(Settings));

