import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Cancel";
import { IconButton } from "@material-ui/core";

class Header extends Component {
  render() {
    return (
      <div className="sc-header">
        <div className="sc-header--team-name"> {this.props.groupName} </div>
        <IconButton onClick={this.props.onClose} color="primary">
          <CloseIcon size="large" />
        </IconButton>
      </div>
    );
  }
}

export default Header;
