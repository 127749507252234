import React from "react";
import { CssBaseline, withStyles, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import { withAuthorization } from "../../Session";
import NewJob from "./newJob";
import Jobs from "./jobs";
import LoadingIndicator from "../../../common/loading";

const styles = theme => ({
  root: {
    height: "100%",
    width: "98%",
    overflowY: "auto",
    overflowX: "hidden",
    ...theme.mixins.toolbar
  },
  fab: {
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
    top: "2%",
    right: "40%",
    position: "absolute",
    zIndex: 1600,
    background: "#ffffff",
    transform: "translate(40%)"
  },
  postIcon: {
    border: "none",
    margin: "none"
  }
});

class JobsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      createNewJobOpen: false,
      hidePostJobButton: false
    };
  }

  hidePostJobButton = hide => {
    this.setState({
      hidePostJobButton: hide
    });
  };

  handleClickOpen = () => {
    this.setState({
      createNewJobOpen: true,
      hidePostJobButton: true
    });
  };

  handleClickClose = () => {
    this.setState({
      createNewJobOpen: false,
      hidePostJobButton: false
    });
    this.props.searchJobs("");
  };

  handleClickPublish = () => {
    this.setState({
      createNewJobOpen: false,
      hidePostJobButton: false
    });
    this.props.searchJobs("");
  };

  componentDidMount() {
    this.refs.iScroll.addEventListener("scroll", () => {
      if (this.refs.iScroll.scrollTop + this.refs.iScroll.clientHeight + 1 >= this.refs.iScroll.scrollHeight) {
        if (!this.props.jobsLoading && this.props.jobsResultSet.length) {
          if (parseInt(this.props.showingCount, 10) < parseInt(this.props.totalCount, 10)) {
            this.props.searchJobs("BHired_CURRENT_QUERY");
          }
        }
      }
    });
    if (this.props.jobsResultSet.length === 0) {
      this.props.searchJobs("", true);
    }
  }

  componentWillUnmount() {
    this.refs.iScroll.removeEventListener("scroll", this.onScroll, false);
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <LoadingIndicator display={this.props.jobsLoading} />
        <CssBaseline />
        <main ref="iScroll" className={classes.root}>
          <Jobs
            jobsResultSet={this.props.jobsResultSet}
            searchJobs={this.props.searchJobs}
            hidePostJobButton={this.hidePostJobButton}
          />
        </main>
        {!this.state.hidePostJobButton && this.props.role === "ROLE_RECRUITER" ? (
          <Button
            variant="outlined"
            color="primary"
            aria-label="Add"
            className={classes.fab}
            onClick={this.handleClickOpen}
            disabled={this.state.hidePostJobButton}>
            <AddIcon className={classes.postIcon} />
            &nbsp;Post a Job
          </Button>
        ) : null}
        <NewJob
          open={this.state.createNewJobOpen}
          handleClickClose={this.handleClickClose}
          handleClickPublish={this.handleClickPublish}
        />
      </React.Fragment>
    );
  }
}

const condition = authUser => !!authUser;
export default withStyles(styles)(withAuthorization(condition)(JobsPage));
