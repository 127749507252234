export const HOME = "/";
export const AUTH_HEADER = "/auth";
export const ACCOUNT = "/account";
export const ADMIN = "/admin";
export const RESET_PASSWORD = "/resetPassword";
export const REGISTRATION_SUCCESSFUL = "/registrationSuccessful";
export const LINKED_ACCOUNT_MESSAGE = "/link";
export const RESET_PASSWORD_MESSAGE = "/resetPasswordMessage";
export const TERMS_AND_CONDITIONS = "/terms";
export const ROLE_SELECTOR = "/roleSelector";
export const DASHBOARD = "/dashboard";
export const PROFILE = "/profile";
export const JOBS = "/jobs";
export const CANDIDATES = "/candidates";
export const WALLET = "/wallet";
export const ASSESSMENTS = "/assessments";
export const HIVE = "/hive";

