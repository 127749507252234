import React from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import Images from "../assets";
import { withRouter } from "react-router-dom";
import withMobileDialog from "@material-ui/core/withMobileDialog";

class BhiredDialog extends React.Component {
  handleClose = () => {
    this.setState({
      open: false
    });
    if (this.props.forward !== null) {
      this.props.history.push(this.props.forward);
    }
  };

  render() {
    return (
      <div>
        <Dialog
          onClose={this.handleClose}
          open={this.props.open ? true : false}
          fullWidth={true}
          BackdropProps={{
            style: {
              backgroundColor: "#ffffff"
            }
          }}
        >
          <DialogTitle id="bhiredinc" align="center">
            <div>
              <img
                style={{
                  width: "25%",
                  height: "auto"
                }}
                src={Images.logo}
                alt="bhiredinc"
              />
            </div>
            {this.props.title}
          </DialogTitle>
          <DialogContent align="center">{this.props.message}</DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Go To Sign In Page
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(withRouter(BhiredDialog));
