import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { CssBaseline, withStyles, Drawer, AppBar, Toolbar, IconButton, Badge } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import IconOpen from "@material-ui/icons/MoreVertOutlined";
import WalletIcon from "@material-ui/icons/AccountBalance";
import BellIcon from "@material-ui/icons/NotificationsActive";
import MenuBar from "./menuBar";
import ProfileImage from "./profileImage";
import SignOutIcon from "@material-ui/icons/PowerSettingsNewSharp";
import Images from "../../assets";
import SearchBar from "./searchBar";
import { withRouter } from "react-router-dom";
import { withApi } from "../Api";
import Notifications from "../Home/Dashboard/notifications";
import SettingsIcon from "@material-ui/icons/SettingsApplicationsRounded";
import Settings from "./settings";

const drawerWidth = 175;
const styles = theme => ({
  root: {
    display: "flex",
    position: "relative",
    overflow: "hidden"
  },
  toolbar: {
    ...theme.mixins.toolbar
  },
  appBar: {
    backgroundColor: "transparent",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: 500
    }),
    [theme.breakpoints.up("sm")]: {
      width: "100%"
    }
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`
  },
  appBarHalfShift: {
    marginLeft: drawerWidth / 2,
    width: `calc(100% - ${drawerWidth / 2.8}px)`
  },
  search: {
    flexGrow: 2
  },
  drawerPaper: {
    position: "relative",
    overflow: "auto",
    overflowX: "hidden",
    height: "100%",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: 500
    })
  },
  drawerPaperClose: {
    overflow: "auto",
    overflowX: "hidden",
    height: "100%",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: 500
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9)
    }
  },
  content: {
    flexGrow: 1,
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(8),
    height: "100vh",
    overflow: "hidden"
  },
  logoOpen: {
    position: "fixed",
    zIndex: 1600,
    bottom: 0,
    left: 2,
    width: "10%",
    height: "10%",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  logoClosed: {
    position: "fixed",
    zIndex: 1600,
    bottom: 0,
    left: 2,
    width: "5%",
    height: "5%",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  hello: {
    fontSize: theme.typography.pxToRem(16),
    flexShrink: 0,
    color: "#B9090B",
    textAlign: "left",
    marginRight: "1%"
  },
  role: {
    fontSize: "12px",
    flexShrink: 0,
    color: "grey"
  }
});

class Layout extends React.Component {
  dataLoaded = false;
  constructor(props) {
    super(props);
    this.state = {
      open: window.innerWidth < 850 ? false : true,
      name: this.props.api.bhiredUser != null ? this.props.api.bhiredUser.name : "",
      location: this.props.api.bhiredUser != null ? this.props.api.bhiredUser.location : "",
      totalTokens: 0,
      totalNotifications: 0,
      notificationsOpen: false,
      settingsOpen: false,
      totalMessages: 0
    };
  }

  componentDidMount() {
    this.dataLoaded = true;
    window.addEventListener("resize", this.handleResize);
    this.registerTokensListener();
  }

  componentWillUnmount() {
    this.dataLoaded = false;
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = e => {
    const windowWidth = window.innerWidth;
    if (windowWidth < 850) {
      this.setState({
        open: false
      });
    } else {
      this.setState({
        open: true
      });
    }
  };

  handleDrawerOpen = () => {
    this.setState({
      open: true
    });
  };

  handleDrawerClose = () => {
    this.setState({
      open: false
    });
  };

  handleWalletClick = () => {
    this.props.history.push("/wallet");
  };

  handleNotificationsOpen = () => {
    this.setState({
      notificationsOpen: true
    });
  };

  handleSettingsOpen = () => {
    this.setState({
      settingsOpen: true
    });
  };

  handleSettingsClose = () => {
    this.setState({
      settingsOpen: false
    });
  };

  handleNotificationsClose = () => {
    this.setState({
      notificationsOpen: false
    });
  };

  registerTokensListener = () => {
    this.props.api.db
      .collection("profile")
      .doc(this.props.api.auth.currentUser.email)
      .onSnapshot(doc => {
        if (this.dataLoaded === true) {
          if (doc.data() !== undefined) {
            this.setState({
              totalTokens: doc.data().totalTokens
            });
          }
        }
      });

    var notificationsRef = this.props.api.db.collection("notifications/" + this.props.api.auth.currentUser.email + "/all");
    notificationsRef.where("isRead", "==", false).onSnapshot(snap => {
      if (this.dataLoaded) {
        this.setState({
          totalNotifications: snap.size
        });
      }
    });
    var mRef = this.props.api.db.collection("hive");
    if (this.dataLoaded && this.props.api.bhiredUser !== null) {
      mRef.where("participants", "array-contains", this.props.api.bhiredUser.key).onSnapshot(snapshot => {
        snapshot.docChanges().forEach(change => {
          if (change.type === "added") {
            var mRefMessages = this.props.api.db.collection("hive/" + change.doc.id + "/messages");
            mRefMessages.get().then(q => {
              q.forEach(message => {
                if (message.data().senderKey !== this.props.api.bhiredUser.key) {
                  this.setState({ totalMessages: this.state.totalMessages + 1 });
                }
              });
            });
          }
        });
      });
    }
  };

  curatedName = () => {
    if (this.state.name !== "" && this.state.name !== null) {
      const name = this.state.name.split("@")[0];
      return name.charAt(0).toUpperCase() + name.slice(1) + "!";
    } else {
      return "";
    }
  };

  render() {
    const {
      classes,
      location: { pathname },
      children,
      searchJobs,
      searchCandidates,
      searchHive,
      role,
      context
    } = this.props;
    //console.log(pathname);
    const conditionalContext =
      pathname === "/"
        ? context
        : pathname === "/home"
        ? context
        : pathname === "/dashboard"
        ? context
        : pathname === "/jobs" && role !== "ROLE_RECRUITER"
        ? "JOBS"
        : pathname === "/candidates"
        ? "CANDIDATES"
        : pathname === "/wallet"
        ? "WALLET"
        : pathname === "/hive"
        ? "HIVE"
        : "";

    const elevation = 0;
    return (
      <React.Fragment>
        <Notifications open={this.state.notificationsOpen} handleNotificationsClose={() => this.handleNotificationsClose()} />
        <Settings open={this.state.settingsOpen} handleSettingsClose={() => this.handleSettingsClose()} />
        <div className={classes.root}>
          <CssBaseline />
          <AppBar
            elevation={elevation}
            className={classNames(classes.appBar, this.state.open && classes.appBarShift, !this.state.open && classes.appBarHalfShift)}
          >
            <Toolbar>
              <IconButton color="primary" aria-label="Open drawer" onClick={this.state.open ? this.handleDrawerClose : this.handleDrawerOpen}>
                {this.state.open ? <MenuIcon /> : <IconOpen />}
              </IconButton>
              <div className={classes.search}>
                {conditionalContext === "CANDIDATES" || conditionalContext === "JOBS" || conditionalContext === "HIVE" ? (
                  <SearchBar
                    role={role}
                    context={conditionalContext}
                    searchJobs={searchJobs}
                    searchCandidates={searchCandidates}
                    searchHive={searchHive}
                    pathname={pathname}
                    location={this.state.location}
                    style={{ float: "left" }}
                  />
                ) : null}
              </div>
              <div>
                <div className={classes.hello}>Hello&nbsp;{this.curatedName()}</div>
                <div className={classes.role}>{role === "ROLE_USER" ? "[ Candidate View ]" : "[ Recruiter View ]"}</div>
              </div>
              <IconButton color="primary" onClick={this.handleNotificationsOpen}>
                <Badge badgeContent={this.state.totalNotifications} max={9999999} color="secondary" title="New Notifications">
                  <BellIcon />
                </Badge>
              </IconButton>
              <IconButton color="primary" onClick={this.handleWalletClick}>
                <Badge badgeContent={this.state.totalTokens} max={9999999} color="secondary" title="Your Bhired Tokens">
                  <WalletIcon />
                </Badge>
              </IconButton>
              <IconButton color="primary" title="Settings" onClick={this.handleSettingsOpen}>
                <SettingsIcon />
              </IconButton>
              <IconButton color="primary" title="Sign Out" onClick={this.props.api.doSignOut}>
                <SignOutIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            classes={{
              paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose)
            }}
            open={this.state.open}
          >
            <ProfileImage />
            <MenuBar role={role} pathname={pathname} totalMessages={this.state.totalMessages} />
          </Drawer>
          <main className={classes.content}>
            <div className={classes.toolbar} /> {children}
          </main>
        </div>
        <div className={classNames(this.state.open && classes.logoOpen, !this.state.open && classes.logoClosed)}>
          <img src={Images.logo} alt="bhiredinc" />
        </div>
      </React.Fragment>
    );
  }
}

Layout.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withApi(withRouter(Layout)));
