import React from "react";
import PropTypes from "prop-types";
import { withStyles, CssBaseline, Stepper, Step, StepLabel, Button, Typography } from "@material-ui/core";
import BasicInfo from "./basicInfo";
import AddressWrapper from "./addressWrapper";
import EducationWrapper from "./educationWrapper";
import WorkExperienceWrapper from "../Profile/workExperienceWrapper";
import Skills from "../Profile/skills";
import ReferencesWrapper from "./referencesWrapper";
import { withApi } from "../../Api";
import LoadingIndicator from "../../../common/loading";
import moment from "moment";

const styles = theme => ({
  root: {
    width: "100%"
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  content: {
    marginBottom: theme.spacing(3)
  },
  message: {
    marginTop: theme.spacing(6)
  }
});

const getDefaultDOB = dob => {
  if (dob != null && dob !== undefined && dob !== "") {
    return moment(dob, "MM/DD/YYYY").format("MM/DD/YYYY");
  } else {
    return moment().subtract(25, "years");
  }
};

const getDefaultStartDate = () => {
  return moment().subtract(25, "years");
};

const getDefaultEndDate = () => {
  return moment().subtract(21, "years");
};

const getDefaultAddressProps = () => {
  return {
    key: "",
    address1: "",
    address1RequiredMessage: "",
    address2: "",
    city: "",
    cityRequiredMessage: "",
    state: "",
    stateRequiredMessage: "",
    zip: "",
    zipRequiredMessage: "",
    country: "",
    countryRequiredMessage: "",
    active: true,
    primary: true
  };
};

const getDefaultEducationProps = () => {
  return {
    key: "",
    schoolName: "",
    schoolNameRequiredMessage: "",
    educationType: "BACHELORS_DEGREE_PROGRAM",
    degree: "",
    startYear: getDefaultStartDate(),
    endYear: getDefaultEndDate(),
    active: true,
    didYouGraduate: true
  };
};

const getDefaultWorkExProps = () => {
  return {
    key: "",
    jobTitle: "",
    jobTitleRequiredMessage: "",
    company: "",
    degree: "",
    startDate: getDefaultStartDate(),
    endDate: getDefaultEndDate(),
    active: true,
    currentJob: true
  };
};

const getDefaultRefProps = () => {
  return {
    key: "",
    firstName: "",
    firstNameRequiredMessage: "",
    lastName: "",
    lastNameRequiredMessage: "",
    relationShip: "",
    phoneNumber: "",
    phoneNumberRequiredMessage: "",
    email: "",
    emailRequiredMessage: "",
    active: true
  };
};

class ProfileStepper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoading: false,
      activeStep: this.props.activeStep,
      bhiredUser: this.getBhiredUser(),
      address: [],
      education: [],
      workExperience: [],
      skills: [],
      skillsSuggestions: [],
      references: []
    };
    this.props.handleData(this.state, this.state.activeStep);
  }

  getBhiredUser = () => {
    return {
      key: this.props.api.bhiredUser.id,
      acceptGDPR: true,
      acceptTerms: true,
      firstName: this.props.api.bhiredUser.firstName != null ? this.props.api.bhiredUser.firstName : "",
      firstNameRequiredMessage: "",
      lastNameRequiredMessage: "",
      phoneRequiredMessage: "",
      dobRequiredMessage: "",
      lastName: this.props.api.bhiredUser.lastName != null ? this.props.api.bhiredUser.lastName : "",
      phone: this.props.api.bhiredUser.phone,
      profileTitle: this.props.api.bhiredUser.profileTitle,
      dob: getDefaultDOB(this.props.api.bhiredUser.dob),
      governmentIssuedID: this.props.api.bhiredUser.governmentIssuedID,
      aboutMe: this.props.api.bhiredUser.aboutMe,
      workAuthorization: this.props.api.bhiredUser.workAuthorization
    };
  };

  handleUserBasicInfo = bhiredUser => {
    this.setState(
      {
        bhiredUser: bhiredUser
      },
      () => {
        this.props.handleData(this.state, this.state.activeStep);
      }
    );
  };

  handleAddress = address => {
    this.setState(
      {
        address: address
      },
      () => {
        this.props.handleData(this.state, this.state.activeStep);
      }
    );
  };

  handleEducation = education => {
    this.setState(
      {
        education: education
      },
      () => {
        this.props.handleData(this.state, this.state.activeStep);
      }
    );
  };

  handleWorkExperience = workExperience => {
    this.setState(
      {
        workExperience: workExperience
      },
      () => {
        this.props.handleData(this.state, this.state.activeStep);
      }
    );
  };

  handleSkills = skills => {
    this.setState(
      {
        skills: skills
      },
      () => {
        this.props.handleData(this.state, this.state.activeStep);
      }
    );
  };

  handleReferences = references => {
    this.setState(
      {
        references: references
      },
      () => {
        this.props.handleData(this.state, this.state.activeStep);
      }
    );
  };

  getSteps = () => {
    return [
      {
        key: "profileBasicInfo",
        value: "Basic Info"
      },
      {
        key: "profileAddress",
        value: "Address"
      },
      {
        key: "profileEducationInfo",
        value: "Education"
      },
      {
        key: "profileWorkExpInfo",
        value: "Work Ex"
      },
      {
        key: "profileSkillsInfo",
        value: "Skills"
      },
      {
        key: "profileReferencesInfo",
        value: "References"
      }
    ];
  };

  validateBasicInfo = () => {
    var isValid = true;
    if (this.state.bhiredUser.firstName === "") {
      isValid = false;
    }
    if (this.state.bhiredUser.lastName === "") {
      isValid = false;
    }
    if (this.state.bhiredUser.phone === "") {
      isValid = false;
    }
    if (this.state.bhiredUser.dob === "" || this.state.bhiredUser.dob === "Invalid date") {
      isValid = false;
    }
    return isValid;
  };

  validateAddress = () => {
    var isValid = true;
    this.setState(
      {
        address: this.state.address.map(item => {
          if (item.address1 === "") {
            isValid = false;
          }
          if (item.city === "") {
            isValid = false;
          }
          if (item.state === "") {
            isValid = false;
          }
          if (item.zip === "") {
            isValid = false;
          }
          if (item.country === "") {
            isValid = false;
          }
          return item;
        })
      },
      () => {}
    );
    return isValid;
  };

  validateEducation = () => {
    var isValid = true;
    this.setState(
      {
        education: this.state.education.map(item => {
          if (item.schoolName === "") {
            isValid = false;
          }
          return item;
        })
      },
      () => {}
    );
    return isValid;
  };

  validateWorkExperience = () => {
    var isValid = true;
    this.setState(
      {
        workExperience: this.state.workExperience.map(item => {
          if (item.jobTitle === "") {
            isValid = false;
          }
          return item;
        })
      },
      () => {}
    );
    return isValid;
  };

  validateReferences = () => {
    var isValid = true;
    this.setState(
      {
        references: this.state.references.map(item => {
          if (item.firstName === "") {
            isValid = false;
          }
          if (item.lastName === "") {
            isValid = false;
          }
          if (item.phoneNumber === "") {
            isValid = false;
          }
          if (item.email === "") {
            isValid = false;
          }
          return item;
        })
      },
      () => {}
    );
    return isValid;
  };

  handleNext = () => {
    const s = this.state.activeStep;
    if (s === 0) {
      if (this.validateBasicInfo()) {
        this.setState({
          dataLoading: true
        });
        this.props.api.updateUserBasicInfo(this.state.bhiredUser).then(bhiredUser => {
          this.props.api.updateMe(bhiredUser);
        });
        this.props.api.getMyAddressArray().then(address => {
          this.setState(state => ({
            activeStep: state.activeStep + 1,
            dataLoading: false,
            address: address.length === 0 ? [getDefaultAddressProps()] : address
          }));
        });
      }
    }
    if (s === 1) {
      if (this.validateAddress()) {
        this.setState({
          dataLoading: true
        });
        this.props.api.createOrUpdateAddressArray(this.state.address).then(address => {
          this.setState({
            address: address
          });
        });
        this.props.api.getMyEducationArray().then(education => {
          this.setState(state => ({
            activeStep: state.activeStep + 1,
            dataLoading: false,
            education: education.length === 0 ? [getDefaultEducationProps()] : education
          }));
        });
      }
    }
    if (s === 2) {
      if (this.validateEducation()) {
        this.setState({
          dataLoading: true
        });
        this.props.api.createOrUpdateEducationArray(this.state.education).then(education => {
          this.setState({
            education: education
          });
        });
        this.props.api.getMyExperienceArray().then(workExperience => {
          this.setState(state => ({
            activeStep: state.activeStep + 1,
            dataLoading: false,
            workExperience: workExperience.length === 0 ? [getDefaultWorkExProps()] : workExperience
          }));
        });
      }
    }
    if (s === 3) {
      if (this.validateWorkExperience()) {
        this.setState({
          dataLoading: true
        });
        this.props.api.createOrUpdateWorkExArray(this.state.workExperience).then(workExperience => {
          this.setState({
            workExperience: workExperience
          });
        });
        this.props.api.getMySkillArray().then(mySkills => {
          this.setState({
            skills: mySkills
          });
          this.props.api.getSkillSuggestions().then(skills => {
            this.setState(state => ({
              activeStep: state.activeStep + 1,
              dataLoading: false,
              skillSuggestions: skills.skillSuggestions
            }));
          });
        });
      }
    }
    if (s === 4) {
      this.setState({
        dataLoading: true
      });
      this.props.api.createOrUpdateSkillsArray(this.state.skills).then(skills => {
        this.setState({
          skills: skills
        });
      });
      this.props.api.getMyReferencesArray().then(references => {
        this.setState(state => ({
          activeStep: state.activeStep + 1,
          dataLoading: false,
          references: references.length === 0 ? [getDefaultRefProps()] : references
        }));
      });
    }
    if (s === 5) {
      this.setState({
        dataLoading: true
      });
      if (this.validateReferences()) {
        this.props.api.createOrUpdateReferencesArray(this.state.references).then(references => {
          this.setState(state => ({
            activeStep: state.activeStep + 1,
            references: references,
            dataLoading: false
          }));
        });
      }
    }
  };

  componentDidMount() {
    const s = this.props.activeStep;
    if (s === 1) {
      this.setState({
        dataLoading: true
      });
      this.props.api.getMyAddressArray().then(address => {
        this.setState({
          dataLoading: false,
          address: address.length === 0 ? [getDefaultAddressProps()] : address
        });
      });
    }
    if (s === 2) {
      this.setState({
        dataLoading: true
      });
      this.props.api.getMyAddressArray().then(address => {
        this.setState(() => ({
          address: address.length === 0 ? [getDefaultAddressProps()] : address
        }));
      });
      this.props.api.getMyEducationArray().then(education => {
        this.setState(() => ({
          dataLoading: false,
          education: education.length === 0 ? [getDefaultEducationProps()] : education
        }));
      });
    }
    if (s === 3) {
      this.setState({
        dataLoading: true
      });
      this.props.api.getMyAddressArray().then(address => {
        this.setState(() => ({
          address: address.length === 0 ? [getDefaultAddressProps()] : address
        }));
      });
      this.props.api.getMyEducationArray().then(education => {
        this.setState(() => ({
          education: education.length === 0 ? [getDefaultEducationProps()] : education
        }));
      });
      this.props.api.getMyExperienceArray().then(workExperience => {
        this.setState(() => ({
          dataLoading: false,
          workExperience: workExperience.length === 0 ? [getDefaultWorkExProps()] : workExperience
        }));
      });
    }
    if (s === 4) {
      this.setState({
        dataLoading: true
      });
      this.props.api.getMyAddressArray().then(address => {
        this.setState(() => ({
          address: address.length === 0 ? [getDefaultAddressProps()] : address
        }));
      });
      this.props.api.getMyEducationArray().then(education => {
        this.setState(() => ({
          education: education.length === 0 ? [getDefaultEducationProps()] : education
        }));
      });
      this.props.api.getMyExperienceArray().then(workExperience => {
        this.setState(() => ({
          workExperience: workExperience.length === 0 ? [getDefaultWorkExProps()] : workExperience
        }));
      });
      this.props.api.getMySkillArray().then(mySkills => {
        this.setState({
          skills: mySkills
        });
        this.props.api.getSkillSuggestions().then(skills => {
          this.setState(() => ({
            dataLoading: false,
            skillSuggestions: skills.skillSuggestions
          }));
        });
      });
    }
    if (s === 5) {
      this.setState({
        dataLoading: true
      });
      this.props.api.getMyAddressArray().then(address => {
        this.setState(() => ({
          address: address.length === 0 ? [getDefaultAddressProps()] : address
        }));
      });
      this.props.api.getMyEducationArray().then(education => {
        this.setState(() => ({
          education: education.length === 0 ? [getDefaultEducationProps()] : education
        }));
      });
      this.props.api.getMyExperienceArray().then(workExperience => {
        this.setState(() => ({
          workExperience: workExperience.length === 0 ? [getDefaultWorkExProps()] : workExperience
        }));
      });
      this.props.api.getMySkillArray().then(mySkills => {
        this.setState({
          skills: mySkills
        });
        this.props.api.getSkillSuggestions().then(skills => {
          this.setState(() => ({
            skillSuggestions: skills.skillSuggestions
          }));
        });
      });
      this.props.api.getMyReferencesArray().then(references => {
        this.setState(() => ({
          dataLoading: false,
          references: references.length === 0 ? [getDefaultRefProps()] : references
        }));
      });
    }
  }

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1
    }));
  };

  getStepContent = stepIndex => {
    switch (stepIndex) {
      case 0:
        return <BasicInfo state={this.state.bhiredUser} handleState={this.handleUserBasicInfo} />;
      case 1:
        return (
          <AddressWrapper
            state={this.state.address}
            handleState={this.handleAddress}
            defAddressProps={getDefaultAddressProps()}
          />
        );
      case 2:
        return (
          <EducationWrapper
            state={this.state.education}
            handleState={this.handleEducation}
            defEducationProps={getDefaultEducationProps()}
          />
        );
      case 3:
        return (
          <WorkExperienceWrapper
            state={this.state.workExperience}
            handleState={this.handleWorkExperience}
            defWorkExProps={getDefaultWorkExProps()}
          />
        );
      case 4:
        return (
          <Skills
            state={this.state.skills}
            handleState={this.handleSkills}
            skillSuggestions={this.state.skillSuggestions}
          />
        );
      case 5:
        return (
          <ReferencesWrapper
            state={this.state.references}
            handleState={this.handleReferences}
            defRefProps={getDefaultRefProps()}
          />
        );
      default:
        return "Unknown";
    }
  };

  render() {
    const { classes } = this.props;
    const steps = this.getSteps();
    const { activeStep, dataLoading } = this.state;
    return (
      <React.Fragment>
        <LoadingIndicator display={dataLoading} />
        <CssBaseline />
        {dataLoading === false ? (
          <main>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map(object => {
                return (
                  <Step key={object.key}>
                    <StepLabel> {object.value} </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {this.state.activeStep === steps.length ? (
              <Typography variant="h6" gutterBottom className={classes.message}>
                Wohoo!
              </Typography>
            ) : (
              <div>
                <div className={classes.content}> {this.getStepContent(activeStep)} </div>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    variant="contained"
                    color="primary"
                    onClick={this.handleBack}
                    className={classes.backButton}>
                    Back
                  </Button>
                  <Button variant="contained" color="primary" onClick={this.handleNext}>
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </Button>
                </div>
              </div>
            )}
          </main>
        ) : null}
      </React.Fragment>
    );
  }
}

ProfileStepper.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(withApi(ProfileStepper));
