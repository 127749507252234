const Images = {
  logo: require("./logo-svg.svg"),
  candidate: require("./candidate.svg"),
  recruiter: require("./recruiter.svg"),
  facebook: require("./facebook.svg"),
  google: require("./google.svg"),
  github: require("./github.svg"),
  logoHorizontal: require("./logo-horizontal.svg"),
  beeTransparent: require("./bee-transparent.png"),
  favicon: require("./favicon.ico"),
  logoTransparent: require("./logo-transparent.png"),
  scallop: require("./scallop.png"),
  dancing: require("./giphy.gif"),
  crying: require("./crying.gif"),
  chatNoBg: require("./chat-no-bg.svg"),
  chatIcon: require("./chat-icon.svg"),
  chatSound: require("./notification.mp3"),
};

export default Images;
