import React from "react";
import {
  Dialog,
  withStyles,
  Typography,
  DialogContent,
  Radio,
  FormControlLabel,
  FormControl,
  Button,
  Grid,
  RadioGroup
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Cancel";
import ClearIcon from "@material-ui/icons/Refresh";
import { withApi } from "../../Api";
import Slider from "@material-ui/lab/Slider";

const styles = theme => ({
  dialogPaper: {
    minHeight: "100vh",
    maxHeight: "100vh",
    minWidth: "50%",
    maxWidth: "50%"
  },
  root: {},
  closeButton: {
    position: "absolute",
    right: 0,
    top: 0,
    margin: "5px",
    cursor: "pointer"
  }
});

const INITIAL_STATE = {
  locationValue: 0,
  mostRelevant: true,
  mostRecent: false,
  filterBySalaryRange: [0, 150000],
  days: "15",
  filterByIndustry: ["INFORMATION_TECHNOLOGY_AND_SERVICES"],
  filterSet: true
};

const INITIAL_STATE_CLEAR = {
  locationValue: 0,
  mostRelevant: true,
  mostRecent: false,
  filterBySalaryRange: [0, 150000],
  days: "15",
  filterByIndustry: [],
  filterSet: false
};

class JobsFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE
    };
  }

  handleClearClick = () => {
    this.setState({ ...INITIAL_STATE_CLEAR });
  };

  handleSortChange = value => {
    this.setState({
      mostRelevant: value === "mostRelevant" ? true : false,
      mostRecent: value === "mostRecent" ? true : false
    });
  };

  handleLocationSliderChange = (event, locationValue) => {
    this.setState({ locationValue });
  };

  handleSalarySliderChange = (event, salary) => {
    this.setState({ filterBySalaryRange: [0, salary] });
  };

  handleDaysPostedChange = event => {
    this.setState({ days: event.target.value }, () => {});
  };

  handleIndustryChange = event => {
    this.setState({ filterByIndustry: [event.target.value] });
  };

  render() {
    const { classes } = this.props;
    const { locationValue } = this.state;
    return (
      <React.Fragment>
        <Dialog
          open={this.props.open ? true : false}
          fullWidth={true}
          classes={{
            paper: classes.dialogPaper
          }}
          transitionDuration={{ enter: 1000, exit: 500 }}>
          <Grid container style={{ paddingBottom: "5%", paddingTop: "2%" }}>
            <Grid item xs={7}>
              <Typography variant="h5" style={{ textAlign: "right" }}>
                Jobs Filter
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <CloseIcon color="primary" className={classes.closeButton} onClick={this.props.handleCloseClick} />
            </Grid>
          </Grid>
          <DialogContent align="center" style={{ overflowX: "hidden" }}>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="body1" style={{ color: "grey", float: "left" }}>
                  Sort By
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <ClearIcon
                  color="primary"
                  style={{ float: "right", cursor: "pointer" }}
                  onClick={() => this.handleClearClick()}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  style={{ float: "left", textAlign: "left" }}
                  control={
                    <Radio
                      checked={this.state.mostRelevant}
                      onChange={() => this.handleSortChange("mostRelevant")}
                      value={this.state.mostRelevant}
                    />
                  }
                  label={<Typography variant="body1">Most Relevant</Typography>}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  style={{ float: "right", textAlign: "right" }}
                  control={
                    <Radio
                      checked={this.state.mostRecent}
                      onChange={() => this.handleSortChange("mostRecent")}
                      value={this.state.mostRecent}
                      color="primary"
                    />
                  }
                  label={<Typography variant="body1">Most Recent</Typography>}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  style={{
                    color: "grey",
                    textAlign: "left",
                    paddingBottom: "3%",
                    paddingTop: "3%"
                  }}>
                  Location
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Slider value={locationValue} min={0} max={250} step={25} onChange={this.handleLocationSliderChange} />
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="body1"
                  style={{
                    paddingTop: "3%",
                    textAlign: "left"
                  }}>
                  {this.props.location !== undefined ? this.props.location : "San Francisco"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="body1"
                  style={{
                    paddingTop: "3%",
                    textAlign: "right"
                  }}>
                  WithIn&nbsp;{locationValue}&nbsp;Miles
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  style={{ color: "grey", paddingTop: "5%", paddingBottom: "3%", textAlign: "left" }}>
                  Salary Range
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Slider
                  value={this.state.filterBySalaryRange[1]}
                  min={0}
                  max={500000}
                  step={25000}
                  onChange={this.handleSalarySliderChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" style={{ float: "right", paddingTop: "2%" }}>
                  ${this.state.filterBySalaryRange[1]}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <Typography
                  variant="body1"
                  style={{ color: "grey", paddingTop: "5%", paddingBottom: "1%", textAlign: "left" }}>
                  Date Posted
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" style={{ color: "grey", paddingTop: "5%", paddingBottom: "1%" }}>
                  Industry
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FormControl component="fieldset" style={{ textAlign: "left", display: "flex" }}>
                  <RadioGroup
                    aria-label="days"
                    name="days"
                    value={this.state.days}
                    onChange={this.handleDaysPostedChange}>
                    <FormControlLabel
                      value="1"
                      control={<Radio color="primary" />}
                      label={<Typography variant="body1">1 day ago</Typography>}
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="7"
                      control={<Radio color="primary" />}
                      label={<Typography variant="body1">7 days ago</Typography>}
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="14"
                      control={<Radio color="primary" />}
                      label={<Typography variant="body1">14 days ago</Typography>}
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="15"
                      control={<Radio color="primary" />}
                      label={<Typography variant="body1">15+ days ago</Typography>}
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl component="fieldset" style={{ textAlign: "right", float: "right", display: "end" }}>
                  <RadioGroup
                    aria-label="industry"
                    name="industry"
                    value={this.state.filterByIndustry[0]}
                    onChange={this.handleIndustryChange}>
                    <FormControlLabel
                      value="INFORMATION_TECHNOLOGY_AND_SERVICES"
                      control={
                        <Radio
                          color="primary"
                          checked={this.state.filterByIndustry[0] === "INFORMATION_TECHNOLOGY_AND_SERVICES"}
                        />
                      }
                      label={<Typography variant="body1">IT & Services</Typography>}
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="COMPUTER_SOFTWARE"
                      control={
                        <Radio color="primary" checked={this.state.filterByIndustry[0] === "COMPUTER_SOFTWARE"} />
                      }
                      label={<Typography variant="body1">Computer Software</Typography>}
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="INTERNET"
                      control={<Radio color="primary" checked={this.state.filterByIndustry[0] === "INTERNET"} />}
                      label={<Typography variant="body1">Internet</Typography>}
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="OTHER"
                      control={<Radio color="primary" checked={this.state.filterByIndustry[0] === "OTHER"} />}
                      label={<Typography variant="body1">Other</Typography>}
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <div align="center" style={{ paddingTop: "10px" }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={() => this.props.filterJobs(this.state)}>
                Apply Filter
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(withApi(JobsFilter));
