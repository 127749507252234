import React from "react";
import Linkify from "react-linkify";
import { Typography } from "@material-ui/core";
const TextMessage = props => {
  var formattedTimestamp = Intl.DateTimeFormat("en-US", {
    weekday: "short",
    hour: "numeric",
    minute: "2-digit",
    second: "2-digit"
  }).format(props.timestamp.toDate());
  return (
    <React.Fragment>
      <div className="sc-message--text">
        <Typography>{<Linkify properties={{ target: "_blank" }}>{props.data.text}</Linkify>}</Typography>
        <Typography style={{ float: "right", fontSize: "8px" }}>{formattedTimestamp}</Typography>
      </div>
    </React.Fragment>
  );
};

export default TextMessage;
