import React, { Component } from "react";
import {
  Paper,
  CssBaseline,
  FormControl,
  TextField,
  withStyles,
  Grid,
  IconButton,
  Checkbox,
  FormControlLabel
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import { DropzoneArea } from "material-ui-dropzone";
import { withApi } from "../../Api";
import DownloadIcon from "@material-ui/icons/CloudDownloadSharp";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import { AsYouType } from "libphonenumber-js";

const styles = theme => ({
  main: {
    display: "flex",
    width: "100%",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(8)
  },
  paper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
  },
  files: {},
  dropzone: {
    padding: "5px",
    marginTop: "10px",
    height: "10%",
    border: "1px dotted",
    background: "#FFF5EE"
  },
  dropzoneText: {
    fontSize: "18px"
  },
  checkBoxLabel: {
    fontSize: "16px",
    textAlign: "left"
  }
});

class BasicInfo extends Component {
  componentLoaded = false;
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.state,
      basicInfoDocs: []
    };
  }

  onChange = event => {
    this.setState(
      {
        [event.target.name]: event.target.value
      },
      () => {
        this.props.handleState(this.state);
      }
    );
  };

  handleDateChange = date => {
    this.setState(
      {
        dob: moment(date).format("MM/DD/YYYY")
      },
      () => {
        this.props.handleState(this.state);
      }
    );
  };

  handleCheckChange = name => event => {
    this.setState(
      {
        [name]: event.target.checked
      },
      () => {
        this.props.handleState(this.state);
      }
    );
  };

  validate = event => {
    if ("firstName" === event.target.name) {
      if (event.target.value !== "") {
        this.setState({
          firstNameRequiredMessage: ""
        });
      } else {
        this.setState({
          firstNameRequiredMessage: "First Name Required"
        });
      }
    }
    if ("lastName" === event.target.name) {
      if (event.target.value !== "") {
        this.setState({
          lastNameRequiredMessage: ""
        });
      } else {
        this.setState({
          lastNameRequiredMessage: "Last Name Required"
        });
      }
    }
    if ("phone" === event.target.name) {
      if (event.target.value !== "") {
        let p = new AsYouType("US").input(this.state.phone);
        this.setState({
          phoneRequiredMessage: "",
          phone: p
        });
      } else {
        this.setState({
          phoneRequiredMessage: "Phone Number Required"
        });
      }
    }
  };

  downloadProfileDocument = key => {
    this.props.api.downloadProfileDocument(key);
  };

  deleteProfileDocument = key => {
    this.props.api.deleteProfileDocument(key).then(() => {
      const basicInfoDocsUpdated = this.state.basicInfoDocs.filter(item => item.key !== key);
      this.setState({
        basicInfoDocs: basicInfoDocsUpdated
      });
    });
  };

  onVideoDrop = file => {
    let data = new FormData();
    data.append("file", file);
    data.append("name", file.name);
    this.props.api.uploadVideoProfilePicture(data);
  };

  onDrop = file => {
    let data = new FormData();
    data.append("file", file);
    data.append("name", file.name);
    this.props.api.uploadProfileDocument(data, "BASIC_INFO");
  };

  componentDidMount() {
    this.componentLoaded = true;
    this.props.api.getProfileDocuments("BASIC_INFO").then(basicInfoDocs => {
      if (this.componentLoaded === true) {
        this.setState({
          basicInfoDocs: basicInfoDocs
        });
      }
    });
  }

  componentWillUnmount() {
    this.componentLoaded = false;
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <main className={classes.main}>
          <CssBaseline />
          <Paper className={classes.paper}>
            <form>
              <DropzoneArea
                dropZoneClass={classes.dropzone}
                dropzoneParagraphClass={classes.dropzoneText}
                dropzoneText="Drag or Drop Your Video Profile Clip"
                filesLimit={1}
                acceptedFiles={['.mp4', '.mkv', '.avi', '.mp3', '.*']}
                onDrop={this.onVideoDrop}
                minFileSize={0}
                maxFileSize={40242880}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                  </div>
                )}
              </DropzoneArea>
              <FormControl margin="normal" required fullWidth>
                <TextField
                  required
                  label="First Name"
                  placeholder="First Name"
                  name="firstName"
                  value={this.state.firstName}
                  onChange={this.onChange}
                  error={this.state.firstNameRequiredMessage === "" ? false : true}
                  helperText={this.state.firstNameRequiredMessage}
                  onKeyDown={this.validate}
                  onKeyUp={this.validate}
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <TextField
                  required
                  label="Last Name"
                  placeholder="Last Name"
                  name="lastName"
                  value={this.state.lastName}
                  onChange={this.onChange}
                  error={this.state.lastNameRequiredMessage === "" ? false : true}
                  helperText={this.state.lastNameRequiredMessage}
                  onKeyDown={this.validate}
                  onKeyUp={this.validate}
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <TextField
                  required
                  label="Phone Number"
                  placeholder="Phone Number"
                  name="phone"
                  value={this.state.phone}
                  onChange={this.onChange}
                  error={this.state.phoneRequiredMessage === "" ? false : true}
                  helperText={this.state.phoneRequiredMessage}
                  onKeyDown={this.validate}
                  onKeyUp={this.validate}
                />
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <TextField label="Title" placeholder="Title" name="profileTitle" value={this.state.profileTitle} onChange={this.onChange} />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    required
                    clearable
                    animateYearScrolling
                    margin="normal"
                    openTo="year"
                    format="MM/dd/yyyy"
                    label="Date Of Birth"
                    minDate={new Date("01/01/1971")}
                    value={this.state.dob}
                    onChange={this.handleDateChange}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <TextField
                  label="Government Issued ID"
                  placeholder="Government Issued ID"
                  name="governmentIssuedID"
                  value={this.state.governmentIssuedID}
                  onChange={this.onChange}
                />
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <TextField
                  multiline={true}
                  rows={4}
                  variant="outlined"
                  placeholder="About Me"
                  value={this.state.aboutMe}
                  name="aboutMe"
                  onChange={this.onChange}
                />
              </FormControl>
              <FormControl margin="normal">
                <FormControlLabel
                  labelPlacement="start"
                  classes={{
                    label: classes.checkBoxLabel
                  }}
                  control={
                    <Checkbox
                      checked={this.state.workAuthorization}
                      onChange={this.handleCheckChange("workAuthorization")}
                      value="workAuthorization"
                    />
                  }
                  label="Authorized to Work in USA"
                />
              </FormControl>
            </form>
            <div>
              <Grid container spacing={2}>
                {this.state.basicInfoDocs !== undefined
                  ? this.state.basicInfoDocs.map((item, index) => (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        style={{
                          paddingTop: "10px",
                          background: "#FFF5EE",
                          margin: "5px",
                          textAlign: "left",
                          borderRadius: "2%"
                        }}
                      >
                        {item.fileName}
                        <div style={{ float: "right" }}>
                          <IconButton color="primary" variant="outlined" onClick={() => this.downloadProfileDocument(item.key)}>
                            <DownloadIcon />
                          </IconButton>
                          <IconButton color="primary" variant="outlined" onClick={() => this.deleteProfileDocument(item.key)}>
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      </Grid>
                    ))
                  : null}
              </Grid>
              <DropzoneArea
                dropZoneClass={classes.dropzone}
                dropzoneParagraphClass={classes.dropzoneText}
                filesLimit={5}
                onDrop={this.onDrop}
                minSize={0}
                maxSize={5242880}
                multiple
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                  </div>
                )}
              </DropzoneArea>
            </div>
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(withApi(BasicInfo));
