import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Paper,
  Button,
  CssBaseline,
  FormControl,
  TextField,
  withStyles,
  InputAdornment,
  IconButton
} from "@material-ui/core";
import { Visibility, VisibilityOff, Email, Lock } from "@material-ui/icons";
import styled from "styled-components";
import { withApi } from "../Api";
import * as Routes from "../../common/routes";
import { validatePassword } from "../../common/helpers.js";
import LoadingIndicator from "../../common/loading";

const styles = theme => ({
  main: {
    width: "auto",
    display: "flex",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
  },
  submit: {
    marginTop: theme.spacing(3)
  }
});

const INITIAL_STATE = {
  email: "",
  passwordOne: "",
  passwordTwo: "",
  error: "",
  disabled: false,
  isLoading: false
};

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    this.setState({
      disabled: true,
      isLoading: true
    });
    const { email, passwordOne } = this.state;
    this.props.api
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(() => {
        this.props.api.sendVerificationEmail();
        this.setState({
          email: "",
          passwordOne: "",
          passwordTwo: "",
          error: "",
          isLoading: false
        });
        this.props.history.push(Routes.REGISTRATION_SUCCESSFUL);
      })
      .catch(error => {
        switch (error.code) {
          case "auth/weak-password":
            error.passwordMessage = "Min 8 chars: Atleast 1 uppercase, 1 lowercase and 1 digit";
            break;
          case "auth/invalid-email":
            error.emailMessage = "Invalid Email Address";
            break;
          case "auth/wrong-password":
            error.passwordMessage = "Invalid Password";
            break;
          case "auth/email-already-in-use":
            error.emailMessage = "Email Address Already In Use";
            this.props.api.tryLinkingAccount(email).then(methods => {
              var existingProvider = methods[0];
              if (!methods.find(method => method === "password")) {
                error.emailMessage = "Linking accounts...";
                this.props.api
                  .doSocialSignIn(existingProvider)
                  .then(() => {
                    var credential = this.props.api.createEmailPasswordCredential(email, passwordOne);
                    this.props.api.auth.currentUser
                      .linkAndRetrieveDataWithCredential(credential)
                      .then(() => {
                        this.props.history.push(Routes.LINKED_ACCOUNT_MESSAGE);
                      })
                      .catch(error => {
                        console.log("Account linking error", error);
                      });
                  })
                  .catch(error => {
                    console.log("Account linking error", error);
                  });
              }
            });
            break;
          default:
            error.emailMessage = error.code;
        }
        this.setState({
          error,
          isLoading: false
        });
      });
    event.preventDefault();
  };

  onChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleClickShowPassword = () => {
    this.setState(state => ({
      showPassword: !state.showPassword
    }));
  };

  onKeyDown = () => {
    this.setState({
      error: "",
      disabled: false
    });
  };

  render() {
    const { classes } = this.props;
    const { disabled, passwordOne, passwordTwo } = this.state;
    const invalid = passwordOne !== passwordTwo && passwordOne !== "";
    const passwordsNotMatchingMessage =
      passwordOne !== "" && passwordTwo.length > 4 && passwordOne !== passwordTwo ? "Passwords don't match" : "";
    const StyledAdornment = styled(InputAdornment)`
      margin-right: 10px;
    `;
    var isPasswordOneValid = validatePassword(passwordOne);
    const passwordsOneStrengthNotCoolMessage =
      passwordOne !== "" && isPasswordOneValid !== "OK" ? isPasswordOneValid : "";
    return (
      <React.Fragment>
        <LoadingIndicator display={this.state.isLoading} />
        <main className={classes.main}>
          <CssBaseline />
          <Paper className={classes.paper}>
            <form onKeyDown={this.onKeyDown} onSubmit={this.onSubmit}>
              <FormControl margin="normal" required fullWidth>
                <TextField
                  required
                  placeholder="Email Address"
                  name="email"
                  autoComplete="new-email"
                  onChange={this.onChange}
                  error={this.state.error.emailMessage == null ? false : true}
                  helperText={this.state.error.emailMessage}
                  InputProps={{
                    startAdornment: (
                      <StyledAdornment>
                        <Email color="disabled" />
                      </StyledAdornment>
                    )
                  }}
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <TextField
                  required
                  placeholder="Password"
                  title="Password should be at least 8 characters long with 1 digit, 1 uppercase and 1 lower case characters"
                  name="passwordOne"
                  autoComplete="new-password"
                  type={this.state.showPassword ? "text" : "password"}
                  onChange={this.onChange}
                  error={
                    (this.state.error.passwordMessage == null ? false : true,
                    passwordsOneStrengthNotCoolMessage === "" ? false : true)
                  }
                  helperText={(this.state.error.passwordMessage, passwordsOneStrengthNotCoolMessage)}
                  InputProps={{
                    startAdornment: (
                      <StyledAdornment>
                        <Lock color="disabled" />
                      </StyledAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="Toggle password visibility" onClick={this.handleClickShowPassword}>
                          {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <TextField
                  required
                  placeholder="Repeat Password"
                  name="passwordTwo"
                  autoComplete="new-password"
                  type={this.state.showPassword ? "text" : "password"}
                  onChange={this.onChange}
                  error={
                    (this.state.error.passwordMessage == null ? false : true,
                    passwordsNotMatchingMessage === "" ? false : true)
                  }
                  helperText={(this.state.error.passwordMessage, passwordsNotMatchingMessage)}
                  InputProps={{
                    startAdornment: (
                      <StyledAdornment>
                        <Lock color="disabled" />
                      </StyledAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="Toggle password visibility" onClick={this.handleClickShowPassword}>
                          {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </FormControl>
              <div className={classes.submit}>
                <Button disabled={disabled || invalid} type="submit" fullWidth variant="contained" color="primary">
                  Sign Up
                </Button>
              </div>
            </form>
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(withRouter(withApi(SignUp)));
