import React, { Component } from "react";
import { AddCircle } from "@material-ui/icons";
import { withStyles, Button } from "@material-ui/core";
import Education from "./education";

const styles = theme => ({
  main: {},
  fab: {}
});

class EducationWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      education: this.props.state
    };
  }

  onAddChild = () => {
    this.setState(
      prevState => ({
        education: [...prevState.education, this.props.defEducationProps]
      }),
      () => {
        this.props.handleState(this.state.education);
      }
    );
  };

  onRemoveMe = removeMeIndex => {
    let eArray = this.state.education;
    if (eArray !== undefined) {
      var index = eArray.indexOf(removeMeIndex);
      eArray.splice(index, 1);
      this.setState(
        {
          education: eArray
        },
        () => {
          this.props.handleState(this.state.education);
        }
      );
    }
  };

  handleState = (educationItem, index) => {
    const eds = this.state.education.slice();
    eds[index] = educationItem;
    this.setState(
      {
        education: eds
      },
      () => {
        this.props.handleState(this.state.education);
      }
    );
  };

  renderChildren = () => {
    const educationArray = this.state.education;
    const children = educationArray.map((educationItem, i) =>
      educationItem.active === true ? (
        <Education
          key={["education", i].join("_")}
          state={educationItem}
          handleState={this.handleState}
          index={i}
          onRemoveMe={this.onRemoveMe}
          showRemoveMe={i > 0 ? true : false}
        />
      ) : null
    );
    return <div>{children}</div>;
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <main className={classes.main}>
          {this.renderChildren()}
          <Button color="primary" className={classes.fab} onClick={this.onAddChild}>
            <AddCircle />
            &nbsp; Add New
          </Button>
        </main>
        <div
          ref={el => {
            this.el = el;
          }}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(EducationWrapper);
