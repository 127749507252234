import React from "react";
import {
  Dialog,
  withStyles,
  Typography,
  DialogContent,
  Radio,
  FormControlLabel,
  Button,
  Grid
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Cancel";
import ClearIcon from "@material-ui/icons/Refresh";
import { withApi } from "../../Api";
import Slider from "@material-ui/lab/Slider";
import RatingIcon from "@material-ui/icons/StarRate";

const styles = theme => ({
  dialogPaper: {
    minHeight: "100vh",
    maxHeight: "100vh",
    minWidth: "50%",
    maxWidth: "50%"
  },
  root: {},
  title: {
    position: "relative",
    left: 0,
    top: 0
  },
  closeButton: {
    position: "absolute",
    right: 0,
    top: 0,
    margin: "5px",
    cursor: "pointer"
  },
  slider: {}
});

const INITIAL_STATE = {
  miles: 50,
  mostRelevant: true,
  candidateRating: false,
  rating: 5,
  numberOfYearsOfExperience: 5,
  filterSet: true,
  ratingOne: false,
  ratingTwo: false,
  ratingThree: false,
  ratingFour: false,
  ratingFive: false
};

const INITIAL_STATE_CLEAR = {
  miles: 0,
  mostRelevant: true,
  candidateRating: false,
  rating: 0,
  numberOfYearsOfExperience: 0,
  filterSet: false,
  ratingOne: false,
  ratingTwo: false,
  ratingThree: false,
  ratingFour: false,
  ratingFive: false
};

class HiveFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE
    };
  }

  handleClearClick = () => {
    this.setState({ ...INITIAL_STATE_CLEAR });
  };

  handleSortChange = value => {
    this.setState({
      mostRelevant: value === "mostRelevant" ? true : false,
      candidateRating: value === "candidateRating" ? true : false
    });
  };

  handleLocationSliderChange = (event, miles) => {
    this.setState({ miles });
  };

  handleExpSliderChange = (event, numberOfYearsOfExperience) => {
    this.setState({ numberOfYearsOfExperience });
  };

  handleRating = ratingOrder => {
    if (ratingOrder === 1) {
      this.setState(state => ({
        ratingOne: !state.ratingOne,
        ratingTwo: false,
        ratingThree: false,
        ratingFour: false,
        ratingFive: false,
        rating: !state.ratingOne === true ? 1 : 0
      }));
    }
    if (ratingOrder === 2) {
      this.setState(state => ({
        ratingOne: !state.ratingTwo,
        ratingTwo: !state.ratingTwo,
        ratingThree: false,
        ratingFour: false,
        ratingFive: false,
        rating: !state.ratingTwo === true ? 2 : 0
      }));
    }
    if (ratingOrder === 3) {
      this.setState(state => ({
        ratingOne: !state.ratingThree,
        ratingTwo: !state.ratingThree,
        ratingThree: !state.ratingThree,
        ratingFour: false,
        ratingFive: false,
        rating: !state.ratingThree === true ? 3 : 0
      }));
    }
    if (ratingOrder === 4) {
      this.setState(state => ({
        ratingOne: !state.ratingFour,
        ratingTwo: !state.ratingFour,
        ratingThree: !state.ratingFour,
        ratingFour: !state.ratingFour,
        ratingFive: false,
        rating: !state.ratingFour === true ? 4 : 0
      }));
    }
    if (ratingOrder === 5) {
      this.setState(state => ({
        ratingOne: !state.ratingFive,
        ratingTwo: !state.ratingFive,
        ratingThree: !state.ratingFive,
        ratingFour: !state.ratingFive,
        ratingFive: !state.ratingFive,
        rating: !state.ratingFive === true ? 5 : 0
      }));
    }
  };

  render() {
    const { classes } = this.props;
    const { miles, numberOfYearsOfExperience } = this.state;
    return (
      <React.Fragment>
        <Dialog
          open={this.props.open ? true : false}
          fullWidth={true}
          classes={{
            paper: classes.dialogPaper
          }}
          transitionDuration={{ enter: 1000, exit: 0 }}>
          <Grid container style={{ paddingBottom: "5%", paddingTop: "2%" }}>
            <Grid item xs={7}>
              <Typography variant="h5" style={{ textAlign: "right" }}>
                Hive Filter
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <CloseIcon color="primary" className={classes.closeButton} onClick={this.props.handleCloseClick} />
            </Grid>
          </Grid>
          <DialogContent align="center" style={{ overflowX: "hidden" }}>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="body1" style={{ color: "grey", float: "left" }}>
                  Sort By
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <ClearIcon
                  color="primary"
                  style={{ float: "right", cursor: "pointer" }}
                  onClick={() => this.handleClearClick()}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  style={{ float: "left", textAlign: "left" }}
                  control={
                    <Radio
                      checked={this.state.mostRelevant}
                      onChange={() => this.handleSortChange("mostRelevant")}
                      value={this.state.mostRelevant}
                    />
                  }
                  label={<Typography variant="body1">Most Relevant</Typography>}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  style={{ float: "right", textAlign: "right" }}
                  control={
                    <Radio
                      checked={this.state.candidateRating}
                      onChange={() => this.handleSortChange("candidateRating")}
                      value={this.state.candidateRating}
                      color="primary"
                    />
                  }
                  label={<Typography variant="body1">Candidate Rating</Typography>}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  style={{
                    color: "grey",
                    textAlign: "left",
                    paddingBottom: "3%",
                    paddingTop: "3%"
                  }}>
                  Location
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Slider value={miles} min={25} max={250} step={25} onChange={this.handleLocationSliderChange} />
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="body1"
                  style={{
                    paddingTop: "3%",
                    textAlign: "left"
                  }}>
                  {this.props.location !== undefined ? this.props.location : "San Francisco"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="body1"
                  style={{
                    paddingTop: "3%",
                    textAlign: "right"
                  }}>
                  WithIn&nbsp;{miles}&nbsp;Miles
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  style={{
                    color: "grey",
                    textAlign: "left",
                    paddingBottom: "3%",
                    paddingTop: "7%"
                  }}>
                  Experience
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Slider
                  value={numberOfYearsOfExperience}
                  min={0}
                  max={50}
                  step={5}
                  onChange={this.handleExpSliderChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  style={{
                    paddingTop: "3%",
                    textAlign: "right"
                  }}>
                  {numberOfYearsOfExperience}&nbsp;Years
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  style={{ color: "grey", paddingTop: "2%", paddingBottom: "1%", textAlign: "left" }}>
                  Rating
                </Typography>
              </Grid>
              <Grid item style={{ float: "left" }}>
                {this.state.ratingOne === true ? (
                  <RatingIcon style={{ color: "#B9090B" }} onClick={() => this.handleRating(1)} />
                ) : (
                  <RatingIcon style={{ color: "grey" }} onClick={() => this.handleRating(1)} />
                )}
                {this.state.ratingTwo === true ? (
                  <RatingIcon style={{ color: "#B9090B" }} onClick={() => this.handleRating(2)} />
                ) : (
                  <RatingIcon style={{ color: "grey" }} onClick={() => this.handleRating(2)} />
                )}
                {this.state.ratingThree === true ? (
                  <RatingIcon style={{ color: "#B9090B" }} onClick={() => this.handleRating(3)} />
                ) : (
                  <RatingIcon style={{ color: "grey" }} onClick={() => this.handleRating(3)} />
                )}
                {this.state.ratingFour === true ? (
                  <RatingIcon style={{ color: "#B9090B" }} onClick={() => this.handleRating(4)} />
                ) : (
                  <RatingIcon style={{ color: "grey" }} onClick={() => this.handleRating(4)} />
                )}
                {this.state.ratingFive === true ? (
                  <RatingIcon style={{ color: "#B9090B" }} onClick={() => this.handleRating(5)} />
                ) : (
                  <RatingIcon style={{ color: "grey" }} onClick={() => this.handleRating(5)} />
                )}
              </Grid>
            </Grid>
            <div align="center" style={{ paddingTop: "10px" }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={() => this.props.filterHive(this.state)}>
                Apply Filter
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(withApi(HiveFilter));
