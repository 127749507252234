import React, { Component } from "react";
import {
  Paper,
  CssBaseline,
  FormControl,
  TextField,
  withStyles,
  MenuItem,
  InputLabel,
  Select
} from "@material-ui/core";

const styles = theme => ({
  main: {
    display: "flex",
    width: "100%",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(8)
  },
  paper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
  }
});

class NewJobInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.state
    };
  }

  onChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
    this.props.handleState(event);
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <main className={classes.main}>
          <CssBaseline />
          <Paper className={classes.paper}>
            <form>
              <FormControl margin="normal" required fullWidth>
                <TextField
                  required
                  placeholder="Company"
                  label="Company"
                  name="company"
                  value={this.state.company}
                  onChange={this.onChange}
                  error={this.props.state.companyRequiredMessage === "" ? false : true}
                  helperText={this.props.state.companyRequiredMessage}
                  onKeyDown={this.props.clearErrorMessage}
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <TextField
                  required
                  label="Job Title"
                  placeholder="Job Title"
                  name="title"
                  value={this.state.title}
                  onChange={this.onChange}
                  error={this.props.state.titleRequiredMessage === "" ? false : true}
                  helperText={this.props.state.titleRequiredMessage}
                  onKeyDown={this.props.clearErrorMessage}
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <TextField
                  required
                  label="Location"
                  placeholder="Location"
                  name="location"
                  value={this.state.location}
                  onChange={this.onChange}
                  error={this.props.state.locationRequiredMessage === "" ? false : true}
                  helperText={this.props.state.locationRequiredMessage}
                  onKeyDown={this.props.clearErrorMessage}
                />
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="employmentType-simple"> Employment Type </InputLabel>
                <Select
                  autoWidth
                  inputProps={{
                    name: "employmentType",
                    id: "employmentType-simple"
                  }}
                  value={this.state.employmentType}
                  name="employmentType"
                  onChange={this.onChange}
                  style={{
                    textAlign: "left"
                  }}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "right"
                    }
                  }}>
                  <MenuItem value="FULL_TIME"> Full Time </MenuItem> <MenuItem value="PART_TIME"> Part Time </MenuItem>
                  <MenuItem value="REMOTE"> Remote </MenuItem>
                </Select>
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="seniorityLevel-simple"> Seniority Level </InputLabel>
                <Select
                  autoWidth
                  inputProps={{
                    name: "seniorityLevel",
                    id: "seniorityLevel-simple"
                  }}
                  value={this.state.seniorityLevel}
                  name="seniorityLevel"
                  onChange={this.onChange}
                  style={{
                    textAlign: "left"
                  }}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "right"
                    }
                  }}>
                  <MenuItem value="SENIOR_LEVEL"> Senior </MenuItem> <MenuItem value="MID_LEVEL"> Mid </MenuItem>
                  <MenuItem value="JUNIOR_LEVEL"> Junior </MenuItem>
                </Select>
              </FormControl>
            </form>
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(NewJobInfo);
