export function formatDate(dateString) {
  const date = new Date(dateString);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  return monthNames[monthIndex] + " " + year;
}

export function formatDateTime(dateTimeString) {
  const date = new Date(dateTimeString);
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  return date.getDate() + " " + monthNames[monthIndex] + " " + year + " - " + date.getHours() + ":" + date.getMinutes();
}

export const toNormalCase = input => {
  let words = [];
  let subject;
  for (let i = 0; i < input.length; i++) {
    if (i === 0 || isUpperCase(input[i])) {
      if (subject) {
        words.push(subject);
      }
      subject = "";
    }
    subject += i === 0 ? input[i].toUpperCase() : input[i];
  }
  if (subject) {
    words.push(subject);
  }
  return words.join(" ");
};

const isUpperCase = character => {
  if (character === character.toUpperCase()) {
    return true;
  } else {
    return false;
  }
};

export const camelCase = input => {
  input = input
    .trim()
    .toLowerCase()
    .replace(/\./g, " ");
  input = input
    .trim()
    .toLowerCase()
    .replace(/\//g, " ");
  input = input
    .trim()
    .toLowerCase()
    .replace(/\\/g, " ");
  input = input
    .trim()
    .toLowerCase()
    .replace("/#/g", " ");
  let words = input.split(" ");
  let camelCased = "";
  for (let i = 0; i < words.length; i++) {
    let word = words[i];
    if (i > 0) {
      camelCased += word[0].toUpperCase() + word.substring(1);
    } else {
      camelCased += word;
    }
  }
  return camelCased;
};

export const bhiredColorArray = [
  "#F44336",
  "#e91e63",
  "#9c27b0",
  "#673ab7",
  "#ff9800",
  "#ff5722",
  "#795548",
  "#607d8b",
  "#3f51b5",
  "#2196F3",
  "#00bcd4",
  "#009688",
  "#2196F3",
  "#32c787",
  "#00BCD4",
  "#ff5652",
  "#ffc107",
  "#ff85af",
  "#FF9800",
  "#39bbb0",
  "#4CAF50",
  "#ffeb3b",
  "#ffc107"
];

export function getAvatarColor(name) {
  name = name.substr(0, 6);
  var hash = 0;
  for (var i = 0; i < name.length; i++) {
    hash = 31 * hash + name.charCodeAt(i);
  }
  var index = Math.abs(hash % bhiredColorArray.length);
  return bhiredColorArray[index];
}

export function validatePassword(password) {
  if (password.length < 8) {
    return "Password too short; Minimum 8 characters required";
  } else if (password.length > 20) {
    return "Password too long; Maximun 20 characters allowed";
  } else if (password.search(/[0-9]/) < 0) {
    return "Password should contain at least 1 digit";
  } else if (password.search(/[a-z]/) < 0) {
    return "Password should contain at least 1 lowercase character";
  } else if (password.search(/[A-Z]/) < 0) {
    return "Password should contain at least 1 uppercase character";
  }
  return "OK";
}

export function isValidEmail(email) {
  return new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email);
}
