import React from "react";
import PropTypes from "prop-types";
import deburr from "lodash/deburr";
import Autosuggest from "react-autosuggest";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import {
  TextField,
  Paper,
  Popper,
  MenuItem,
  InputAdornment,
  withStyles,
  IconButton,
  ListItemText
} from "@material-ui/core";
import FilterIcon from "@material-ui/icons/FilterListOutlined";
import styled from "styled-components";
import { withApi } from "../Api";
import JobsFilter from "./Jobs/jobsFilter";
import CandidatesFilter from "./Profile/candidatesFilter";
import HiveFilter from "./Hive/hiveFilter";

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion, query);
  const parts = parse(suggestion, matches);
  const style = isHighlighted ? "#fff" : "#000";
  return (
    <MenuItem component="div">
      <ListItemText
        disableTypography
        style={{
          color: style
        }}>
        {parts.map((part, index) => {
          return part.highlight ? (
            <span
              key={String(index)}
              style={{
                fontWeight: 500
              }}>
              {part.text}
            </span>
          ) : (
            <strong
              key={String(index)}
              style={{
                fontWeight: 300
              }}>
              {part.text}
            </strong>
          );
        })}
      </ListItemText>
    </MenuItem>
  );
}

const styles = theme => ({
  root: {
    marginLeft: "15px",
    padding: "5px",
    backgroundColor: "#ffffff",
    width: "60%",
    ...theme.mixins.gutters()
  },
  suggestionsContainerOpen: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0
  },
  suggestion: {
    display: "block"
  },
  iconButtonOff: { color: "grey" },
  iconButtonOn: { color: "#B9090B" },
  suggestionsList: {
    margin: 0,
    paddingLeft: 0,
    marginTop: theme.spacing(1),
    listStyleType: "none"
  }
});

class IntegrationAutosuggest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      single: "",
      popper: "",
      suggestions: [],
      query: "",
      showJobsFilter: false,
      jobsFilter: null,
      jobsFilterSet: false,
      showCandidatesFilter: false,
      candidatesFilter: null,
      candidatesFilterSet: false,
      showHiveFilter: false,
      hiveFilter: null,
      hiveFilterSet: false
    };
  }

  renderInputComponent = inputProps => {
    const { classes, inputRef = () => {}, ref, ...other } = inputProps;
    const StyledAdornment = styled(InputAdornment)`
      }`;
    return (
      <React.Fragment>
        <TextField
          value={this.state.query}
          noValidate
          autoComplete="off"
          fullWidth
          InputProps={{
            inputRef: node => {
              ref(node);
              inputRef(node);
            },
            classes: {
              input: classes.input
            },
            endAdornment: (
              <StyledAdornment>
                <IconButton
                  className={
                    this.state.jobsFilterSet === true ||
                    this.state.showCandidatesFilter === true ||
                    this.state.showHiveFilter === true
                      ? classes.iconButtonOn
                      : classes.iconButtonOff
                  }
                  title={
                    this.state.jobsFilterSet === true ||
                    this.state.showCandidatesFilter === true ||
                    this.state.showHiveFilter === true
                      ? "Filters are On"
                      : "Filters are Off"
                  }
                  aria-label="Search"
                  onClick={this.handleFilterIconClick}>
                  <FilterIcon />
                </IconButton>
              </StyledAdornment>
            )
          }}
          {...other}
        />
        <JobsFilter
          filterJobs={this.filterJobs}
          open={this.state.showJobsFilter}
          location={this.props.location}
          handleCloseClick={this.handleFilterIconClick}
        />
        <CandidatesFilter
          filterCandidates={this.filterCandidates}
          open={this.state.showCandidatesFilter}
          location={this.props.location}
          handleCloseClick={this.handleFilterIconClick}
        />
        <HiveFilter
          filterHive={this.filterHive}
          open={this.state.showHiveFilter}
          location={this.props.location}
          handleCloseClick={this.handleFilterIconClick}
        />
      </React.Fragment>
    );
  };

  getSuggestionValue = suggestion => {
    return suggestion;
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    const inputValue = deburr(value.trim()).toLowerCase();
    if (this.props.context === "JOBS") {
      this.props.api.getJobSearchSuggestions(inputValue).then(suggestion => {
        this.setState({
          suggestions: suggestion
        });
      });
    }
    if (this.props.context === "CANDIDATES") {
      this.props.api.getUserSearchSuggestions(inputValue).then(suggestion => {
        this.setState({
          suggestions: suggestion
        });
      });
    }
    if (this.props.context === "HIVE") {
      this.props.api.getUserSearchSuggestions(inputValue).then(suggestion => {
        this.setState({
          suggestions: suggestion
        });
      });
    }
  };

  handleFilterIconClick = () => {
    if (this.props.context === "JOBS") {
      this.setState(state => ({
        showJobsFilter: !state.showJobsFilter,
        jobsFilterSet: true
      }));
    }
    if (this.props.context === "CANDIDATES") {
      this.setState(state => ({
        showCandidatesFilter: !state.showCandidatesFilter
      }));
    }
    if (this.props.context === "HIVE") {
      this.setState(state => ({
        showHiveFilter: !state.showHiveFilter
      }));
    }
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  handleChange = name => (event, { newValue, method }) => {
    this.setState({
      [name]: newValue
    });
  };

  handleSuggestionSelected = (event, { suggestionValue }) => {
    this.setState({
      value: suggestionValue
    });
    if (this.props.context === "JOBS") {
      this.props.searchJobs(suggestionValue);
    }
    if (this.props.context === "CANDIDATES") {
      this.props.searchCandidates(suggestionValue);
    }
    if (this.props.context === "HIVE") {
      this.props.searchCandidates(suggestionValue);
    }
  };

  searchJobs = event => {
    if (event.key === "Enter") {
      this.props.searchJobs(this.state.query, null);
    }
  };

  filterJobs = jobsFilter => {
    //console.log(jobsFilter);
    this.setState(
      {
        jobsFilter: jobsFilter,
        showJobsFilter: false,
        jobsFilterSet: jobsFilter.filterSet
      },
      () => {
        this.props.searchJobs(this.state.query, this.state.jobsFilterSet === true ? this.state.jobsFilter : null);
      }
    );
  };

  filterCandidates = candidatesFilter => {
    //console.log(candidatesFilter);
    this.setState(
      {
        candidatesFilter: candidatesFilter,
        showCandidatesFilter: false,
        candidatesFilterSet: candidatesFilter.filterSet
      },
      () => {
        this.props.searchCandidates(
          this.state.query,
          this.state.candidatesFilterSet === true ? this.state.candidatesFilter : null
        );
      }
    );
  };

  filterHive = hiveFilter => {
    this.setState(
      {
        hiveFilter: hiveFilter,
        showHiveFilter: false,
        hiveFilterSet: hiveFilter.filterSet
      },
      () => {
        this.props.searchHive(this.state.query, this.state.hiveFilterSet === true ? this.state.hiveFilter : null);
      }
    );
  };

  searchCandidates = event => {
    if (event.key === "Enter") {
      this.props.searchCandidates(this.state.query, null);
    }
  };

  searchHive = event => {
    if (event.key === "Enter") {
      this.props.searchHive(this.state.query, null);
    }
  };

  render() {
    const { classes, context } = this.props;
    const autosuggestProps = {
      renderInputComponent: this.renderInputComponent,
      suggestions: this.state.suggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      onSuggestionSelected: this.handleSuggestionSelected,
      getSuggestionValue: this.getSuggestionValue,
      renderSuggestion,
      focusInputOnSuggestionClick: false
    };
    return (
      <Paper className={classes.root} elevation={5}>
        <Autosuggest
          {...autosuggestProps}
          inputProps={{
            classes,
            placeholder:
              context === "JOBS"
                ? "Type Jobs Query & Press Enter..."
                : context === "CANDIDATES"
                ? "Type Profile Query & Press Enter"
                : context === "HIVE"
                ? "Search Hive"
                : "",
            value: this.state.query,
            onChange: this.handleChange("query"),
            onKeyPress:
              context === "JOBS"
                ? this.searchJobs
                : context === "CANDIDATES"
                ? this.searchCandidates
                : context === "HIVE"
                ? this.searchHive
                : this.searchJobs,
            onClick:
              context === "JOBS"
                ? this.searchJobs
                : context === "CANDIDATES"
                ? this.searchCandidates
                : context === "HIVE"
                ? this.searchHive
                : this.searchJobs,
            inputRef: node => {
              this.popperNode = node;
            },
            InputLabelProps: {
              shrink: true
            }
          }}
          theme={{
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion
          }}
          renderSuggestionsContainer={options => (
            <Popper anchorEl={this.popperNode} open={Boolean(options.children)}>
              <Paper
                square
                elevation={10}
                {...options.containerProps}
                style={{
                  width: this.popperNode ? this.popperNode.clientWidth : null
                }}>
                {options.children}
              </Paper>
            </Popper>
          )}
        />
      </Paper>
    );
  }
}

IntegrationAutosuggest.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withApi(IntegrationAutosuggest));
