import React, { Component } from "react";
import {
  Paper,
  CssBaseline,
  FormControl,
  TextField,
  withStyles,
  Button,
  Checkbox,
  FormControlLabel
} from "@material-ui/core";
import { RemoveCircle } from "@material-ui/icons";

const styles = theme => ({
  main: {
    display: "flex",
    width: "100%",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(8),
    marginBottom: "10px"
  },
  paper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
  },
  itemText: {
    color: theme.palette.primary.main
  },
  itemTextSelected: {
    color: "#fff"
  },
  checkBoxLabel: {
    fontSize: "16px"
  },
  remove: {}
});

class Address extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.state
    };
  }

  onChange = event => {
    this.setState(
      {
        [event.target.name]: event.target.value
      },
      () => {
        this.props.handleState(this.state, this.props.index);
      }
    );
  };

  onRemoveMe = () => {
    if (this.state.key !== "") {
      this.setState(
        {
          active: false
        },
        () => {
          this.props.handleState(this.state, this.props.index);
        }
      );
    } else {
      this.props.onRemoveMe(this.props.index);
    }
  };

  handleCheckChange = name => event => {
    this.setState(
      {
        [name]: event.target.checked
      },
      () => {
        this.props.handleState(this.state, this.props.index);
      }
    );
  };

  validate = event => {
    if (
      "address1" === event.target.name ||
      "city" === event.target.name ||
      "state" === event.target.name ||
      "zip" === event.target.name ||
      "country" === event.target.name
    ) {
      if (this.state.address1 !== "") {
        this.setState({
          address1RequiredMessage: ""
        });
      } else {
        this.setState({
          address1RequiredMessage: "Address1 Required"
        });
      }

      if (this.state.city !== "") {
        this.setState({
          cityRequiredMessage: ""
        });
      } else {
        this.setState({
          cityRequiredMessage: "City Required"
        });
      }

      if (this.state.state !== "") {
        this.setState({
          stateRequiredMessage: ""
        });
      } else {
        this.setState({
          stateRequiredMessage: "State Required"
        });
      }
      if (this.state.zip !== "") {
        this.setState({
          zipRequiredMessage: ""
        });
      } else {
        this.setState({
          zipRequiredMessage: "Zip Code Required"
        });
      }
      if (this.state.country !== "") {
        this.setState({
          countryRequiredMessage: ""
        });
      } else {
        this.setState({
          countryRequiredMessage: "Country Required"
        });
      }
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <main className={classes.main}>
          <CssBaseline />
          <Paper className={classes.paper}>
            {this.props.showRemoveMe === true ? (
              <div>
                <Button className={classes.remove} color="primary" onClick={this.onRemoveMe}>
                  <RemoveCircle />
                  &nbsp; Remove
                </Button>
              </div>
            ) : null}
            <form>
              <FormControl margin="normal" required fullWidth>
                <TextField
                  required
                  label="Address1"
                  placeholder="Address1"
                  name="address1"
                  value={this.state.address1}
                  onChange={this.onChange}
                  error={
                    this.state.address1RequiredMessage === "" || this.state.address1RequiredMessage === undefined
                      ? false
                      : true
                  }
                  helperText={this.state.address1RequiredMessage}
                  onKeyDown={this.validate}
                  onKeyUp={this.validate}
                />
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <TextField
                  required
                  label="Address2"
                  placeholder="Address2"
                  name="address2"
                  value={this.state.address2}
                  onChange={this.onChange}
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <TextField
                  required
                  label="City"
                  placeholder="City"
                  name="city"
                  value={this.state.city}
                  onChange={this.onChange}
                  error={
                    this.state.cityRequiredMessage === "" || this.state.cityRequiredMessage === undefined ? false : true
                  }
                  helperText={this.state.cityRequiredMessage}
                  onKeyDown={this.validate}
                  onKeyUp={this.validate}
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <TextField
                  required
                  label="State"
                  placeholder="State"
                  name="state"
                  value={this.state.state}
                  onChange={this.onChange}
                  error={
                    this.state.stateRequiredMessage === "" || this.state.stateRequiredMessage === undefined
                      ? false
                      : true
                  }
                  helperText={this.state.stateRequiredMessage}
                  onKeyDown={this.validate}
                  onKeyUp={this.validate}
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <TextField
                  required
                  label="Zip"
                  placeholder="Zip"
                  name="zip"
                  value={this.state.zip}
                  onChange={this.onChange}
                  error={
                    this.state.zipRequiredMessage === "" || this.state.zipRequiredMessage === undefined ? false : true
                  }
                  onKeyDown={this.validate}
                  onKeyUp={this.validate}
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <TextField
                  required
                  label="Country"
                  placeholder="Country"
                  name="country"
                  value={this.state.country}
                  onChange={this.onChange}
                  error={
                    this.state.countryRequiredMessage === "" || this.state.countryRequiredMessage === undefined
                      ? false
                      : true
                  }
                  onKeyDown={this.validate}
                  onKeyUp={this.validate}
                />
              </FormControl>
              <FormControl>
                <FormControlLabel
                  labelPlacement="start"
                  classes={{
                    label: classes.checkBoxLabel
                  }}
                  control={
                    <Checkbox
                      checked={this.state.primary}
                      onChange={this.handleCheckChange("primary")}
                      value="primary"
                    />
                  }
                  label="Primary Address?"
                />
              </FormControl>
            </form>
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(Address);
