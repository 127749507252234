import React from "react";
import { withAuthorization } from "../../Session";
import JobCard from "../Jobs/jobCard";
import CandidateCard from "../Candidates/candidateCard";
import { withStyles, Grid, CssBaseline, Button, Paper } from "@material-ui/core";
import grey from "@material-ui/core/colors/grey";
import ProfileCard from "./profileCard";
import ProfileChart from "./profileChart";
import LoadingIndicator from "../../../common/loading";

const styles = theme => ({
  scroll: {
    height: "50vh",
    overflowY: "auto",
    overflowX: "hidden",
    paddingLeft: "2%",
    paddingBottom: "6%"
  }
});

class Dashboard extends React.Component {
  componentDidMount() {
    this.refs.iScroll.addEventListener("scroll", () => {
      if (this.refs.iScroll.scrollTop + this.refs.iScroll.clientHeight + 1 >= this.refs.iScroll.scrollHeight) {
        if (!this.props.jobsLoading && this.props.jobsResultSet.length) {
          if (parseInt(this.props.showingCount, 10) < parseInt(this.props.totalCount, 10)) {
            this.props.searchJobs("BHired_CURRENT_QUERY");
          }
        }
      }
    });
  }

  componentWillUnmount() {
    this.refs.iScroll.removeEventListener("scroll", this.onScroll, false);
  }

  render() {
    const { classes, jobsResultSet, candidatesResultSet, context } = this.props;
    return (
      <React.Fragment>
        <CssBaseline />
        <Grid container>
          <Grid item xs={6}>
            <ProfileCard profileCompletion={this.props.profileCompletion} />
          </Grid>
          <Grid item xs={6}>
            <ProfileChart />
          </Grid>
          <Grid item xs={12}>
            <Paper
              style={{
                width: "100%",
                backgroundColor: grey[50],
                marginTop: "2%"
              }}>
              <Button
                disabled
                style={{
                  color: grey[700]
                }}
                size="large">
                {context === "JOBS" ? "Job Recommendations" : "BROWSE TOP CANDIDATES"}
              </Button>
              <main ref="iScroll" className={classes.scroll}>
                {context === "JOBS" ? (
                  <React.Fragment>
                    <LoadingIndicator display={this.props.jobsLoading} />
                    <Grid container spacing={2}>
                      {jobsResultSet.map((item, index) => (
                        <Grid key={index} item xs={6}>
                          <JobCard job={item.bhiredSearchObject} social={item.bhiredObject} />
                        </Grid>
                      ))}
                    </Grid>
                  </React.Fragment>
                ) : null}
                {context === "CANDIDATES" ? (
                  <React.Fragment>
                    <LoadingIndicator display={this.props.candidatesLoading} />
                    <Grid container spacing={2}>
                      {candidatesResultSet.map((item, index) => (
                        <Grid key={index} item xs={6}>
                          <CandidateCard candidate={item.bhiredSearchObject} social={item.bhiredObject} />
                        </Grid>
                      ))}
                    </Grid>
                  </React.Fragment>
                ) : null}
              </main>
            </Paper>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const condition = authUser => !!authUser;
export default withAuthorization(condition)(withStyles(styles)(Dashboard));
