import React from "react";
import PropTypes from "prop-types";
import { withStyles, Grid, Drawer, Typography, IconButton, ClickAwayListener } from "@material-ui/core";
import { withApi } from "../../Api";
import NotificationCard from "./notificationCard";
import CloseIcon from "@material-ui/icons/ArrowRight";

const styles = theme => ({
  list: { width: window.innerWidth / 3, marginBottom: "25%" }
});

class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = { notificationsResultSet: [] };
  }

  componentDidMount() {
    this.componentLoaded = true;
    this.registerNotificationsListener();
  }

  registerNotificationsListener = () => {
    var notificationsRef = this.props.api.db
      .collection("notifications/" + this.props.api.auth.currentUser.email + "/all")
      .orderBy("timestamp", "desc")
      .limit(25);
    let notificationsResultSet = [...this.state.notificationsResultSet];
    notificationsRef.onSnapshot(querySnapshot => {
      if (this.componentLoaded === true) {
        querySnapshot.forEach(doc => {
          let notf = doc.data();
          notf.id = doc.id;
          let index = notificationsResultSet.findIndex(e => e.id === notf.id);
          //console.log(index);
          if (index !== -1) {
            notificationsResultSet[index] = notf;
          } else {
            notificationsResultSet.push(notf);
          }
        });
        this.setState({ notificationsResultSet });
      }
    });
  };

  componentWillUnmount() {
    this.componentLoaded = false;
  }

  handleClickClose = () => {
    this.props.handleNotificationsClose();
  };

  handleClickDelete = id => {
    var notificationsRef = this.props.api.db.collection(
      "notifications/" + this.props.api.auth.currentUser.email + "/all"
    );
    notificationsRef
      .doc(id)
      .delete()
      .then(() => {
        this.setState(prevState => ({
          notificationsResultSet: prevState.notificationsResultSet.filter(el => el.id !== id)
        }));
      })
      .catch(function(error) {
        console.error("Error removing document: ", error);
      });
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Drawer
          anchor="right"
          open={this.props.open}
          transitionDuration={{
            enter: 1000,
            exit: 1000
          }}>
          <ClickAwayListener onClickAway={this.handleClickClose}>
            <main className={classes.list}>
              <Grid container alignItems="center" justify="center">
                <Grid item xs={6}>
                  <Typography color="primary" variant="h6" style={{ paddingLeft: "15px", paddingTop: "10px" }}>
                    Notifications
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <IconButton
                    aria-label="Close"
                    color="primary"
                    onClick={this.handleClickClose}
                    style={{ float: "right" }}>
                    <CloseIcon fontSize="large" />
                  </IconButton>
                </Grid>
              </Grid>
              {this.state.notificationsResultSet !== undefined ? (
                <Grid container alignItems="center" justify="center" spacing={1}>
                  {this.state.notificationsResultSet.map((item, index) => (
                    <Grid key={index} item xs={12}>
                      <NotificationCard
                        id={item.id}
                        artifactKey={item.artifactKey}
                        handleClickDelete={this.handleClickDelete}
                        title={item.title}
                        description={item.description}
                        actions={item.actions}
                        isRead={item.isRead}
                        actionTaken={item.actionTaken}
                        actionTakenDate={item.actionTakenDate.toDate()}
                        actionTakenTitle={item.actionTakenTitle}
                        sentOn={item.timestamp.toDate()}
                        type={item.type}
                        handleClickClose={() => this.props.handleNotificationsClose()}
                      />
                    </Grid>
                  ))}
                </Grid>
              ) : null}
              {this.state.notificationsResultSet.length === 0 ? (
                <Grid container style={{ padding: "50px" }} alignItems="center" justify="center" spacing={3}>
                  <Typography
                    style={{
                      padding: "50px",
                      color: "grey"
                    }}
                    variant="h5">
                    No Notifications Found
                  </Typography>
                </Grid>
              ) : null}
            </main>
          </ClickAwayListener>
        </Drawer>
      </React.Fragment>
    );
  }
}

Notifications.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withApi(Notifications));
