import React from "react";
import Layout from "./layout";
import Profile from "../Home/Profile";
import Jobs from "../Home/Jobs";
import Candidates from "../Home/Candidates";
import Wallet from "../Home/Wallet";
import Hive from "../Home/Hive";
import Dashboard from "../Home/Dashboard";
import Assessments from "../Home/Assessments";
import { Route, Switch } from "react-router-dom";
import { withApi } from "../Api";
import LoadingIndicator from "../../common/loading";
import { withAuthorization } from "../Session";
import * as Routes from "../../common/routes";
import ChatWindow from "../Home/Hive/chat";

const updateJobsResult = (result, showingCount) => prevState => ({
  jobsLoading: false,
  jobsResultSet: [...prevState.jobsResultSet, ...result.resultSet],
  totalCount: result.totalCount,
  showingCount: parseInt(showingCount, 10) + parseInt(result.returnedCount, 10),
  cursor: result.cursor
});

const updateCandidatesResult = (result, showingCount) => prevState => ({
  candidatesLoading: false,
  candidatesResultSet: [...prevState.candidatesResultSet, ...result.resultSet],
  totalCount: result.totalCount,
  showingCount: parseInt(showingCount, 10) + parseInt(result.returnedCount, 10),
  cursor: result.cursor
});

const updateHiveResult = (result, showingCount) => prevState => ({
  hiveLoading: false,
  hiveResultSet: [...prevState.hiveResultSet, ...result.resultSet],
  totalCount: result.totalCount,
  showingCount: parseInt(showingCount, 10) + parseInt(result.returnedCount, 10),
  cursor: result.cursor
});

const setJobsResult = result => () => ({
  jobsLoading: false,
  jobsResultSet: result.resultSet,
  totalCount: result.totalCount,
  showingCount: result.returnedCount,
  cursor: result.cursor
});

const setCandidatesResult = result => () => ({
  candidatesLoading: false,
  candidatesResultSet: result.resultSet,
  totalCount: result.totalCount,
  showingCount: result.returnedCount,
  cursor: result.cursor
});

const setHiveResult = result => () => ({
  hiveLoading: false,
  hiveResultSet: result.resultSet,
  totalCount: result.totalCount,
  showingCount: result.returnedCount,
  cursor: result.cursor
});

class Home extends React.Component {
  authUserLoaded = false;
  constructor(props) {
    super(props);
    this.state = {
      userLoaded: true,
      jobsLoading: false,
      candidatesLoading: false,
      role: "",
      q: "",
      jobsResultSet: [],
      jobsFilter: null,
      candidatesResultSet: [],
      hiveResultSet: [],
      hiveLoading: false,
      candidatesFilter: null,
      totalCount: 0,
      showingCount: 0,
      cursor: "",
      profileCompletion: 0,
      isChatOpen: false,
      messageList: [],
      title: "",
      chatId: "",
      emailId: "",
      otherGuyKey: "",
      messagesRef: null
    };
  }

  onMessageWasSent = message => {
    var ref = this.state.messagesRef.collection("messages");
    ref.add({
      belongToChatId: this.state.chatId,
      chatGroupKey: this.state.chatId,
      createdDate: new Date(),
      message: message.data.text,
      senderAvatar: "",
      senderKey: this.props.api.bhiredUser.key,
      senderUserName: this.props.api.bhiredUser.firstName + " " + this.props.api.bhiredUser.lastName
    });
  };

  onFilesSelected = fileList => {
    const objectURL = window.URL.createObjectURL(fileList[0]);
    this.setState({
      messageList: [
        ...this.state.messageList,
        {
          type: "file",
          author: "me",
          data: {
            url: objectURL,
            fileName: fileList[0].name
          }
        }
      ]
    });
  };

  handleChatCloseClick = () => {
    this.setState({
      isChatOpen: false
    });
  };

  startChat = (title, emailId, chatId, messagesRef, otherGuyKey, mArray) => {
    this.setState(
      {
        newMessagesCount: 0,
        title: title,
        chatId: chatId,
        emailId: emailId,
        messageList: mArray,
        messagesRef: messagesRef,
        otherGuyKey: otherGuyKey
      },
      () => {
        messagesRef
          .collection("messages").onSnapshot(snapshot => {
          snapshot.docChanges().forEach(change => {
            if (change.type === "added") {
              const exists = this.state.messageList.some(v => v.key === change.doc.id);
              //console.log(change.doc.data().message);
              if (!exists) {
                this.setState({
                  messageList: [
                    ...this.state.messageList,
                    {
                      senderKey: change.doc.data().senderKey,
                      author: change.doc.data().senderUserName,
                      type: "text",
                      data: { text: change.doc.data().message },
                      timestamp: change.doc.data().createdDate,
                      key: change.doc.id
                    }
                  ]
                });
              }
            }
          });
          this.setState({
            isChatOpen: true
          });
        });
      }
    );
  };

  searchJobs = (q, jobsFilter) => {
    const newSearch = q !== "BHired_CURRENT_QUERY";
    if (newSearch) {
      this.setState({
        q: q,
        jobsResultSet: [],
        totalCount: 0,
        showingCount: 0,
        cursor: "",
        jobsFilter: jobsFilter
      });
    }
    this.setState({
      jobsLoading: true
    });
    this.props.api
      .searchJobs({
        query: newSearch ? q : this.state.q,
        cursor: newSearch ? "" : this.state.cursor,
        pageSize: 5,
        mostRelevant: jobsFilter != null ? jobsFilter.mostRelevant : false,
        mostRecent: jobsFilter != null ? jobsFilter.mostRecent : false,
        location: jobsFilter != null ? jobsFilter.location : null,
        miles: jobsFilter != null ? jobsFilter.miles : null,
        filterBySalaryRange: jobsFilter != null ? jobsFilter.filterBySalaryRange : null,
        filterByIndustry: jobsFilter != null ? jobsFilter.filterByIndustry : null
      })
      .then(result => {
        this.state.showingCount === 0
          ? this.setState(setJobsResult(result))
          : this.setState(updateJobsResult(result, this.state.showingCount));
      });
  };

  searchRecruiterJobs = q => {
    //console.log("RecruiterJobs Query: " + q);
    const newSearch = q !== "BHired_CURRENT_QUERY";
    if (newSearch) {
      this.setState({
        q: q,
        jobsResultSet: [],
        totalCount: 0,
        showingCount: 0,
        cursor: ""
      });
    }
    this.setState({
      jobsLoading: true
    });
    this.props.api
      .recruiterJobs({
        query: newSearch ? q : this.state.q,
        cursor: newSearch ? "" : this.state.cursor,
        pageSize: 5
      })
      .then(result => {
        this.setState(setJobsResult(result));
      });
  };

  searchCandidates = (q, candidatesFilter) => {
    //console.log("Candidates Query: " + q);
    //console.log("Candidates Filter: " + candidatesFilter);
    const newSearch = q !== "BHired_CURRENT_QUERY";
    if (newSearch) {
      this.setState({
        q: q,
        candidatesResultSet: [],
        totalCount: 0,
        showingCount: 0,
        cursor: "",
        candidatesFilter: candidatesFilter
      });
    }
    this.setState({
      candidatesLoading: true
    });
    this.props.api
      .searchCandidates({
        query: newSearch ? q : this.state.q,
        cursor: newSearch ? "" : this.state.cursor,
        pageSize: 5,
        mostRelevant: candidatesFilter != null ? candidatesFilter.mostRelevant : false,
        candidateRating: candidatesFilter != null ? candidatesFilter.candidateRating : false,
        location: candidatesFilter != null ? candidatesFilter.location : null,
        miles: candidatesFilter != null ? candidatesFilter.miles : null,
        numberOfYearsOfExperience: candidatesFilter != null ? candidatesFilter.numberOfYearsOfExperience : null,
        rating: candidatesFilter != null ? candidatesFilter.rating : null
      })
      .then(result => {
        this.state.showingCount === 0
          ? this.setState(setCandidatesResult(result))
          : this.setState(updateCandidatesResult(result, this.state.showingCount));
      });
  };

  searchHive = (q, hiveFilter) => {
    //console.log("Hive Query: " + q);
    const newSearch = q !== "BHired_CURRENT_QUERY";
    if (newSearch) {
      this.setState({
        q: q,
        hiveResultSet: [],
        totalCount: 0,
        showingCount: 0,
        cursor: ""
      });
    }
    this.setState({
      hiveLoading: true
    });
    this.props.api
      .searchHive({
        query: newSearch ? q : this.state.q,
        cursor: newSearch ? "" : this.state.cursor,
        pageSize: 5,
        mostRelevant: hiveFilter != null ? hiveFilter.mostRelevant : false,
        candidateRating: hiveFilter != null ? hiveFilter.candidateRating : false,
        location: hiveFilter != null ? hiveFilter.location : null,
        miles: hiveFilter != null ? hiveFilter.miles : null,
        numberOfYearsOfExperience: hiveFilter != null ? hiveFilter.numberOfYearsOfExperience : null,
        rating: hiveFilter != null ? hiveFilter.rating : null
      })
      .then(result => {
        this.state.showingCount === 0
          ? this.setState(setHiveResult(result))
          : this.setState(updateHiveResult(result, this.state.showingCount));
      });
  };

  componentDidMount() {
    this.authUserLoaded = true;
    if (this.props.api.bhiredUser === null) {
      if (this.authUserLoaded === true) {
        this.setState({
          userLoaded: false
        });
      }
      this.props.api.getMe().then(isActive => {
        if (isActive !== true) {
          this.props.history.push(Routes.TERMS_AND_CONDITIONS);
        }
        if (this.authUserLoaded === true) {
          this.setState(
            {
              userLoaded: true,
              role: this.props.api.bhiredUser.roles[0],
              profileCompletion: this.props.api.bhiredUser.profileCompletion.percentageCompleted
            },
            () => {
              this.state.role === "ROLE_RECRUITER"
                ? this.searchCandidates(this.state.q, true)
                : this.searchJobs(this.state.q, true);
            }
          );
        }
      });
    } else {
      this.setState(
        {
          userLoaded: true,
          role: this.props.api.bhiredUser.roles[0]
        },
        () => {
          this.state.role === "ROLE_RECRUITER"
            ? this.searchCandidates(this.state.q, true)
            : this.searchJobs(this.state.q, true);
        }
      );
    }
  }

  componentWillUnmount() {
    this.authUserLoaded = false;
  }

  render() {
    return (
      <React.Fragment>
        <LoadingIndicator display={!this.state.userLoaded} />
        <ChatWindow
          messageList={this.state.messageList}
          onUserInputSubmit={this.onMessageWasSent}
          onFilesSelected={this.onFilesSelected}
          groupProfile={{ groupName: this.state.title, imageURL: "" }}
          isChatOpen={this.state.isChatOpen}
          onChatClose={this.handleChatCloseClick}
          showEmoji={true}
        />
        {this.state.userLoaded === true ? (
          <Layout
            role={this.state.role}
            context={this.state.role === "ROLE_RECRUITER" ? "CANDIDATES" : "JOBS"}
            searchJobs={this.searchJobs}
            searchCandidates={this.searchCandidates}
            searchHive={this.searchHive}
          >
            <Switch>
              <Route
                exact
                path={Routes.HOME}
                render={props => (
                  <Dashboard
                    {...props}
                    role={this.state.role}
                    context={this.state.role === "ROLE_RECRUITER" ? "CANDIDATES" : "JOBS"}
                    searchJobs={this.searchJobs}
                    profileCompletion={this.state.profileCompletion}
                    jobsResultSet={this.state.jobsResultSet}
                    searchCandidates={this.searchCandidates}
                    candidatesResultSet={this.state.candidatesResultSet}
                    showingCount={this.state.showingCount}
                    totalCount={this.state.totalCount}
                    jobsLoading={this.state.jobsLoading}
                    candidatesLoading={this.state.candidatesLoading}
                  />
                )}
              />
              <Route
                exact
                path={Routes.DASHBOARD}
                render={props => (
                  <Dashboard
                    {...props}
                    role={this.state.role}
                    context={this.state.role === "ROLE_RECRUITER" ? "CANDIDATES" : "JOBS"}
                    searchJobs={this.searchJobs}
                    jobsResultSet={this.state.jobsResultSet}
                    searchCandidates={this.searchCandidates}
                    candidatesResultSet={this.state.candidatesResultSet}
                    showingCount={this.state.showingCount}
                    totalCount={this.state.totalCount}
                    jobsLoading={this.state.jobsLoading}
                    candidatesLoading={this.state.candidatesLoading}
                  />
                )}
              />
              <Route exact path={Routes.PROFILE} render={props => <Profile {...props} />} />
              <Route
                exact
                path={Routes.JOBS}
                render={props => (
                  <Jobs
                    {...props}
                    role={this.state.role}
                    context={"JOBS"}
                    searchJobs={this.state.role === "ROLE_RECRUITER" ? this.searchRecruiterJobs : this.searchJobs}
                    jobsResultSet={this.state.jobsResultSet}
                    showingCount={this.state.showingCount}
                    totalCount={this.state.totalCount}
                    jobsLoading={this.state.jobsLoading}
                  />
                )}
              />
              <Route
                exact
                path={Routes.CANDIDATES}
                render={props => (
                  <Candidates
                    {...props}
                    role={this.state.role}
                    context={"CANDIDATES"}
                    searchCandidates={this.searchCandidates}
                    candidatesResultSet={this.state.candidatesResultSet}
                    showingCount={this.state.showingCount}
                    totalCount={this.state.totalCount}
                    candidatesLoading={this.state.candidatesLoading}
                  />
                )}
              />
              <Route exact path={Routes.WALLET} render={props => <Wallet {...props} />} />
              <Route
                exact
                path={Routes.HIVE}
                render={props => (
                  <Hive
                    {...props}
                    role={this.state.role}
                    context={"HIVE"}
                    searchHive={this.searchHive}
                    hiveResultSet={this.state.hiveResultSet}
                    showingCount={this.state.showingCount}
                    totalCount={this.state.totalCount}
                    hiveLoading={this.state.hiveLoading}
                    startChat={this.startChat}
                  />
                )}
              />
              <Route exact path={Routes.ASSESSMENTS} render={props => <Assessments {...props} />} />
            </Switch>
          </Layout>
        ) : null}
      </React.Fragment>
    );
  }
}

const condition = authUser => !!authUser;
export default withAuthorization(condition)(withApi(Home));
