import React, { Component } from "react";
import {
  Grid,
  Paper,
  Typography,
  Button,
  CssBaseline,
  FormControl,
  TextField,
  withStyles,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { Visibility, VisibilityOff, Email, Lock } from "@material-ui/icons";
import * as Routes from "../../common/routes";
import { ResetPasswordLink } from "./resetPassword";
import { withRouter } from "react-router-dom";
import { withApi } from "../Api";
import { compose } from "recompose";
import PropTypes from "prop-types";
import styled from "styled-components";
import LoadingIndicator from "../../common/loading";
import Images from "../../assets";
import { isValidEmail } from "../../common/helpers.js";

const styles = (theme) => ({
  main: {
    width: "auto",
    display: "block",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 425,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  paperSocial: {
    cursor: "pointer",
    height: "50px",
    width: "50px",
    borderRadius: "50%",
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  submit: {
    marginTop: theme.spacing(1),
  },
  error: {
    textAlign: "center",
    width: "100%",
    color: "red",
  },
  resetPassword: {
    textAlign: "right",
    width: "100%",
  },
});

const INITIAL_STATE = {
  email: "",
  password: "",
  error: "",
  showPassword: false,
  disabled: false,
  isLoading: false,
  emailNotVerified: false,
};

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
    };
  }

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSocialSignIn = (oAuthProvider) => {
    this.setState({
      isLoading: true,
    });
    this.props.api
      .doSocialSignIn(oAuthProvider)
      .then(() => {
        this.setState({
          disabled: true,
        });
        this.next();
      })
      .catch((error) => {
        var pendingCred = error.credential;
        if (error.code === "auth/account-exists-with-different-credential") {
          this.setState({
            disabled: true,
            isLoading: true,
          });
          var email = error.email;
          this.props.api.auth
            .fetchSignInMethodsForEmail(email)
            .then((methods) => {
              var existingProvider = methods[0];
              this.props.api.doSocialSignIn(existingProvider).then((result) => {
                result.user
                  .linkAndRetrieveDataWithCredential(pendingCred)
                  .then(() => {
                    this.next();
                  })
                  .catch((error) => {
                    console.log(error);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              });
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
  };

  handleSubmit = (event) => {
    const { email, password } = this.state;
    if (isValidEmail(email) === true) {
      this.setState({
        disabled: true,
        isLoading: true,
      });
      this.props.api
        .doSignInWithEmailAndPassword(email, password)
        .then(() => {
          if (this.props.api.auth.currentUser.emailVerified === true) {
            this.next();
          } else {
            this.setState({
              emailNotVerified: true,
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          switch (error.code) {
            case "auth/invalid-email":
              error.emailMessage = "Invalid Email Address";
              break;
            case "auth/user-not-found":
              error.emailMessage = "Unknown Email Address";
              break;
            case "auth/wrong-password":
              error.passwordMessage = "Invalid Password";
              this.setState({
                password: "",
              });
              break;
            default:
              error.emailMessage = error.code;
          }
          this.setState({
            error,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        emailNotVerified: true,
        isLoading: false,
      });
    }
    event.preventDefault();
  };

  next() {
    this.props.api.getMe().then((response) => {
      this.setState({
        isLoading: false,
      });
      if (response === false) {
        this.props.history.push(Routes.TERMS_AND_CONDITIONS);
      } else {
        this.props.history.push(Routes.HOME);
      }
    });
  }

  handleClickShowPassword = () => {
    this.setState((state) => ({
      showPassword: !state.showPassword,
    }));
  };

  onKeyDown = (event) => {
    this.setState({
      error: "",
      disabled: false,
      emailNotVerified: false,
    });
  };

  render() {
    const { classes } = this.props;
    const { disabled, password } = this.state;
    const StyledAdornment = styled(InputAdornment)`
      margin-right: 10px;
    }`;
    return (
      <React.Fragment>
        <LoadingIndicator display={this.state.isLoading} />
        <main className={classes.main}>
          <CssBaseline />
          <Paper className={classes.paper}>
            <form onSubmit={this.handleSubmit} onKeyDown={this.onKeyDown}>
              <FormControl margin="normal" required fullWidth>
                <TextField
                  required
                  placeholder="Email Address"
                  id="email"
                  name="email"
                  autoComplete="new-email"
                  onChange={this.handleInputChange}
                  error={(this.state.error.emailMessage == null ? false : true, this.state.emailNotVerified)}
                  helperText={
                    (this.state.error.emailMessage,
                    this.state.emailNotVerified ? "Email Address Not Valid/Verified" : "")
                  }
                  InputProps={{
                    startAdornment: (
                      <StyledAdornment>
                        <Email color="disabled" />
                      </StyledAdornment>
                    ),
                  }}
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <TextField
                  required
                  placeholder="Password"
                  id="password"
                  name="password"
                  value={password}
                  autoComplete="new-password"
                  type={this.state.showPassword ? "text" : "password"}
                  onChange={this.handleInputChange}
                  error={this.state.error.passwordMessage == null ? false : true}
                  helperText={this.state.error.passwordMessage}
                  InputProps={{
                    startAdornment: (
                      <StyledAdornment>
                        <Lock color="disabled" />
                      </StyledAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="Toggle password visibility" onClick={this.handleClickShowPassword}>
                          {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
              <div className={classes.resetPassword}>
                <ResetPasswordLink />
              </div>
              <Button
                disabled={disabled}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}>
                Sign in
              </Button>
            </form>
          </Paper>
          <Grid container justify="space-around">
            <Grid item>
              <div
                style={{
                  height: "1px",
                  width: "50px",
                  backgroundColor: "#e53935",
                  marginTop: "100%",
                }}
              />
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                color="primary"
                style={{
                  marginTop: "150%",
                }}>
                Or
              </Typography>
            </Grid>
            <Grid item>
              <div
                style={{
                  height: "1px",
                  width: "50px",
                  backgroundColor: "#e53935",
                  marginTop: "100%",
                }}
              />
            </Grid>
          </Grid>
          <Grid container justify="space-around">
            <Grid item>
              <div onClick={(e) => this.handleSocialSignIn("google.com")}>
                <Paper className={classes.paperSocial}>
                  <img src={Images.google} alt="google" />
                </Paper>
              </div>
            </Grid>
            <Grid item>
              <div onClick={(e) => this.handleSocialSignIn("facebook.com")}>
                <Paper className={classes.paperSocial}>
                  <img src={Images.facebook} alt="facebook" />
                </Paper>
              </div>
            </Grid>
            <Grid item>
              <div onClick={(e) => this.handleSocialSignIn("github.com")}>
                <Paper className={classes.paperSocial}>
                  <img src={Images.github} alt="github" />
                </Paper>
              </div>
            </Grid>
          </Grid>
        </main>
      </React.Fragment>
    );
  }
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withStyles(styles), withRouter, withApi)(SignIn);
