import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/App";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter as Router } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import Api, { ApiContext } from "./components/Api";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    type: "light",
    action: {
      selected: "#B9090B",
      active: "#B9090B",
      hover: red[200]
    },
    primary: {
      main: "#B9090B",
      light: red[100],
      dark: "#B9090B",
      contrastText: "#ffffff"
    },
    secondary: {
      main: "#B9090B",
      light: green[800],
      dark: green[800],
      contrastText: "#ffffff"
    },
    contrastThreshold: 3
  },
  overrides: {
    MUIDataTableBodyCell: {
      root: {}
    },
    MuiDrawer: {
      paperAnchorRight: {
        left: "auto",
        right: 0,
        top: 75,
        border: "2px solid #B9090B",
        borderRadius: "10px"
      }
    }
  }
});

ReactDOM.render(
  <Router>
    <MuiThemeProvider theme={theme}>
      <ApiContext.Provider value={new Api()}>
        <App />
      </ApiContext.Provider>
    </MuiThemeProvider>
  </Router>,
  document.getElementById("root")
);
serviceWorker.unregister();
