import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import Hive from "./hive";

const styles = theme => ({
  appBar: {
    width: "98%"
  }
});

class HiveHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0
    };
  }

  render() {
    return (
      <React.Fragment>
        <Hive
          searchHive={this.props.searchHive}
          hiveResultSet={this.props.hiveResultSet}
          showingCount={this.props.showingCount}
          totalCount={this.props.totalCount}
          hiveLoading={this.props.hiveLoading}
          startChat={this.props.startChat}
        />
      </React.Fragment>
    );
  }
}

HiveHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, {
  withTheme: true
})(HiveHeader);
