import React, { Component } from "react";
import TextMessage from "./textMessage";
import EmojiMessage from "./emojiMessage";
import FileMessage from "./fileMessage";
import chatIconUrl from "./../../../../../assets";
import { withApi } from "../../../../Api";

class Message extends Component {
  renderMessageOfType = type => {
    switch (type) {
      case "text":
        return <TextMessage {...this.props.message} />;
      case "emoji":
        return <EmojiMessage {...this.props.message} />;
      case "file":
        return <FileMessage {...this.props.message} />;
      default:
        console.error(`Attempting to load message with unsupported file type '${type}'`);
    }
  };

  render() {
    let contentClassList = [
      "sc-message--content",
      this.props.message.senderKey === this.props.api.bhiredUser.key ? "sent" : "received"
    ];
    return (
      <div className="sc-message">
        <div className={contentClassList.join(" ")}>
          <div
            className="sc-message--avatar"
            style={{
              backgroundImage: `url(${chatIconUrl})`
            }}
          />
          {this.renderMessageOfType(this.props.message.type)}
        </div>
      </div>
    );
  }
}

export default withApi(Message);
