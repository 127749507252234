import React from "react";
import { Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import Images from "../../assets";
import * as Routes from "../../common/routes";
import { withApi } from "../Api";
import { withAuthorization } from "../Session";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
class Terms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      scroll: "paper",
      acceptTerms: false,
      acceptGDPR: false
    };
  }

  handleDisagree = () => {
    this.persist();
    this.setState({
      open: false
    });
    this.props.history.push(Routes.AUTH_HEADER);
  };

  handleAgree = () => {
    this.setState({
      open: false
    });
    this.props.history.push(Routes.ROLE_SELECTOR);
  };

  persist() {
    delete this.state.open;
    delete this.state.scroll;
    this.props.api.userOnboard(this.state).then(() => {
      this.props.api.doSignOut();
    });
  }

  render() {
    return (
      <Dialog
        open={this.state.open}
        onClose={this.handleClose}
        scroll={this.state.scroll}
        aria-labelledby="bhiredinc"
        fullWidth={true}
        BackdropProps={{
          style: {
            backgroundColor: "#ffffff"
          }
        }}
      >
        <DialogTitle id="bhiredinc" align="center">
          <div>
            <img
              style={{
                width: "25%",
                height: "auto"
              }}
              src={Images.logo}
              alt="bhiredinc"
            />
          </div>
          Terms and Conditions
        </DialogTitle>
        <DialogContent>
          <Typography variant="h6" gutterBottom>
            Introduction
          </Typography>
          <Typography variant="body1" gutterBottom>
            These Website Standard Terms and Conditions written on this page shall manage your use of our website, bhiredinc
            accessible at bhiredinc.
          </Typography>
          <Typography variant="body1" gutterBottom>
            These Terms will be applied fully and affect to your use of this Website. By using this Website, you agree to
            accept all terms and conditions written in here. You must not use this Website if you disagree with any of these
            Website Standard Terms and Conditions.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Minors or people below 18 years old are not allowed to use this Website.
          </Typography>
          <Typography variant="h6" gutterBottom>
            Intellectual Property Rights
          </Typography>
          <Typography variant="body1" gutterBottom>
            Other than the content you own, under these Terms, Bhired and/or its licensors own all the intellectual property
            rights and materials contained in this Website.
          </Typography>
          <Typography variant="body1" gutterBottom>
            You are granted limited license only for purposes of viewing the material contained on this Website.
          </Typography>
          <Typography variant="h6" gutterBottom>
            Restrictions
          </Typography>
          <Typography variant="body1">You are specifically restricted from all of the following:</Typography>
          <ul>
            <li> Publishing any Website material in any other media. </li>
            <li> Selling, sublicensing and/or otherwise commercializing any Website material. </li>
            <li> Publicly performing and/or showing any Website material. </li>
            <li> Using this Website in any way that is or may be damaging to this Website. </li>
            <li> Using this Website in any way that impacts user access to this Website. </li>
            <li>
              Using this Website contrary to applicable laws and regulations, or in any way may cause harm to the Website, or
              to any person or business entity.
            </li>
            <li>
              Engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this
              Website.
            </li>
            <li>Using this Website to engage in any advertising or marketing. </li>
          </ul>
          <Typography variant="body1" gutterBottom>
            Certain areas of this Website are restricted from being access by you and Bhired may further restrict access by
            you to any areas of this Website, at any time, in absolute discretion. Any user ID and password you may have for
            this Website are confidential and you must maintain confidentiality as well.
          </Typography>
          <Typography variant="h6" gutterBottom>
            Your Content
          </Typography>
          <Typography variant="body1" gutterBottom>
            In these Website Standard Terms and Conditions, "Your Content" shall mean any audio, video, text, images or other
            material you choose to display on this Website. By displaying your content, you grant Bhired a non-exclusive,
            worldwide irrevocable, sub licensable license to use, reproduce, adapt, publish, translate and distribute it in
            any and all media.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Your Content must be your own and must not be invading any third-party’s rights. Bhired reserves the right to
            remove any of your content from this Website at any time without notice.
          </Typography>
          <Typography variant="h6" gutterBottom>
            No warranties
          </Typography>
          <Typography variant="body1" gutterBottom>
            This Website is provided "as is," with all faults, and Bhired express no representations or warranties, of any
            kind related to this Website or the materials contained on this Website. Also, nothing contained on this Website
            shall be interpreted as advising you.
          </Typography>
          <Typography variant="h6" gutterBottom>
            Limitation of liability
          </Typography>
          <Typography variant="body1" gutterBottom>
            In no event shall Bhired, nor any of its officers, directors and employees, shall be held liable for anything
            arising out of or in any way connected with your use of this Website whether such liability is under contract.
            Bhired, including its officers, directors and employees shall not be held liable for any indirect, consequential
            or special liability arising out of or in any way related to your use of this Website.
          </Typography>
          <Typography variant="h6" gutterBottom>
            Indemnification
          </Typography>
          <Typography variant="body1" gutterBottom>
            You hereby indemnify to the fullest extent Bhired from and against any and/or all liabilities, costs, demands,
            causes of action, damages and expenses arising in any way related to your breach of any of the provisions of
            these Terms.
          </Typography>
          <Typography variant="h6" gutterBottom>
            Severability
          </Typography>
          <Typography variant="body1" gutterBottom>
            If any provision of these Terms is found to be invalid under any applicable law, such provisions shall be deleted
            without affecting the remaining provisions herein.
          </Typography>
          <Typography variant="h6" gutterBottom>
            Variation of Terms
          </Typography>
          <Typography variant="body1" gutterBottom>
            Bhired is permitted to revise these Terms at any time as it sees fit, and by using this Website you are expected
            to review these Terms on a regular basis.
          </Typography>
          <Typography variant="h6" gutterBottom>
            Assignment
          </Typography>
          <Typography variant="body1" gutterBottom>
            The Bhired is allowed to assign, transfer, and subcontract its rights and/or obligations under these Terms
            without any notification.However, you are not allowed to assign, transfer, or subcontract any of your rights
            and/or obligations under these Terms.
          </Typography>
          <Typography variant="h6" gutterBottom>
            Entire Agreement
          </Typography>
          <Typography variant="body1" gutterBottom>
            These Terms constitute the entire agreement between Bhired and you in relation to your use of this Website, and
            supersede all prior agreements and understandings.
          </Typography>
          <Typography variant="h6" gutterBottom>
            Governing Law & Jurisdiction
          </Typography>
          <Typography variant="body1" gutterBottom>
            These Terms will be governed by and interpreted in accordance with the laws of the State of California, and you
            submit to the non-exclusive jurisdiction of the state and federal courts located in us for the resolution of any
            disputes.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleDisagree} color="primary">
            Disagree
          </Button>
          <Button onClick={this.handleAgree} color="primary">
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const condition = authUser => !!authUser;
export default compose(
  withAuthorization(condition),
  withRouter,
  withApi
)(Terms);
