import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import {
  CardHeader,
  CardContent,
  CardActions,
  Card,
  withStyles,
  Collapse,
  Avatar,
  IconButton,
  Typography,
  Button,
  CssBaseline,
  Grid,
  Chip
} from "@material-ui/core";
import red from "@material-ui/core/colors/red";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Location from "@material-ui/icons/LocationOn";
import MoreVertIcon from "@material-ui/icons/Edit";
import { getAvatarColor } from "../../../common/helpers";
import { withApi } from "../../Api";
import EditJob from "../Jobs/editJob";
import LoadingIndicator from "../../../common/loading";
import {
  FacebookShareButton,
  LinkedinShareButton,
  FacebookIcon,
  LinkedinIcon,
  EmailIcon,
  EmailShareButton
} from "react-share";
import moment from "moment";

const styles = theme => ({
  card: {
    width: "98%",
    transitionDuration: "0.3s"
  },
  media: {},
  actions: {
    display: "flex"
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  avatar: {
    backgroundColor: red[500]
  },
  status: {
    float: "right",
    cursor: "none",
    marginLeft: "10px"
  },
  type: {
    cursor: "none",
    float: "left"
  },
  location: {
    cursor: "none",
    float: "left",
    padding: "0px"
  },
  right: {
    marginLeft: "auto"
  }
});

class JobCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      editJobOpen: false,
      favorite: this.props.social !== undefined && this.props.social.favourite === "FAVOURITE" ? true : false,
      loading: false,
      apply: this.props.social !== undefined ? this.props.social.apply : null
    };
  }

  handleExpandClick = () => {
    this.setState(state => ({
      expanded: !state.expanded
    }));
  };

  capitalize = str => {
    return str.charAt(0).toUpperCase();
  };

  handleClickOpen = () => {
    this.setState({
      editJobOpen: true
    });
    this.props.hidePostJobButton(true);
  };

  handleClickApply = () => {
    this.setState({
      loading: true
    });
    this.props.api.applyForJob(this.props.job.key).then(response => {
      this.setState({
        apply: "APPLY",
        loading: false
      });
    });
  };

  handleClickClose = () => {
    this.setState({
      editJobOpen: false
    });
    this.props.hidePostJobButton(false);
    this.props.searchJobs("");
  };

  handleClickPublish = () => {
    this.setState({
      editJobOpen: false
    });
    this.props.hidePostJobButton(false);
    this.props.searchJobs("");
  };

  favorite = () => {
    this.setState({
      loading: true
    });
    if (this.state.favorite === true) {
      this.props.api.unFavoriteJob(this.props.job.key).then(response => {
        this.setState(state => ({
          favorite: !state.favorite,
          loading: false
        }));
      });
    } else {
      this.props.api.favoriteJob(this.props.job.key).then(response => {
        this.setState(state => ({
          favorite: !state.favorite,
          loading: false
        }));
      });
    }
  };

  render() {
    const { classes, job } = this.props;
    const rColor = job !== undefined ? getAvatarColor(job.company) : "";
    const title = job !== undefined ? job.title + " @ " + job.company + " " : "";
    const eType = job !== undefined ? (job.employmentType !== "" ? job.employmentType : "Full Time") : "";
    const exp = job !== undefined ? parseInt(job.experienceInMonths) / 12 : 0;
    const createdBy = job !== undefined ? job.createdBy : "";
    const startDate = moment(job.startDate).format("MM/DD/YYYY");
    return (
      <React.Fragment>
        <LoadingIndicator display={this.state.loading} />
        {this.props.api.bhiredUser.email === createdBy ? (
          <EditJob
            open={this.state.editJobOpen}
            job={this.props.job}
            handleClickClose={this.handleClickClose}
            handleClickPublish={this.handleClickPublish}
          />
        ) : null}
        <CssBaseline />
        {job !== undefined ? (
          <Card className={classes.card}>
            <CardHeader
              avatar={
                <Avatar
                  aria-label="Avatar"
                  className={classes.avatar}
                  style={{
                    backgroundColor: rColor,
                    padding: "0px"
                  }}>
                  {this.capitalize(job.company)}
                </Avatar>
              }
              action={
                this.props.api.bhiredUser.email === createdBy ? (
                  <IconButton onClick={this.handleClickOpen}>
                    <MoreVertIcon />
                  </IconButton>
                ) : (
                  <Button
                    style={this.state.apply !== "APPLY" ? { marginTop: "10px" } : { marginTop: "10px", cursor: "none" }}
                    onClick={this.state.apply !== "APPLY" ? this.handleClickApply : null}
                    title={
                      this.props.social.apply === "APPLY"
                        ? "Applied On " +
                          new Intl.DateTimeFormat("US", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit"
                          }).format(Date.parse(this.props.social.updatedDate))
                        : null
                    }
                    variant="contained"
                    color="primary">
                    {this.state.apply === "APPLY" ? "Applied" : "Apply"}
                  </Button>
                )
              }
              title={
                <React.Fragment>
                  <Typography variant="body1">
                    {title}
                    {job.jobStatus !== "PUBLISH" || this.props.api.bhiredUser.email === createdBy ? (
                      <Button variant="text" color="primary" className={classes.status}>
                        {job.jobStatus === "PUBLISH"
                          ? "Open"
                          : job.jobStatus === "SAVED"
                          ? "Draft"
                          : job.jobStatus === "CLOSED"
                          ? "Closed"
                          : "New"}
                      </Button>
                    ) : null}
                  </Typography>
                </React.Fragment>
              }
              subheader={
                <Typography variant="caption" className={classes.type}>
                  {eType === "FULL_TIME"
                    ? "Full Time"
                    : eType === "PART_TIME"
                    ? "Part Time"
                    : eType === "REMOTE"
                    ? "Remote"
                    : "Full Time"}
                  ,&nbsp;
                  {new Intl.DateTimeFormat("US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit"
                  }).format(Date.parse(job.createdDate))}
                </Typography>
              }
              style={{
                marginLeft: "10px"
              }}
            />
            <CardContent>
              <Typography variant="body1"> {job.description.value} </Typography>
            </CardContent>
            <CardActions className={classes.actions}>
              <IconButton className={classes.location} disabled={true}>
                <Location color="primary" /> <Typography variant="body1"> {job.location} </Typography>
              </IconButton>
              <Grid container alignItems="center" justify="center">
                <Grid item style={{ marginLeft: "1%" }}>
                  <FacebookShareButton url="https://bhired-web.appspot.com">
                    <FacebookIcon size={32} round={true} />
                  </FacebookShareButton>
                </Grid>
                <Grid item style={{ marginLeft: "1%" }}>
                  <LinkedinShareButton url="https://bhired-web.appspot.com">
                    <LinkedinIcon size={32} round={true} />
                  </LinkedinShareButton>
                </Grid>
                <Grid item style={{ marginLeft: "1%" }}>
                  <EmailShareButton url="https://bhired-web.appspot.com">
                    <EmailIcon size={32} round={true} />
                  </EmailShareButton>
                </Grid>
              </Grid>
              {this.props.api.bhiredUser.email !== createdBy ? (
                <div>
                  <IconButton aria-label="Add to favorites" onClick={this.favorite}>
                    {this.state.favorite === false ? (
                      <FavoriteIcon style={{ color: "grey" }} />
                    ) : (
                      <FavoriteIcon style={{ color: "#B9090B" }} />
                    )}
                  </IconButton>
                </div>
              ) : null}
              <IconButton
                className={classnames(classes.expand, {
                  [classes.expandOpen]: this.state.expanded
                })}
                onClick={this.handleExpandClick}
                aria-expanded={this.state.expanded}
                aria-label="Show more">
                <ExpandMoreIcon />
              </IconButton>
            </CardActions>
            <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
              <CardContent>
                <Grid container alignContent="center" alignItems="center" spacing={2}>
                  <Grid item xs={12}>
                    {job.skills != null && job.skills !== undefined
                      ? job.skills.map((item, index) => (
                          <Chip
                            size="small"
                            key={index}
                            variant="outlined"
                            label={item}
                            style={{ margin: "1px", color: "grey" }}
                          />
                        ))
                      : ""}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1">Start Date:&nbsp;{startDate}&nbsp;</Typography>
                    <Typography variant="body1">Experience:&nbsp;{exp}&nbsp;Year(s)</Typography>
                    <Typography variant="body1">Salary:&nbsp;${job.salary}</Typography>
                    <Typography variant="body1">
                      Seniority Level:&nbsp;
                      {job.seniorityLevel === "SENIOR_LEVEL"
                        ? "Senior"
                        : job.seniorityLevel === "MID_LEVEL"
                        ? "Mid"
                        : job.seniorityLevel === "JUNIOR_LEVEL"
                        ? "Junior"
                        : ""}
                    </Typography>
                    <Typography variant="body1">
                      General:&nbsp;
                      {job.general.value !== "" ? job.general.value : "None"}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Collapse>
          </Card>
        ) : null}
      </React.Fragment>
    );
  }
}

JobCard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withApi(JobCard));
