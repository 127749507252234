import React, { Component } from "react";
import { AddCircle } from "@material-ui/icons";
import { withStyles, Button } from "@material-ui/core";
import Address from "./address";

const styles = () => ({
  main: {},
  fab: {}
});

class AddressWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: this.props.state
    };
  }

  onAddChild = () => {
    console.log(this.state.address);
    this.setState(
      prevState => ({
        address: [...prevState.address, this.props.defAddressProps]
      }),
      () => {
        console.log(this.state.address);
        this.props.handleState(this.state.address);
      }
    );
  };

  onRemoveMe = removeMeIndex => {
    let eArray = this.state.address;
    var index = eArray.indexOf(removeMeIndex);
    eArray.splice(index, 1);
    this.setState(
      {
        address: eArray
      },
      () => {
        this.props.handleState(this.state.address);
      }
    );
  };

  handleState = (addressItem, index) => {
    const eds = this.state.address.slice();
    eds[index] = addressItem;
    this.setState(
      {
        address: eds
      },
      () => {
        this.props.handleState(this.state.address);
      }
    );
  };

  renderChildren = () => {
    const addressArray = this.state.address;
    const children = addressArray.map((addressItem, i) =>
      addressItem.active === true ? (
        <Address
          key={["address", i].join("_")}
          state={addressItem}
          handleState={this.handleState}
          index={i}
          onRemoveMe={this.onRemoveMe}
          showRemoveMe={i > 0 ? true : false}
        />
      ) : null
    );
    return <div> {children} </div>;
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <main className={classes.main}>
          {this.renderChildren()}
          <Button color="primary" className={classes.fab} onClick={this.onAddChild}>
            <AddCircle />
            &nbsp; Add New
          </Button>
        </main>
        <div
          ref={el => {
            this.el = el;
          }}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(AddressWrapper);
