import PropTypes from 'prop-types';
import React, { Component } from 'react';
import MessageList from './messageList'
import UserInput from './userInput'
import Header from './header'

class ChatWindow extends Component {

    onUserInputSubmit = message => {
      this.props.onUserInputSubmit(message);
    }

    onFilesSelected = filesList => {
      this.props.onFilesSelected(filesList);
    }

    render() {
      let messageList = this.props.messageList || [];
      let classList = [
        "sc-chat-window",
        (this.props.isChatOpen ? "opened" : "closed")
      ];
      return (
        <div className={classList.join(' ')}>
          <Header
            groupName={this.props.groupProfile.groupName}
            imageUrl={this.props.groupProfile.imageUrl}
            onClose={this.props.onChatClose}
          />
          <MessageList
            messages={messageList}
            imageUrl={this.props.groupProfile.imageUrl}
          />
          <UserInput
            onSubmit={this.onUserInputSubmit}
            onFilesSelected={this.onFilesSelected}
            showEmoji={this.props.showEmoji}
          />
        </div>
      );
    }
}

ChatWindow.propTypes = {
  groupProfile: PropTypes.object.isRequired,
  isChatOpen: PropTypes.bool.isRequired,
  onChatClose: PropTypes.func.isRequired,
  onFilesSelected: PropTypes.func,
  onUserInputSubmit: PropTypes.func.isRequired,
  showEmoji: PropTypes.bool
}

export default ChatWindow;