import React, { Component } from "react";
import { Paper, CssBaseline, FormControl, withStyles, Checkbox, ListItemText } from "@material-ui/core";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";

const styles = theme => ({
  main: {
    display: "flex",
    width: "100%",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(8)
  },
  paper: {
    marginTop: theme.spacing(1),
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: theme.spacing(1) / 4,
    backgroundColor: "#B9090B",
    color: "#ffffff"
  },
  selected: {
    backgroundColor: "#ffffff  !important"
  }
});

const defSkillsMap = [
  "Java",
  "HTML",
  "CSS",
  "JavaScript",
  "Spring Boot",
  "ReactJS",
  "Machine Learning",
  "Python",
  "NodeJS",
  "C++"
];

class Skills extends Component {
  constructor(props) {
    super(props);
    const skillsLean = [];
    if (this.props.state !== undefined) {
      this.props.state.map(item => {
        if (item.active === true) {
          skillsLean.push(item.skillName);
        }
        return item;
      });
      this.state = {
        skills: skillsLean
      };
    }
  }

  onChange = event => {
    this.setState(
      {
        [event.target.name]: event.target.value
      },
      () => {
        const skillObjFirstCut = this.state.skills.map(item => {
          const c = this.props.state.find(oItem => oItem.skillName === item);
          if (c === undefined) {
            return {
              skillName: item,
              key: "",
              favSkill: true,
              active: true,
              yearsOfExperienceInMonths: 24
            };
          } else {
            c.active = true;
            return c;
          }
        });
        const skillInActive = this.props.state.map(item => {
          const d = skillObjFirstCut.find(oItem => oItem.skillName === item.skillName);
          if (d === undefined) {
            item.active = false;
            return item;
          } else {
            return d;
          }
        });
        const skillsFinal = [...new Set([...skillObjFirstCut, ...skillInActive])];
        this.props.handleState(skillsFinal);
      }
    );
  };

  render() {
    const { classes } = this.props;
    const skillsMap =
      this.props.skillSuggestions === undefined || this.props.skillSuggestions.length === 0
        ? defSkillsMap
        : this.props.skillSuggestions;
    return (
      <React.Fragment>
        <main className={classes.main}>
          <CssBaseline />
          <Paper className={classes.paper}>
            <form>
              <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="select-multiple-chip"> Skills </InputLabel>
                <Select
                  autoWidth={true}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "right"
                    }
                  }}
                  multiple
                  name="skills"
                  value={this.state.skills}
                  onChange={this.onChange}
                  input={<Input id="select-multiple-checkbox" />}
                  renderValue={selected => (
                    <div className={classes.chips}>
                      {selected.map((item, i) => (
                        <Chip key={i} label={item} className={classes.chip} />
                      ))}
                    </div>
                  )}>
                  {skillsMap.map((skill, i) => (
                    <MenuItem
                      key={i}
                      value={skill}
                      classes={{
                        selected: classes.selected
                      }}>
                      <Checkbox checked={this.state.skills.indexOf(skill) > -1} />
                      <ListItemText primary={skill} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </form>
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}
export default withStyles(styles, {
  withTheme: true
})(Skills);
