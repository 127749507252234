import React from "react";
import PropTypes from "prop-types";
import {
  CardHeader,
  CardContent,
  Card,
  withStyles,
  Avatar,
  IconButton,
  Typography,
  Grid,
  Chip
} from "@material-ui/core";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import grey from "@material-ui/core/colors/grey";
import FavoriteIcon from "@material-ui/icons/Favorite";
import Lock from "@material-ui/icons/Lock";
import LockOpen from "@material-ui/icons/LockOpen";
import ViewProfile from "@material-ui/icons/HowToReg";
import { getAvatarColor } from "../../../common/helpers";
import Clipboard from "react-clipboard.js";
import { withApi } from "../../Api";
import LoadingIndicator from "../../../common/loading";
import ProfileView from "../Profile/profileView";
import Location from "@material-ui/icons/LocationOn";

const styles = theme => ({
  card: {
    width: "98%",
    transitionDuration: "0.3s",
    display: "block",
    height: "100%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    color: grey[800]
  },
  media: {},
  actions: {
    display: "flex"
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  avatar: {
    backgroundColor: red[500]
  },
  status: {
    float: "right",
    cursor: "none",
    color: grey[500],
    marginLeft: "1%",
    textTransform: "none"
  },
  type: {
    float: "right",
    cursor: "none",
    color: green[500],
    textTransform: "none"
  },
  location: {
    cursor: "none",
    float: "left",
    padding: "0px"
  },
  right: {
    marginLeft: "auto"
  }
});

class CandidateCard extends React.Component {
  constructor(props) {
    super(props);
    //console.log(this.props.social);
    const min = 1;
    const max = 20;
    let rand = min + Math.random() * (max - min);
    this.state = {
      expanded: false,
      loading: false,
      locked: this.props.social.unlock === undefined || this.props.social.unlock === null ? true : false,
      accepted: this.props.social.unlock === "ACCEPTED" ? true : false,
      unlocksent: this.props.social.unlock === "SENT" ? true : false,
      favorite: this.props.social.favourite === "FAVOURITE" ? true : false,
      openProfile: false,
      key: this.props.candidate.key,
      randExp : Math.round(rand)
    };
  }

  handleExpandClick = () => {
    this.setState(state => ({
      expanded: !state.expanded
    }));
  };

  unLockRequest = () => {
    this.setState({
      loading: true
    });
    this.props.api.unlockProfile(this.props.candidate.key).then(response => {
      this.setState(state => ({
        locked: !state.locked,
        unlocksent: true,
        loading: false
      }));
    });
  };

  favorite = () => {
    this.setState({
      loading: true
    });
    if (this.state.favorite === true) {
      this.props.api.unFavoriteProfile(this.props.candidate.key).then(response => {
        this.setState(state => ({
          favorite: !state.favorite,
          loading: false
        }));
      });
    } else {
      this.props.api.favoriteProfile(this.props.candidate.key).then(response => {
        this.setState(state => ({
          favorite: !state.favorite,
          loading: false
        }));
      });
    }
  };

  capitalize = str => {
    return str.charAt(0).toUpperCase();
  };

  openUnlockedProfile = () => {
    this.setState({
      openProfile: true
    });
  };

  handleProfileClose = () => {
    this.setState({
      openProfile: false
    });
  };

  render() {
    const { classes, candidate } = this.props;
    //console.log(this.props.social);
    const rColor = getAvatarColor(candidate.firstName);
    const title = candidate.profileTitle;
    const exp =
      candidate.totalWrkExpInDays !== undefined &&
      candidate.totalWrkExpInDays !== null &&
      candidate.totalWrkExpInDays !== ""
        ? candidate.totalWrkExpInDays / 365
        : this.state.randExp;
    const location = candidate.address !== undefined && candidate.address !== null ? candidate.address[0].city : "";
    return (
      <React.Fragment>
        <LoadingIndicator display={this.state.loading} />
        <ProfileView
          open={this.state.openProfile}
          id={this.props.candidate.key}
          handleProfileClose={() => this.handleProfileClose}
        />
        <Card className={classes.card}>
          <CardHeader
            avatar={
              <Avatar
                aria-label="Avatar"
                className={classes.avatar}
                style={{
                  backgroundColor: rColor
                }}>
                {this.capitalize(candidate.firstName)}
              </Avatar>
            }
            action={
              <div className={classes.right}>
                <IconButton aria-label="Add to favorites" onClick={this.favorite}>
                  {this.state.favorite === false ? (
                    <FavoriteIcon style={{ color: "grey" }} />
                  ) : (
                    <FavoriteIcon style={{ color: "#B9090B" }} />
                  )}
                </IconButton>
                {this.state.locked === true ? (
                  <IconButton title="Click to Unlock" onClick={this.unLockRequest}>
                    <Lock />
                  </IconButton>
                ) : this.state.unlocksent === true ? (
                  <IconButton title="Profile Unlock Request Pending">
                    <LockOpen />
                  </IconButton>
                ) : this.state.accepted === true ? (
                  <IconButton title="Click to View Candidate Profile" onClick={this.openUnlockedProfile}>
                    <ViewProfile />
                  </IconButton>
                ) : null}
              </div>
            }
            title={
              <Typography variant="body1">
                {title}&nbsp;With&nbsp;{exp}&nbsp;Year(s) of Experience
              </Typography>
            }
            subheader={<div>Actively looking... </div>}
          />
          <CardContent>
            <Typography variant="body1">{candidate.aboutMe}</Typography>
          </CardContent>
          <CardContent>
            <Grid container>
              {candidate.skill != null
                ? candidate.skill.map(item => (
                    <Chip
                      size="small"
                      key={item.key}
                      variant="outlined"
                      label={item.skillName}
                      style={{ margin: "1px", color: "grey" }}
                    />
                  ))
                : ""}
            </Grid>
          </CardContent>
          <CardContent>
            <Clipboard
              style={{float: "right", marginBottom: "5px"}}
              data-clipboard-text={candidate.userIdentificationNumber}
              button-title="Click to copy">
              <Chip label={candidate.userIdentificationNumber} />
            </Clipboard>
            <IconButton className={classes.location} disabled={true}>
              <Location color="primary" /> <Typography variant="body1"> {location} </Typography>
            </IconButton>
          </CardContent>
        </Card>
      </React.Fragment>
    );
  }
}

CandidateCard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withApi(CandidateCard));
