import React from "react";
import PropTypes from "prop-types";
import {
  CardHeader,
  CardContent,
  Card,
  withStyles,
  Avatar,
  IconButton,
  Typography,
  Button,
  Badge
} from "@material-ui/core";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import grey from "@material-ui/core/colors/grey";
import Location from "@material-ui/icons/LocationOn";
import ChatOn from "@material-ui/icons/Chat";
import Link from "@material-ui/icons/Link";
import { getAvatarColor } from "../../../common/helpers";
import { withApi } from "../../Api";

const styles = theme => ({
  card: {
    width: "100%",
    height: "100%",
    transitionDuration: "0.3s",
    margin: "1%",
    position: "relative"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    color: grey[800]
  },
  media: {},
  actions: {
    display: "flex"
  },
  avatar: {
    backgroundColor: red[500]
  },
  type: {
    float: "right",
    cursor: "none",
    color: green[500],
    textTransform: "none"
  },
  location: {
    cursor: "none",
    float: "left"
  },
  right: {
    marginLeft: "auto"
  }
});

class HiveCard extends React.Component {
  dataLoaded = false;

  constructor(props) {
    super(props);
    this.state = {
      connect: this.props.social.connect,
      messageCount: 0,
      chatKey: null
    };
  }

  componentDidMount() {
    this.dataLoaded = true;
    this.setChatKey();
  }

  componentWillUnmount() {
    this.dataLoaded = false;
  }

  setChatKey = () => {
    const body = {
      groupName: this.props.candidate.email,
      members: [this.props.candidate.email, this.props.api.bhiredUser.email]
    };
    this.props.api.initiateChat(body).then(response => {
      if (this.dataLoaded === true) {
        this.setState({
          chatKey: response.chatKey
        });
        var mRefMessages = this.props.api.db.collection("hive/" + this.state.chatKey + "/messages");
        mRefMessages.where("senderKey", "==", this.props.candidate.key).onSnapshot(snap => {
          if (this.dataLoaded) {
            this.setState({
              messageCount: snap.size
            });
          }
        });
      }
    });
  };

  connectRequest = () => {
    this.setState({
      loading: true
    });
    this.props.api.requestConnection(this.props.candidate.key).then(() => {
      this.setState({
        connect: "SENT",
        loading: false
      });
    });
  };

  acceptRequest = () => {
    this.setState({
      loading: true
    });
    this.props.api.acceptConnection(this.props.candidate.key).then(() => {
      this.setState({
        connect: "ACCEPTED",
        loading: false
      });
    });
  };

  initiateChat = (email, title) => {
    var mRef = this.props.api.db.collection("hive").doc(this.state.chatKey);
    let key1 = this.props.candidate.key;
    let key2 = this.props.api.bhiredUser.key;
    mRef.get().then(doc => {
      if (!doc.exists) {
        mRef.set(
          {
            createdDate: new Date(),
            groupAvatar: "",
            groupAvatarColor: getAvatarColor(this.props.candidate.firstName),
            groupName: this.props.candidate.firstName + "...",
            key: this.state.chatKey,
            updatedDate: new Date(),
            lastMessage: "",
            participants: [key1, key2]
          },
          { merge: true }
        );
      }
    });
    var mArray = [];
    mRef
      .collection("messages")
      .orderBy("createdDate", "asc")
      .limit(150)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          var d = {
            senderKey: doc.data().senderKey,
            author: doc.data().senderUserName,
            type: "text",
            data: { text: doc.data().message },
            timestamp: doc.data().createdDate,
            key: doc.id
          };
          mArray.push(d);
        });
        this.props.startChat(title, email, this.state.chatKey, mRef, key1, mArray);
      });
  };

  capitalize = str => {
    return str.charAt(0).toUpperCase();
  };

  curatedName = name => {
    if (name !== "" && name !== null) {
      name = name.split("@")[0];
      return name.charAt(0).toUpperCase() + name.slice(1) + "...";
    } else {
      return "";
    }
  };

  render() {
    const { classes, candidate } = this.props;
    const rColor = getAvatarColor(candidate.firstName);
    const title = this.curatedName(candidate.firstName + " " + candidate.lastName);
    const location = candidate.address !== undefined && candidate.address !== null ? candidate.address[0].city : "";
    //console.log(this.props.social);
    //console.log(this.state.connect);
    return (
      <React.Fragment>
        <Card className={classes.card}>
          <CardHeader
            avatar={
              <Avatar
                aria-label="Avatar"
                className={classes.avatar}
                style={{
                  backgroundColor: rColor
                }}>
                {this.capitalize(candidate.firstName)}
              </Avatar>
            }
            action={
              this.state.connect !== "SENT" &&
              this.state.connect !== "ACCEPTED" &&
              this.state.connect !== "RECEIVED" ? (
                <Button variant="outlined" color="primary" onClick={() => this.connectRequest()}>
                  Connect
                  <Link />
                </Button>
              ) : this.state.connect === "SENT" ? (
                <Button variant="outlined" color="primary" disabled={true}>
                  Request Pending&nbsp;
                  <Link />
                </Button>
              ) : this.state.connect === "ACCEPTED" ? (
                <IconButton color="primary" onClick={() => this.initiateChat(candidate.email, title)}>
                  <Badge badgeContent={this.state.messageCount} max={9999999} color="secondary" title="New Messages">
                    <ChatOn />
                  </Badge>
                </IconButton>
              ) : this.state.connect === "RECEIVED" ? (
                <Button variant="outlined" color="primary" onClick={() => this.acceptRequest()}>
                  Pending Your Approval&nbsp;
                  <Link />
                </Button>
              ) : null
            }
            title={<Typography variant="body1">{title}</Typography>}
            subheader={<div>{candidate.profileTitle}</div>}
          />
          <CardContent>
            <Typography variant="body1">{candidate.aboutMe}</Typography>
          </CardContent>
          <CardContent>
            <IconButton style={{ float: "right" }} title="Candidate's Location">
              <Location color="primary" />
              <Typography variant="body1"> {location} </Typography>
            </IconButton>
          </CardContent>
        </Card>
      </React.Fragment>
    );
  }
}

HiveCard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withApi(HiveCard));
