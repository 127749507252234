import React, { Component } from "react";
import { Paper, CssBaseline, FormControl, withStyles, Checkbox, ListItemText } from "@material-ui/core";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";

const styles = theme => ({
  main: {
    display: "flex",
    width: "100%",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(8)
  },
  paper: {
    marginTop: theme.spacing(),
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: theme.spacing() / 4,
    backgroundColor: "#B9090B",
    color: "#ffffff"
  },
  selected: {
    backgroundColor: "#ffffff  !important"
  }
});

const skillsMap = [
  "Java",
  "HTML",
  "CSS",
  "Javascript",
  "Spring Boot",
  "ReactJS",
  "Machine Learning",
  "Python",
  "Nodejs",
  "C++"
];

class AddSkills extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      ...props.state
    };
  }

  onChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
    this.props.handleState(event);
  };

  onIndustryChange = event => {
    this.setState({
      [event.target.name]: [event.target.value]
    });
    this.props.handleState(event);
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <main className={classes.main}>
          <CssBaseline />
          <Paper className={classes.paper}>
            <form>
              <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="select-multiple-chip"> Skills </InputLabel>
                <Select
                  autoWidth={true}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "right"
                    }
                  }}
                  multiple
                  name="skills"
                  value={this.state.skills}
                  onChange={this.onChange}
                  input={<Input id="select-multiple-checkbox" />}
                  renderValue={selected => (
                    <div className={classes.chips}>
                      {selected.map(value => (
                        <Chip key={value} label={value} className={classes.chip} />
                      ))}
                    </div>
                  )}>
                  {skillsMap.map(skills => (
                    <MenuItem
                      key={skills}
                      value={skills}
                      classes={{
                        selected: classes.selected
                      }}>
                      <Checkbox checked={this.state.skills.indexOf(skills) > -1} />
                      <ListItemText primary={skills} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="industry-simple">Industry Type</InputLabel>
                <Select
                  autoWidth
                  inputProps={{
                    name: "industry",
                    id: "industry-simple"
                  }}
                  value={
                    this.state.industry.length > 0 ? this.state.industry[0] : "INFORMATION_TECHNOLOGY_AND_SERVICES"
                  }
                  name="industry"
                  onChange={this.onIndustryChange}
                  style={{
                    textAlign: "left"
                  }}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "right"
                    }
                  }}>
                  <MenuItem value="INFORMATION_TECHNOLOGY_AND_SERVICES"> IT & Services </MenuItem>
                  <MenuItem value="COMPUTER_SOFTWARE"> Computer Software </MenuItem>
                  <MenuItem value="INTERNET"> Internet </MenuItem>
                  <MenuItem value="OTHER"> Other </MenuItem>
                </Select>
              </FormControl>
            </form>
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}
export default withStyles(styles, {
  withTheme: true
})(AddSkills);
