import React from "react";
import { compose } from "recompose";
import { withStyles, Grid, Typography } from "@material-ui/core";
import JobCard from "./jobCard";

const styles = theme => ({
  root: {
    flexGrow: 1,
    display: "flex"
  }
});

class Jobs extends React.Component {
  render() {
    const { jobsResultSet, classes } = this.props;
    return (
      <React.Fragment>
        <Grid container className={classes.root} alignItems="center" justify="center" spacing={3}>
          {jobsResultSet !== undefined
            ? jobsResultSet.map((item, index) => (
                <Grid key={index} item xs={12}>
                  <JobCard
                    job={item.bhiredSearchObject}
                    social={item.bhiredObject}
                    searchJobs={this.props.searchJobs}
                    hidePostJobButton={this.props.hidePostJobButton}
                  />
                </Grid>
              ))
            : null}
        </Grid>
        {jobsResultSet.length === 0 ? (
          <Grid container style={{ padding: "50px" }} alignItems="center" justify="center" spacing={3}>
            <Typography
              style={{
                padding: "50px",
                color: "grey"
              }}
              variant="h5">
              No Jobs Found
            </Typography>
          </Grid>
        ) : null}
      </React.Fragment>
    );
  }
}

export default compose(withStyles(styles))(Jobs);
