import React from "react";
import PropTypes from "prop-types";
import { CardHeader, CardContent, Card, withStyles, Avatar, Typography, Button, IconButton } from "@material-ui/core";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import grey from "@material-ui/core/colors/grey";
import Link from "@material-ui/icons/Link";
import { withApi } from "../../Api";
import Iframe from "react-iframe";
import CloseIcon from "@material-ui/icons/Cancel";

const styles = theme => ({
  card: {
    width: "96%",
    height: "100%",
    transitionDuration: "0.3s",
    margin: "1%",
    position: "relative"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    color: grey[800]
  },
  media: {},
  actions: {
    display: "flex"
  },
  avatar: {
    backgroundColor: red[500]
  },
  type: {
    float: "right",
    cursor: "none",
    color: green[500],
    textTransform: "none"
  },
  location: {
    cursor: "none",
    float: "left"
  },
  right: {
    marginLeft: "auto"
  },
  closeButton: {
    float: "right",
    marginRight: "5%"
  }
});
const URI = "https://photontadpole-builds.s3.ap-south-1.amazonaws.com/talent-litmus/bhired/wrapper/index.html";

class AssessmentCard extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      status: "New",
      score: 0.0,
      rating: "Not Set",
      competencies: []
    };
  }

  connectRequest = () => {
    this.setState({
      open: true,
      URL: URI + "?uid=" + this.props.api.bhiredUser.id
    });
  };

  handleClickClose = () => {
    this.setState({
      open: false
    });
  };

  componentDidMount() {
    this._isMounted = true;
    this.getResults();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getResults = () => {
    this.props.api.getTalentLitmusObject().then(result => {
      if (this._isMounted) {
        this.setState({
          status: result.status,
          score: result.percentileScore,
          rating: result.overAllRating,
          competencies: result.competencies !== null ? result.competencies : []
        });
      }
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        {this.state.open === true ? (
          <Card className={classes.card}>
            <CardHeader
              title={<Typography variant="h6">Game On...</Typography>}
              action={
                <IconButton aria-label="Close" color="primary" className={classes.closeButton} onClick={this.handleClickClose}>
                  <CloseIcon />
                </IconButton>
              }
            />
            <CardContent style={{ width: "600px", height: "425px", padding: "2%" }}>
              <Iframe url={this.state.URL} width="95%" height="95%" position="absolute" />
            </CardContent>
          </Card>
        ) : (
          <Card className={classes.card}>
            <CardHeader
              avatar={
                <Avatar
                  aria-label="Avatar"
                  className={classes.avatar}
                  style={{
                    backgroundColor: "red"
                  }}
                >
                  Talent Litmus
                </Avatar>
              }
              action={
                <Button variant="outlined" color="primary" onClick={() => this.connectRequest()}>
                  Launch Talent Litmus
                  <Link />
                </Button>
              }
              title={<Typography variant="body1">Scores</Typography>}
              subheader={<div>Talent Litmus Scores</div>}
            />
            <CardContent>
              <Typography variant="body1">Status: {this.state.status}</Typography>
              <Typography variant="body1">Score: {this.state.score}</Typography>
              <Typography variant="body1">Rating: {this.state.rating}</Typography>
            </CardContent>
          </Card>
        )}
      </React.Fragment>
    );
  }
}

AssessmentCard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withApi(AssessmentCard));
