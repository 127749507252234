import React, { Component } from "react";
import Message from "./messages";

class MessageList extends Component {
  componentDidUpdate() {
    this.scrollList.scrollTop = this.scrollList.scrollHeight;
  }

  render() {
    return (
      <div className="sc-message-list" ref={el => (this.scrollList = el)}>
        {this.props.messages.map((message) => {
          return <Message message={message} key={message.key} />;
        })}
      </div>
    );
  }
}

export default MessageList;
