import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  Paper,
  Typography,
  Button,
  CssBaseline,
  FormControl,
  TextField,
  withStyles,
  InputAdornment
} from "@material-ui/core";
import { withApi } from "../Api";
import * as Routes from "../../common/routes";
import Email from "@material-ui/icons/Email";
import Images from "../../assets";
import LoadingIndicator from "../../common/loading";

const styles = theme => ({
  main: {
    width: "auto",
    display: "block",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1)
  },
  submit: {
    marginTop: theme.spacing(3)
  }
});

const INITIAL_STATE = {
  email: "",
  error: "",
  isLoading: false
};

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    this.setState({ isLoading: true });
    const { email } = this.state;
    this.props.api
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE, isLoading: false });
        this.props.history.push(Routes.RESET_PASSWORD_MESSAGE);
      })
      .catch(error => {
        console.log(error);
        switch (error.code) {
          case "auth/invalid-email":
            error.emailMessage = "Invalid Email Address";
            break;
          case "auth/user-not-found":
            error.emailMessage = "Unknown Email Address";
            break;
          default:
            error = "";
        }
        this.setState({
          error,
          isLoading: false
        });
      });
    event.preventDefault();
  };

  onChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  render() {
    const { classes } = this.props;
    const StyledAdornment = styled(InputAdornment)`
      margin-right: 10px;
    }`;
    return (
      <React.Fragment>
        <LoadingIndicator display={this.state.isLoading} />
        <main className={classes.main}>
          <CssBaseline />
          <Paper className={classes.paper}>
            <div
              style={{
                textAlign: "center",
                width: "35%",
                height: "auto"
              }}>
              <img src={Images.logo} alt="bhiredinc" />
            </div>
            <Typography
              variant="h5"
              style={{
                color: "gray",
                marginTop: "5px"
              }}>
              Reset Password
            </Typography>
            <form onSubmit={this.onSubmit}>
              <FormControl margin="normal" required fullWidth>
                <TextField
                  required
                  name="email"
                  value={this.state.email}
                  onChange={this.onChange}
                  autoComplete="email"
                  type="text"
                  autoFocus
                  placeholder="Email Address"
                  error={this.state.error.emailMessage == null ? false : true}
                  helperText={this.state.error.emailMessage}
                  InputProps={{
                    startAdornment: (
                      <StyledAdornment>
                        <Email color="disabled" />
                      </StyledAdornment>
                    )
                  }}
                />
              </FormControl>
              <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                Reset My Password
              </Button>
            </form>
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}
const StyledLink = styled(Link)`
  font-size: 11px;
  text-decoration: none;
  color: gray;
`;
const ResetPasswordLink = props => <StyledLink to={Routes.RESET_PASSWORD}>RESET PASSWORD</StyledLink>;
export default withStyles(styles)(withApi(ResetPassword));
export { ResetPasswordLink };
