import React from "react";
import { Grid, Avatar, withStyles } from "@material-ui/core";
import ProfileIcon from "@material-ui/icons/Person";
import { withApi } from "../Api";
import LoadingIndicator from "../../common/loading";

const styles = theme => ({
  avatar: {
    marginTop: 2,
    cursor: "pointer",
    width: 72,
    height: 72,
    borderColor: "gray",
    borderStyle: "outset",
    backgroundColor: "transparent",
    color: theme.palette.primary.main
  },
  profile: {
    marginTop: 2,
    cursor: "pointer",
    width: 72,
    height: 72,
    backgroundColor: "transparent"
  }
});
class ProfileImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageURL: this.props.api.bhiredUser != null ? this.props.api.bhiredUser.avatar : "",
      isLoading: false,
      error: ""
    };
  }

  onImageChange = event => {
    this.setState({
      file: event.target.files[0],
      isLoading: true
    });
    this.uploadImage(event);
  };

  uploadImage = event => {
    var blob = event.target.files[0];
    event.preventDefault();
    if (blob >= 2000000) {
      this.setState({
        error: "File size exceeds limit of 2MB."
      });
      return;
    }
    let data = new FormData();
    data.append("file", blob);
    data.append("name", blob.name);
    this.props.api
      .uploadProfilePicture(data)
      .then(response => {
        let imageURL = response.avatar;
        this.setState({
          error: "",
          imageURL: imageURL,
          isLoading: false
        });
      })
      .catch(err => {
        this.setState({
          error: err
        });
      });
  };

  render() {
    const { classes } = this.props;
    const url = this.state.imageURL !== "" && this.state.imageURL !== undefined ? this.state.imageURL + "=s1024-c" : "";
    return (
      <React.Fragment>
        <LoadingIndicator display={this.state.isLoading} />
        <Grid container justify="center">
          <input hidden id="profileImage" onChange={this.onImageChange} accept="image/*" type="file" />
          <label htmlFor="profileImage">
            {url === "" ? (
              <Avatar className={classes.avatar}>
                <ProfileIcon fontSize="large" />
              </Avatar>
            ) : (
              <Avatar alt="Profile" className={classes.profile} src={url} />
            )}
          </label>
        </Grid>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(withApi(ProfileImage));
