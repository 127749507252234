import React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogContent, CircularProgress, withStyles } from "@material-ui/core";
const styles = theme => ({
  open: {
    top: "50%",
    left: "50%",
    position: "fixed",
    transform: "translate(-50%)"
  },
  root: {
    backgroundColor: "transparent"
  },
  paper: {
    backgroundColor: "transparent",
    boxShadow: "none",
    overflow: "hidden"
  }
});

class Loading extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.display}
        BackdropProps={{
          classes: {
            root: classes.root
          }
        }}
        PaperProps={{
          classes: {
            root: classes.paper
          }
        }}>
        <DialogContent>
          <CircularProgress variant="indeterminate" className={classes.open} color="primary" />
        </DialogContent>
      </Dialog>
    );
  }
}

Loading.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Loading);
