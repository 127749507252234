import React from "react";
import PropTypes from "prop-types";
import { CardContent, Card, withStyles, Typography, Grid, Button } from "@material-ui/core";
import grey from "@material-ui/core/colors/grey";
import CloseIcon from "@material-ui/icons/Cancel";
import { withApi } from "../../Api";
import ProfileView from "../Profile/profileView";
import LoadingIndicator from "../../../common/loading";

const styles = theme => ({
  card: { margin: "5px" },
  cardRead: { margin: "5px", background: "#F5F5F5" },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    color: grey[800]
  }
});

class NotificationCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profileOpen: false,
      loading: false
    };
  }

  handleProfileClose = () => {
    this.setState({
      profileOpen: false
    });
  };

  handleAction = (type, actionKey, artifactKey) => {
    //console.log(type);
    if (type === "UNLOCK_REQ") {
      this.setState({
        loading: true
      });
      if (actionKey === "Accept") {
        this.props.api.acceptUnlockProfile(artifactKey).then(() => {
          this.setState({
            loading: false
          });
        });
      } else if (actionKey === "Reject") {
        this.props.api.rejectUnlockProfile(artifactKey).then(() => {
          this.setState({
            loading: false
          });
        });
      } else {
        this.setState({
          profileOpen: true,
          loading: false
        });
      }
    }
    if (type === "PROFILE_FAVORITED") {
      //console.log(actionKey);
      if (actionKey === "View Candidate Profile" || actionKey === "View Recruiter Profile") {
        this.setState({
          profileOpen: true
        });
      }
    }
    if (type === "CONNECTION_REQ") {
      this.setState({
        loading: true
      });
      if (actionKey === "Accept") {
        this.props.api.acceptConnection(artifactKey).then(() => {
          this.setState({
            loading: false
          });
        });
      }
      if (actionKey === "Reject") {
        this.props.api.rejectConnection(artifactKey).then(() => {
          this.setState({
            loading: false
          });
        });
      }
    }
    if (type === "JOB_APPLY") {
      console.log(actionKey);
      if (actionKey === "View Candidate Profile" || actionKey === "View Recruiter Profile") {
        this.setState({
          profileOpen: true
        });
      }
    }
    if (type === "JOB_FAVORITED") {
      if (actionKey === "View Candidate Profile" || actionKey === "View Recruiter Profile") {
        this.setState({
          profileOpen: true
        });
      }
    }
  };

  viewProfile = () => {
    this.setState({
      profileOpen: true
    });
  };

  render() {
    const { classes } = this.props;
    var formattedTimestamp = Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "2-digit",
      second: "2-digit"
    }).format(this.props.sentOn);
    var formattedActionTakenTimestamp = Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "2-digit",
      second: "2-digit"
    }).format(this.props.actionTakenDate);
    var size = Object.keys(this.props.actions).length;
    return (
      <React.Fragment>
        <LoadingIndicator display={this.state.loading} />
        <ProfileView
          open={this.state.profileOpen}
          id={this.props.artifactKey}
          handleProfileClose={() => this.handleProfileClose}
        />
        <Card className={!this.props.isRead ? classes.card : classes.cardRead}>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={10}>
                <Typography className={classes.heading}>{this.props.title}</Typography>
                <Typography variant="caption" style={{ float: "left" }}>
                  {formattedTimestamp}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <CloseIcon
                  aria-label="Close"
                  color="primary"
                  onClick={() => this.props.handleClickDelete(this.props.id)}
                  style={{ float: "right", cursor: "auto" }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">{this.props.description}</Typography>
              </Grid>
              {this.props.actions !== undefined && this.props.actionTaken === "NONE" && size === 1 ? (
                Object.keys(this.props.actions).map((key, i) => (
                  <Grid item xs={12} key={i}>
                    <Button
                      onClick={() => this.handleAction(this.props.type, key, this.props.artifactKey)}
                      key={i}
                      color="primary"
                      variant="outlined">
                      {key}
                    </Button>
                  </Grid>
                ))
              ) : this.props.actionTaken !== "NONE" ? (
                <Grid item xs={12}>
                  <Typography variant="body2" style={{ fontStyle: "italic" }}>
                    {this.props.actionTakenTitle}&nbsp;on&nbsp;
                    {formattedActionTakenTimestamp}
                  </Typography>
                  <Button variant="outlined" onClick={() => this.viewProfile()}>
                    View Profile
                  </Button>
                </Grid>
              ) : null}
              {this.props.actions !== undefined && this.props.actionTaken === "NONE" && size > 1
                ? Object.keys(this.props.actions).map((key, i) => (
                    <Grid item xs={6} key={i}>
                      <Button
                        onClick={() => this.handleAction(this.props.type, key, this.props.artifactKey)}
                        key={i}
                        color="primary"
                        variant="outlined"
                        style={i === 0 ? { float: "left" } : { float: "right" }}>
                        {key}
                      </Button>
                    </Grid>
                  ))
                : null}
            </Grid>
          </CardContent>
        </Card>
      </React.Fragment>
    );
  }
}

NotificationCard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withApi(NotificationCard));
