import React, { Component } from "react";
import { Paper, CssBaseline, FormControl, TextField, withStyles, InputAdornment } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";

const styles = theme => ({
  main: {
    display: "flex",
    width: "100%",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(8)
  },
  paper: {
    marginTop: theme.spacing(1),
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
  }
});

class NewJobDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      ...props.state
    };
  }

  onChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
    this.props.handleState(event);
  };

  handleDateChange = date => {
    this.setState({
      startDate: date
    });
    this.props.handleDateChange(date);
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <main className={classes.main}>
          <CssBaseline />
          <Paper className={classes.paper}>
            <form>
              <FormControl margin="normal" fullWidth>
                <TextField
                  label="Salary"
                  placeholder="Salary"
                  name="salary"
                  value={this.state.salary}
                  onChange={this.onChange}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                  }}
                />
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <TextField
                  multiline={true}
                  rows={6}
                  variant="outlined"
                  placeholder="Job Description"
                  value={this.state.description.value}
                  name="description"
                  onChange={this.onChange}
                />
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    required
                    clearable
                    animateYearScrolling
                    margin="normal"
                    openTo="year"
                    label="Start Date"
                    format="MM/yyyy"
                    minDate={new Date("01/01/2019")}
                    value={this.state.startDate}
                    onChange={this.handleDateChange}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </form>
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(NewJobDetails);
