import React, { Component } from "react";
import BhiredDialog from "../../common/dialog";
import * as Routes from "../../common/routes";

class RegistrationSuccessful extends Component {
  render() {
    return (
      <div>
        <BhiredDialog
          open="true"
          title="Thank You for Registering @ bhiredinc!"
          message="Please check your email to verify your account and login to bhiredinc."
          forward={Routes.AUTH_HEADER}
        />
      </div>
    );
  }
}
export default RegistrationSuccessful;
