import React, { Component } from "react";
import BhiredDialog from "../../common/dialog";
import * as Routes from "../../common/routes";

class ResetPasswordMessage extends Component {
  render() {
    return (
      <div>
        <BhiredDialog
          open="true"
          title="Password Reset Instructions Sent"
          message="Please check your email to reset your password and login to bhiredinc."
          forward={Routes.AUTH_HEADER}
        />
      </div>
    );
  }
}
export default ResetPasswordMessage;
