import React from "react";
import ProfileStepper from "./profileStepper";
import { Dialog, DialogTitle, withStyles, Typography, DialogContent, IconButton, Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Cancel";
import { withApi } from "../../Api";
import LoadingIndicator from "../../../common/loading";

const styles = theme => ({
  dialogPaper: {
    minHeight: "100vh",
    maxHeight: "100vh",
    minWidth: "65%",
    maxWidth: "65%"
  },
  root: {
    margin: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  closeButton: {
    position: "absolute",
    right: 0,
    top: theme.spacing(1)
  },
  status: {
    position: "absolute",
    top: "2%",
    cursor: "none",
    right: "50%",
    transform: "translate(50%)",
    background: "#d3d3d3"
  },
  update: {
    position: "absolute",
    right: "8%",
    top: theme.spacing(2),
    color: theme.palette.primary.contrastText
  }
});

class EditProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
      activeStep: this.props.activeStep,
      dataLoading: false
    };
  }

  handleClickClose = () => {
    this.props.handleClickClose();
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ activeStep : nextProps.activeStep });
  }

  handleClickUpdate = () => {
    const s = this.state.activeStep;
    if (s <= 5) {
      if (s === 0) {
        if (this.validateBasicInfo()) {
          this.setState({
            dataLoading: true
          });
          this.props.api.updateUserBasicInfo(this.state.data.bhiredUser).then(bhiredUser => {
            this.props.api.updateMe(bhiredUser);
            this.setState(
              {
                dataLoading: false
              },
              () => {
                this.props.handleClickUpdate();
              }
            );
          });
        }
      }
      if (s === 1) {
        if (this.validateAddress()) {
          this.setState({
            dataLoading: true
          });
          this.props.api.createOrUpdateAddressArray(this.state.data.address).then(address => {
            this.setState(
              {
                dataLoading: false
              },
              () => {
                this.props.handleClickUpdate();
              }
            );
          });
        }
      }
      if (s === 2) {
        if (this.validateEducation()) {
          this.setState({
            dataLoading: true
          });
          this.props.api.createOrUpdateEducationArray(this.state.data.education).then(education => {
            this.setState(
              {
                dataLoading: false
              },
              () => {
                this.props.handleClickUpdate();
              }
            );
          });
        }
      }
      if (s === 3) {
        if (this.validateWorkExperience()) {
          this.setState({
            dataLoading: true
          });
          this.props.api.createOrUpdateWorkExArray(this.state.data.workExperience).then(workExperience => {
            this.setState(
              {
                dataLoading: false
              },
              () => {
                this.props.handleClickUpdate();
              }
            );
          });
        }
      }
      if (s === 4) {
        this.setState({
          dataLoading: true
        });
        this.props.api.createOrUpdateSkillsArray(this.state.data.skills).then(skills => {
          this.setState(
            {
              dataLoading: false
            },
            () => {
              this.props.handleClickUpdate();
            }
          );
        });
      }
      if (s === 5) {
        if (this.validateReferences()) {
          this.setState({
            dataLoading: true
          });
          this.props.api.createOrUpdateReferencesArray(this.state.data.references).then(references => {
            this.setState(
              {
                dataLoading: false
              },
              () => {
                this.props.handleClickUpdate();
              }
            );
          });
        }
      }
    } else {
      this.props.handleClickUpdate();
    }
  };

  validateBasicInfo = () => {
    var isValid = true;
    if (this.state.data.bhiredUser.firstName === "") {
      isValid = false;
    }
    if (this.state.data.bhiredUser.lastName === "") {
      isValid = false;
    }
    if (this.state.data.bhiredUser.dob === "" || this.state.data.bhiredUser.dob === "Invalid date") {
      isValid = false;
    }
    return isValid;
  };

  validateAddress = () => {
    var isValid = true;
    this.setState({
      address: this.state.data.address.map(item => {
        if (item.address1 === "") {
          isValid = false;
        }
        if (item.city === "") {
          isValid = false;
        }
        if (item.state === "") {
          isValid = false;
        }
        if (item.zip === "") {
          isValid = false;
        }
        if (item.country === "") {
          isValid = false;
        }
        return item;
      })
    });
    return isValid;
  };

  validateEducation = () => {
    var isValid = true;
    this.setState({
      education: this.state.data.education.map(item => {
        if (item.schoolName === "") {
          isValid = false;
        }
        return item;
      })
    });
    return isValid;
  };

  validateWorkExperience = () => {
    var isValid = true;
    this.setState({
      workExperience: this.state.data.workExperience.map(item => {
        if (item.jobTitle === "") {
          isValid = false;
        }
        return item;
      })
    });
    return isValid;
  };

  validateReferences = () => {
    var isValid = true;
    this.setState({
      references: this.state.data.references.map(item => {
        if (item.firstName === "") {
          isValid = false;
        }
        if (item.lastName === "") {
          isValid = false;
        }
        if (item.phoneNumber === "") {
          isValid = false;
        }
        if (item.email === "") {
          isValid = false;
        }
        return item;
      })
    });
    return isValid;
  };

  handleData = (d, step) => {
    this.setState({
      data: d,
      activeStep: step
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <LoadingIndicator display={this.state.dataLoading} />
        <Dialog
          open={this.props.open ? true : false}
          fullWidth={true}
          classes={{
            paper: classes.dialogPaper
          }}
          transitionDuration={{
            enter: 1000,
            exit: 0
          }}>
          <DialogTitle disableTypography id="bhiredinc" className={classes.root}>
            <Typography variant="h5" gutterBottom style={{ paddingTop: "10px", textAlign: "center" }}>
              Your Profile
            </Typography>
            <Button
              color="primary"
              variant="contained"
              aria-label="Update"
              className={classes.update}
              onClick={this.handleClickUpdate}>
              Update
            </Button>
            <IconButton
              aria-label="Close"
              color="primary"
              className={classes.closeButton}
              onClick={this.handleClickClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent align="center">
            <ProfileStepper handleData={this.handleData} activeStep={this.state.activeStep} />
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(withApi(EditProfile));
