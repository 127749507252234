import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/analytics";
import ApiContext, { withApi } from "./context";
var config = {
  apiKey: "AIzaSyAN-oacv2_tYArcID6b_XwEkMHh6N3xtrE",
  authDomain: "bhired-firebase-server.firebaseapp.com",
  databaseURL: "https://bhired-firebase-server.firebaseio.com",
  projectId: "bhired-firebase-server",
  storageBucket: "bhired-firebase-server.appspot.com",
  messagingSenderId: "1034374413338",
  appId: "1:1034374413338:web:0d91825162010709f6e5a3",
  measurementId: "G-X8T155DZ0D"
};

class Api {
  constructor() {
    app.initializeApp(config);
    app.analytics();
    this.auth = app.auth();
    this.db = app.firestore();
    this.bhiredUser = null;
    this.googleAuthProvider = new app.auth.GoogleAuthProvider();
    this.facebookAuthProvider = new app.auth.FacebookAuthProvider();
    this.githubAuthProvider = new app.auth.GithubAuthProvider();
    //this.API_BASE_URL = "http://localhost:8080";
    this.API_BASE_URL = "https://bhired-api-server.appspot.com";
  }

  doCreateUserWithEmailAndPassword = (email, password) => this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) => this.auth.signInWithEmailAndPassword(email, password);

  doSocialSignIn = oAuthProvider => {
    var provider;
    switch (oAuthProvider) {
      case "google.com":
        provider = this.googleAuthProvider;
        break;
      case "facebook.com":
        provider = this.facebookAuthProvider;
        break;
      case "github.com":
        provider = this.githubAuthProvider;
        break;
      default:
        return;
    }
    return this.auth.signInWithPopup(provider);
  };

  tryLinkingAccount = email => {
    return this.auth.fetchSignInMethodsForEmail(email);
  };

  doSignOut = () => {
    this.auth.signOut();
    //this.deleteJobIndex();
    //this.deleteUserIndex();
  };

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  sendVerificationEmail = () =>
    this.auth.currentUser
      .sendEmailVerification()
      .then(() => {
        this.auth.signOut();
      })
      .catch(error => {
        console.log(error);
      });

  createEmailPasswordCredential(email, password) {
    return app.auth.EmailAuthProvider.credential(email, password);
  }

  getAction(url) {
    return this.request({
      url: this.API_BASE_URL + url,
      method: "GET",
      type: "application/json"
    });
  }

  userOnboard(user) {
    return this.request({
      url: this.API_BASE_URL + "/api/user/onBoard",
      method: "POST",
      body: JSON.stringify(user),
      type: "application/json"
    });
  }

  uploadProfilePicture(formData) {
    return this.request({
      url: this.API_BASE_URL + "/api/user/avatar",
      method: "POST",
      body: formData,
      type: null
    });
  }

  uploadVideoProfilePicture(formData) {
    return this.request({
      url: this.API_BASE_URL + "/api/user/videoProfile",
      method: "POST",
      body: formData,
      type: null
    });
  }

  uploadProfileDocument(formData, category) {
    return this.request({
      url: this.API_BASE_URL + "/api/user/document/uploadProfileDocument/" + category,
      method: "POST",
      body: formData,
      type: null
    });
  }

  getProfileDocuments(category) {
    return this.request({
      url: this.API_BASE_URL + "/api/user/document/getProfileDocuments/" + category,
      method: "GET",
      type: "application/json"
    });
  }

  getUserPrefs() {
    return this.request({
      url: this.API_BASE_URL + "/api/user/userPreferences",
      method: "GET",
      type: "application/json"
    });
  }

  saveUserPrefs(prefs) {
    return this.request({
      url: this.API_BASE_URL + "/api/user/userPreferences/update/true",
      method: "PUT",
      body: JSON.stringify(prefs),
      type: "application/json"
    });
  }

  getVideoProfileURL() {
    let URL = this.API_BASE_URL + "/api/user/streamCurrentUserVideoProfile";
    return this.auth.currentUser.getIdToken(true).then(token => {
      const headers = new Headers({});
      headers.append("Authorization", "Bearer " + token);
      const defaults = {
        headers: headers
      };
      return fetch(URL, defaults).then(response => {
        return response.blob().then(blob => {
          return window.URL.createObjectURL(blob);
        });
      });
    });
  }

  initiateChat(chatInfo) {
    return this.request({
      url: this.API_BASE_URL + "/api/user/hive/initiateChat",
      method: "POST",
      body: JSON.stringify(chatInfo),
      type: "application/json"
    });
  }

  downloadProfileDocument(key) {
    const url = this.API_BASE_URL + "/api/user/document/downloadProfileDocument/" + key;
    this.requestDataDownload(url);
  }

  deleteProfileDocument(key) {
    return this.request({
      url: this.API_BASE_URL + "/api/user/document/deleteProfileDocument/" + key,
      method: "GET",
      type: "application/json"
    });
  }

  getMe() {
    return this.request({
      url: this.API_BASE_URL + "/api/user/me",
      method: "GET",
      type: "application/json"
    }).then(response => {
      this.bhiredUser = response;
      return JSON.parse(this.bhiredUser.active);
    });
  }

  getSocialObject(key) {
    return this.request({
      url: this.API_BASE_URL + "/api/user/getSocialObject/" + key,
      method: "GET",
      type: "application/json"
    }).then(response => {
      //console.log(response);
      return response;
    });
  }

  getTalentLitmusObject() {
    return this.request({
      url: this.API_BASE_URL + "/api/user/getTalentLitmusScores",
      method: "GET",
      type: "application/json"
    }).then(response => {
      console.log(response);
      return response;
    });
  }

  getProfileComplete() {
    return this.request({
      url: this.API_BASE_URL + "/api/user/profileComplete",
      method: "GET",
      type: "application/json"
    });
  }

  getMyAddressArray() {
    return this.request({
      url: this.API_BASE_URL + "/api/user/address/getMyAddressArray",
      method: "GET",
      type: "application/json"
    });
  }

  getMyEducationArray() {
    return this.request({
      url: this.API_BASE_URL + "/api/user/education/getMyEducationArray",
      method: "GET",
      type: "application/json"
    });
  }

  getMyExperienceArray() {
    return this.request({
      url: this.API_BASE_URL + "/api/user/experience/getMyExperienceArray",
      method: "GET",
      type: "application/json"
    });
  }

  getMySkillArray() {
    return this.request({
      url: this.API_BASE_URL + "/api/user/skill/getMySkillArray",
      method: "GET",
      type: "application/json"
    });
  }

  getSkillSuggestions() {
    return this.request({
      url: this.API_BASE_URL + "/api/user/skill/getSkillSuggestions",
      method: "GET",
      type: "application/json"
    });
  }

  getMyReferencesArray() {
    return this.request({
      url: this.API_BASE_URL + "/api/user/references/getMyReferencesArray",
      method: "GET",
      type: "application/json"
    });
  }

  deleteJobIndex() {
    return this.request({
      url: this.API_BASE_URL + "/api/search/deleteIndex/BhiredJobSearch",
      method: "POST",
      type: "application/json"
    });
  }

  deleteUserIndex() {
    return this.request({
      url: this.API_BASE_URL + "/api/search/deleteIndex/BhiredUserSearch",
      method: "POST",
      type: "application/json"
    });
  }

  getFullProfile() {
    return this.request({
      url: this.API_BASE_URL + "/api/user/userCompleteProfile",
      method: "GET",
      type: "application/json"
    });
  }

  getViewProfile(key) {
    return this.request({
      url: this.API_BASE_URL + "/api/user/userCompleteProfile/" + key,
      method: "GET",
      type: "application/json"
    });
  }

  updateMe(bhiredUser) {
    this.bhiredUser = bhiredUser;
  }

  createJob(job) {
    return this.request({
      url: this.API_BASE_URL + "/api/job/postNewJob",
      method: "POST",
      body: JSON.stringify(job),
      type: "application/json"
    });
  }

  createOrUpdateAddressArray(address) {
    return this.request({
      url: this.API_BASE_URL + "/api/user/address/createOrUpdate/true",
      method: "POST",
      body: JSON.stringify(address),
      type: "application/json"
    });
  }

  createOrUpdateEducationArray(education) {
    return this.request({
      url: this.API_BASE_URL + "/api/user/education/createOrUpdate/true",
      method: "POST",
      body: JSON.stringify(education),
      type: "application/json"
    });
  }

  createOrUpdateWorkExArray(workExperience) {
    return this.request({
      url: this.API_BASE_URL + "/api/user/experience/createOrUpdate/true",
      method: "POST",
      body: JSON.stringify(workExperience),
      type: "application/json"
    });
  }

  createOrUpdateSkillsArray(skills) {
    return this.request({
      url: this.API_BASE_URL + "/api/user/skill/createOrUpdate/true",
      method: "POST",
      body: JSON.stringify(skills),
      type: "application/json"
    });
  }

  createOrUpdateReferencesArray(references) {
    return this.request({
      url: this.API_BASE_URL + "/api/user/references/createOrUpdate/true",
      method: "POST",
      body: JSON.stringify(references),
      type: "application/json"
    });
  }

  searchJobs(payload) {
    return this.request({
      url: this.API_BASE_URL + "/api/search/filterJobs",
      method: "POST",
      body: JSON.stringify(payload),
      type: "application/json"
    });
  }

  recruiterJobs(payload) {
    return this.request({
      url: this.API_BASE_URL + "/api/search/getJobsByRecruiter",
      method: "POST",
      body: JSON.stringify(payload),
      type: "application/json"
    });
  }

  searchCandidates(payload) {
    return this.request({
      url: this.API_BASE_URL + "/api/search/getCandidates",
      method: "POST",
      body: JSON.stringify(payload),
      type: "application/json"
    });
  }

  searchHive(payload) {
    return this.request({
      url: this.API_BASE_URL + "/api/search/getConnections",
      method: "POST",
      body: JSON.stringify(payload),
      type: "application/json"
    });
  }

  getWalletBalance() {
    return this.request({
      url: this.API_BASE_URL + "/api/user/getMyWalletTokenTransactionsBalanceSheet",
      method: "POST",
      body: JSON.stringify({}),
      type: "application/json"
    });
  }

  getConnectionsReqSent(payload) {
    return this.request({
      url: this.API_BASE_URL + "/api/user/activities/getPendingRequestsSent",
      method: "POST",
      body: JSON.stringify(payload),
      type: "application/json"
    });
  }

  getConnectionsReqReceived(payload) {
    return this.request({
      url: this.API_BASE_URL + "/api/user/activities/getPendingRequestsReceived",
      method: "POST",
      body: JSON.stringify(payload),
      type: "application/json"
    });
  }

  getTransactions(payload) {
    return this.request({
      url: this.API_BASE_URL + "/api/user/myTokenTransactions",
      method: "POST",
      body: JSON.stringify(payload),
      type: "application/json"
    });
  }

  getJobSearchSuggestions(q) {
    return this.request({
      url: this.API_BASE_URL + "/api/job/jobSearchCompletionSuggestions/" + q,
      method: "GET",
      type: "application/json"
    });
  }

  getUserSearchSuggestions(q) {
    return this.request({
      url: this.API_BASE_URL + "/api/user/userSearchCompletionSuggestions/" + q,
      method: "GET",
      type: "application/json"
    });
  }

  updateUserBasicInfo(bhiredUser) {
    return this.request({
      url: this.API_BASE_URL + "/api/user/updateWithBasicProfileComplete/true",
      method: "PUT",
      body: JSON.stringify(bhiredUser),
      type: "application/json"
    });
  }

  requestConnection(artifactKey) {
    return this.request({
      url: this.API_BASE_URL + "/api/user/activities/requestConnection/" + artifactKey,
      method: "GET",
      type: "application/json"
    });
  }

  acceptConnection(artifactKey) {
    return this.request({
      url: this.API_BASE_URL + "/api/user/activities/acceptConnectionRequest/" + artifactKey,
      method: "GET",
      type: "application/json"
    });
  }

  rejectConnection(artifactKey) {
    return this.request({
      url: this.API_BASE_URL + "/api/user/activities/rejectConnectionRequest/" + artifactKey,
      method: "GET",
      type: "application/json"
    });
  }

  favoriteJob(artifactKey) {
    return this.request({
      url: this.API_BASE_URL + "/api/user/activities/favoriteJob/" + artifactKey,
      method: "GET",
      type: "application/json"
    });
  }

  unFavoriteJob(artifactKey) {
    return this.request({
      url: this.API_BASE_URL + "/api/user/activities/unFavoriteJob/" + artifactKey,
      method: "GET",
      type: "application/json"
    });
  }

  applyForJob(artifactKey) {
    return this.request({
      url: this.API_BASE_URL + "/api/user/activities/jobApply/" + artifactKey,
      method: "GET",
      type: "application/json"
    });
  }

  unlockProfile(artifactKey) {
    return this.request({
      url: this.API_BASE_URL + "/api/user/activities/unlockProfile/" + artifactKey,
      method: "GET",
      type: "application/json"
    });
  }

  acceptUnlockProfile(artifactKey) {
    return this.request({
      url: this.API_BASE_URL + "/api/user/activities/acceptUnlockProfile/" + artifactKey,
      method: "GET",
      type: "application/json"
    });
  }

  rejectUnlockProfile(artifactKey) {
    return this.request({
      url: this.API_BASE_URL + "/api/user/activities/rejectUnlockProfile/" + artifactKey,
      method: "GET",
      type: "application/json"
    });
  }

  favoriteProfile(artifactKey) {
    return this.request({
      url: this.API_BASE_URL + "/api/user/activities/favoriteProfile/" + artifactKey,
      method: "GET",
      type: "application/json"
    });
  }

  unFavoriteProfile(artifactKey) {
    return this.request({
      url: this.API_BASE_URL + "/api/user/activities/unFavoriteProfile/" + artifactKey,
      method: "GET",
      type: "application/json"
    });
  }

  request = async options => {
    const token = await this.auth.currentUser.getIdToken(true);
    //console.log(token);
    const headers = new Headers({});
    headers.append("Authorization", "Bearer " + token);
    if (options.type !== null) {
      headers.append("Content-Type", options.type);
    }
    const defaults = {
      headers: headers
    };
    options = Object.assign({}, defaults, options);
    return fetch(options.url, options)
      .then(this.checkStatus)
      .then(response => {
        if (response.status === 204) {
          return null;
        }
        return response.json().then(json => {
          return json;
        });
      });
  };

  checkStatus(response) {
    if (response.ok) {
      return response;
    }
    return response.json().then(error => {
      error.response = response;
      throw error;
    });
  }

  requestDataDownload = URL => {
    return this.auth.currentUser.getIdToken(true).then(token => {
      const headers = new Headers({});
      headers.append("Authorization", "Bearer " + token);
      const defaults = {
        headers: headers
      };
      return fetch(URL, defaults).then(response => {
        const filename = response.headers.get("Content-Disposition").split("filename=")[1];
        response.blob().then(blob => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = filename;
          a.click();
        });
      });
    });
  };
}
export default Api;
export { ApiContext, withApi };
