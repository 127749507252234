import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import NewJobInfo from "./newJobInfo";
import NewJobDetails from "./newJobDetails";
import AddSkills from "./addSkills";
import JobBudget from "./jobBudget";
import { withApi } from "../../Api";
import LoadingIndicator from "../../../common/loading";

const styles = theme => ({
  root: {
    width: "100%"
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  content: {
    marginBottom: theme.spacing(3)
  },
  message: {
    marginTop: theme.spacing(6)
  }
});

class NewJobStepper extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activeStep: 0, loading: false };
  }

  getSteps = () => {
    return ["Basic Info", "Details", "Skills & Industry", "Exp & Salary"];
  };

  getStepContent = (stepIndex, props) => {
    switch (stepIndex) {
      case 0:
        return (
          <NewJobInfo state={props.state} handleState={props.handleState} clearErrorMessage={props.clearErrorMessage} />
        );
      case 1:
        return (
          <NewJobDetails
            state={props.state}
            handleState={props.handleState}
            handleDateChange={props.handleDateChange}
          />
        );
      case 2:
        return <AddSkills state={props.state} handleState={props.handleState} />;
      case 3:
        return <JobBudget state={props.state} handleState={props.handleState} />;
      default:
        return "Unknown";
    }
  };

  handleNext = () => {
    if (this.props.isValidData()) {
      this.setState({
        loading: true
      });
      this.props.api.createJob(this.props.state).then(job => {
        this.props.handleKeyAndStatus(job.key, job.jobStatus);
        this.setState(state => ({
          activeStep: state.activeStep + 1,
          loading: false
        }));
      });
    }
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1
    }));
  };

  render() {
    const { classes } = this.props;
    const steps = this.getSteps();
    const { activeStep } = this.state;

    return (
      <React.Fragment>
        <LoadingIndicator display={this.state.loading} />
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map(label => {
            return (
              <Step key={label}>
                <StepLabel> {label} </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {this.state.activeStep === steps.length ? (
          <Typography variant="h6" gutterBottom className={classes.message}>
            All steps completed. Publish the Job Post or come back to publish. Press Close Icon to go back to Jobs Page.
          </Typography>
        ) : (
          <div>
            <div className={classes.content}> {this.getStepContent(activeStep, this.props)} </div>
            <div>
              <Button
                disabled={activeStep === 0}
                variant="contained"
                color="primary"
                onClick={this.handleBack}
                className={classes.backButton}>
                Back
              </Button>
              <Button variant="contained" color="primary" onClick={this.handleNext}>
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

NewJobStepper.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(withApi(NewJobStepper));
