import React, { Component } from "react";
import { AddCircle } from "@material-ui/icons";
import { withStyles, Button } from "@material-ui/core";
import WorkExperience from "./workExperience";

const styles = theme => ({
  main: {},
  fab: {}
});

class WorkExperienceWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workExperience: this.props.state
    };
  }

  onAddChild = () => {
    this.setState(
      prevState => ({
        workExperience: [...prevState.workExperience, this.props.defWorkExProps]
      }),
      () => {
        this.props.handleState(this.state.workExperience);
      }
    );
  };

  onRemoveMe = removeMeIndex => {
    let eArray = this.state.workExperience;
    var index = eArray.indexOf(removeMeIndex);
    eArray.splice(index, 1);
    this.setState(
      {
        workExperience: eArray
      },
      () => {
        this.props.handleState(this.state.workExperience);
      }
    );
  };

  handleState = (workExItem, index) => {
    const eds = this.state.workExperience.slice();
    eds[index] = workExItem;
    this.setState(
      {
        workExperience: eds
      },
      () => {
        this.props.handleState(this.state.workExperience);
      }
    );
  };

  renderChildren = () => {
    const workExArray = this.state.workExperience;
    const children = workExArray.map((workExItem, i) =>
      workExItem.active === true ? (
        <WorkExperience
          key={["workExperience", i].join("_")}
          state={workExItem}
          handleState={this.handleState}
          index={i}
          onRemoveMe={this.onRemoveMe}
          showRemoveMe={i > 0 ? true : false}
        />
      ) : null
    );
    return <div> {children} </div>;
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <main className={classes.main}>
          {this.renderChildren()}
          <Button color="primary" className={classes.fab} onClick={this.onAddChild}>
            <AddCircle />
            &nbsp; Add New
          </Button>
        </main>
        <div
          ref={el => {
            this.el = el;
          }}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(WorkExperienceWrapper);
