import React from "react";
import { compose } from "recompose";
import { withStyles, Grid } from "@material-ui/core";
import CandidateCard from "./candidateCard";

const styles = theme => ({
  root: {
    flexGrow: 1,
    display: "flex"
  }
});

class Candidates extends React.Component {
  render() {
    const { candidatesResultSet, classes } = this.props;
    return (
      <React.Fragment>
        <Grid container className={classes.root} alignItems="center" justify="center" spacing={3}>
          {candidatesResultSet.map((item, index) => (
            <Grid key={index} item xs={12}>
              <CandidateCard candidate={item.bhiredSearchObject} social={item.bhiredObject} />
            </Grid>
          ))}
        </Grid>
      </React.Fragment>
    );
  }
}

export default compose(withStyles(styles))(Candidates);
