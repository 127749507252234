import React from "react";
import { PieChart, Pie, ResponsiveContainer, Cell, Legend } from "recharts";
import { CardContent, Card, withStyles } from "@material-ui/core";
import grey from "@material-ui/core/colors/grey";
import PropTypes from "prop-types";
import { withApi } from "../../Api";
import { Redirect } from "react-router";

const styles = theme => ({
  card: { width: "92%", height: "99%", marginLeft: "5%", cursor: "auto" },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    color: grey[800]
  },
  edit: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    cursor: "pointer",
    textAlign: "center"
  }
});

const COLORS = ["#008000", "#1E90FF"];

class ProfileChart extends React.Component {
  dataLoaded = false;
  constructor(props) {
    super(props);
    this.state = {
      walletData: [{ name: "Credit", value: 0 }, { name: "Debit", value: 0 }],
      redirect: false
    };
  }

  componentDidMount() {
    this.dataLoaded = true;
    if (this.props.api.bhiredUser !== null) {
      this.props.api.getWalletBalance().then(walletData => {
        if (this.dataLoaded === true) {
          this.setState({
            walletData: [{ name: "Credit", value: walletData.credit }, { name: "Debit", value: walletData.debit }],
            activeIndex: 0
          });
        }
      });
    }
  }

  handleCardClick = () => {
    this.setState({
      redirect: true
    });
  };

  onPieEnter = index => {
    this.setState({
      activeIndex: index
    });
  };

  componentWillUnmount() {
    this.dataLoaded = false;
  }

  renderLabel = item => {
    if (item.name === "Credit") {
      return (
        <React.Fragment>
          <text fill={item.fill} transform="translate(-30)">
            <tspan x="50%" y="45%" dominantBaseline="middle" textAnchor="middle" dy="0em">
              {item.value} BHD
            </tspan>
          </text>
        </React.Fragment>
      );
    } else {
      return (
        <text fill={item.fill} transform="translate(-30)">
          <tspan x="50%" y="62%" dominantBaseline="middle" textAnchor="middle" dy="0em">
            {item.value} BHD
          </tspan>
        </text>
      );
    }
  };

  render() {
    const { classes } = this.props;
    //console.log(this.state.walletData);
    if (this.state.redirect === true) {
      return <Redirect to="/wallet" />;
    }
    return (
      <Card className={classes.card} onClick={this.handleCardClick}>
        <CardContent>
          {this.state.walletData !== undefined ? (
            <ResponsiveContainer height={125} width="100%">
              <PieChart>
                <Legend iconType="rect" layout="vertical" verticalAlign="middle" align="right" />
                <Pie
                  labelLine={false}
                  label={this.renderLabel}
                  data={this.state.walletData}
                  innerRadius={45}
                  outerRadius={63}
                  startAngle={90}
                  endAngle={-270}
                  dataKey="value">
                  <Cell key={0} fill={COLORS[0]} />
                  <Cell key={1} fill={COLORS[1]} />
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          ) : null}
        </CardContent>
      </Card>
    );
  }
}

ProfileChart.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withApi(ProfileChart));
