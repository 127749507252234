import React from "react";
import NewJobStepper from "./newJobStepper";
import { Dialog, DialogTitle, withStyles, Typography, DialogContent, IconButton, Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Cancel";
import Arrow from "@material-ui/icons/ArrowRight";
import LoadingIndicator from "../../../common/loading";
import { withApi } from "../../Api";

const styles = theme => ({
  dialogPaper: {
    minHeight: "100vh",
    maxHeight: "100vh",
    minWidth: "65%",
    maxWidth: "65%"
  },
  root: {
    margin: 0,
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    zIndex: 1600
  },
  closeButton: {
    position: "absolute",
    right: 0,
    top: theme.spacing()
  },
  status: {
    position: "absolute",
    top: "2.2%",
    cursor: "none",
    right: "50%",
    padding: "none",
    transform: "translate(50%)"
  },
  publish: {
    position: "absolute",
    right: "8%",
    top: theme.spacing(2),
    color: theme.palette.primary.contrastText
  },
  close: {
    position: "absolute",
    right: "25%",
    top: theme.spacing(2),
    color: theme.palette.primary.contrastText
  }
});

class EditJob extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.job,
      publishing: false,
      companyRequiredMessage: "",
      titleRequiredMessage: "",
      locationRequiredMessage: ""
    };
    //console.log(this.state);
  }

  handleState = event => {
    var propName = event.target.name;
    if ("industry" === propName) {
      this.setState({
        [event.target.name]: [event.target.value]
      });
    } else {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  };

  handleKeyAndStatus = (key, jobStatus) => {
    this.setState({
      key: key,
      jobStatus: jobStatus
    });
  };

  handleDateChange = date => {
    this.setState({
      startDate: date
    });
  };

  isValidData = () => {
    var isValid = true;
    if (this.state.company === "") {
      this.setState({
        companyRequiredMessage: "Please provide Company name"
      });
      isValid = false;
    } else {
      this.setState({
        companyRequiredMessage: ""
      });
    }
    if (this.state.title === "") {
      this.setState({
        titleRequiredMessage: "Please provide Job Title"
      });
      isValid = false;
    } else {
      this.setState({
        titleRequiredMessage: ""
      });
    }
    if (this.state.location === "") {
      this.setState({
        locationRequiredMessage: "Please provide Job Location"
      });
      isValid = false;
    } else {
      this.setState({
        locationRequiredMessage: ""
      });
    }
    return isValid;
  };

  clearErrorMessage = event => {
    if ("company" === event.target.name) {
      this.setState({
        companyRequiredMessage: ""
      });
    }
    if ("title" === event.target.name) {
      this.setState({
        titleRequiredMessage: ""
      });
    }
    if ("location" === event.target.name) {
      this.setState({
        locationRequiredMessage: ""
      });
    }
  };

  handleClickClose = () => {
    this.props.handleClickClose();
  };

  handleClickCloseJob = () => {
    this.setState(
      state => {
        state.jobStatus = "CLOSED";
        state.publishing = true;
      },
      () => {
        this.props.api.createJob(this.state).then(() => {
          this.setState({
            publishing: false
          });
          this.props.handleClickClose();
        });
      }
    );
  };

  handleClickPublish = () => {
    if (this.isValidData()) {
      const newStatus =
        this.state.jobStatus === "PUBLISH"
          ? "CLOSED"
          : this.state.jobStatus === "SAVED"
          ? "PUBLISH"
          : this.state.jobStatus === "CLOSED"
          ? "PUBLISH"
          : "SAVED";
      this.setState(
        state => {
          state.jobStatus = newStatus;
          state.publishing = true;
        },
        () => {
          this.props.api.createJob(this.state).then(() => {
            this.setState({
              publishing: false
            });
            this.props.handleClickPublish();
          });
        }
      );
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <LoadingIndicator display={this.state.publishing} />
        <Dialog
          open={this.props.open ? true : false}
          fullWidth={true}
          classes={{
            paper: classes.dialogPaper
          }}
          transitionDuration={{
            enter: 1000,
            exit: 0
          }}>
          <DialogTitle
            disableTypography
            id="bhiredinc"
            className={classes.root}
            style={{
              color: "#ffffff"
            }}>
            <div>
              <Typography
                variant="h5"
                style={{
                  paddingTop: "12px",
                  paddingLeft: "0px"
                }}>
                Edit Job Post
              </Typography>
              <Button color="primary" variant="outlined" className={classes.status}>
                Status <Arrow />
                {this.state.jobStatus === "PUBLISH"
                  ? "Open"
                  : this.state.jobStatus === "SAVED"
                  ? "Draft"
                  : this.state.jobStatus === "CLOSED"
                  ? "Closed"
                  : "New"}
              </Button>
              <Button
                color="primary"
                variant="contained"
                aria-label="Publish"
                className={classes.publish}
                onClick={this.handleClickPublish}>
                {this.state.jobStatus === "PUBLISH"
                  ? "Close"
                  : this.state.jobStatus === "SAVED"
                  ? "Publish"
                  : this.state.jobStatus === "CLOSED"
                  ? "Publish"
                  : "New"}
              </Button>
              <IconButton
                aria-label="Close"
                color="primary"
                className={classes.closeButton}
                onClick={this.handleClickClose}>
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent align="center">
            <NewJobStepper
              state={this.state}
              handleState={this.handleState}
              handleDateChange={this.handleDateChange}
              isValidData={this.isValidData}
              clearErrorMessage={this.clearErrorMessage}
              handleKeyAndStatus={this.handleKeyAndStatus}
            />
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(withApi(EditJob));
