import React from "react";
import { CssBaseline, withStyles } from "@material-ui/core";
import AssessmentCard from "./assessmentCard";

const styles = theme => ({
  root: {
    maxHeight: "78vh",
    width: "100%",
    overflowY: "auto",
    overflowX: "hidden"
  }
});

class Assessment extends React.Component {
  componentDidMount() {
  }

  componentWillUnmount() {
  }

  render() {
    return (
      <React.Fragment>
        <CssBaseline />
        <main><AssessmentCard/></main>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Assessment);
