import React from "react";
import PropTypes from "prop-types";
import { CardHeader, CardContent, Card, withStyles, Avatar, Typography, CssBaseline } from "@material-ui/core";
import { getAvatarColor } from "../../../common/helpers";
import { withApi } from "../../Api";
import Images from "../../../assets";

const styles = theme => ({
  card: {
    width: "98%",
    transitionDuration: "0.3s"
  },
  actions: {
    display: "flex"
  },
  type: {
    cursor: "none"
  }
});

class TransactionCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  capitalize = str => {
    return str.charAt(0).toUpperCase();
  };

  render() {
    const { classes, transaction } = this.props;
    const title = transaction !== undefined ? transaction.title : "";
    const eType =
      transaction !== undefined && transaction.fromEmail === this.props.api.bhiredUser.email ? "Paid" : "Received";
    const tokens = transaction !== undefined ? parseInt(transaction.tokensTransacted) : 0;
    const otherGuy =
      transaction !== undefined && transaction.fromEmail === this.props.api.bhiredUser.email
        ? transaction.toEmail
        : transaction.fromEmail;
    const rColor = transaction !== undefined ? getAvatarColor(otherGuy) : "";
    return (
      <React.Fragment>
        <CssBaseline />
        {transaction !== undefined ? (
          <Card className={classes.card}>
            <CardHeader
              avatar={
                <Avatar
                  aria-label="Avatar"
                  className={classes.avatar}
                  style={{
                    backgroundColor: rColor,
                    padding: "0px"
                  }}>
                  {this.capitalize(otherGuy)}
                </Avatar>
              }
              title={
                <React.Fragment>
                  <Typography variant="body1">{title}</Typography>
                  <Typography variant="body1" className={classes.type} />
                </React.Fragment>
              }
              subheader={
                <div>
                  {otherGuy}&nbsp;
                  {new Intl.DateTimeFormat("US", {
                    weekday: "short",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    year: "numeric",
                    month: "long",
                    day: "2-digit"
                  }).format(Date.parse(transaction.createdDate))}
                </div>
              }
            />
            <CardContent>
              <Typography variant="body1" style={{ textAlign: "center", color: "grey" }}>
                You&nbsp;{eType}&nbsp;{tokens}&nbsp;Bhired Tokens
                {eType === "Paid" ? (
                  <img src={Images.crying} height="52px" alt="bhiredinc" />
                ) : (
                  <img src={Images.dancing} height="52px" alt="bhiredinc" />
                )}
              </Typography>
            </CardContent>
          </Card>
        ) : null}
      </React.Fragment>
    );
  }
}

TransactionCard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withApi(TransactionCard));
