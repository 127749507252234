import React, { Component } from "react";
import {
  Paper,
  CssBaseline,
  FormControl,
  TextField,
  withStyles,
  MenuItem,
  InputLabel,
  Select,
  Grid,
  ListItemText,
  Checkbox,
  FormControlLabel,
  Button,
  IconButton
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import { RemoveCircle } from "@material-ui/icons";
import moment from "moment";
import { DropzoneArea } from "material-ui-dropzone";
import { withApi } from "../../Api";
import DownloadIcon from "@material-ui/icons/CloudDownloadSharp";
import DeleteIcon from "@material-ui/icons/DeleteOutline";

const styles = theme => ({
  main: {
    display: "flex",
    width: "100%",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(8),
    marginBottom: "10px"
  },
  paper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
  },
  itemText: {
    color: theme.palette.primary.main
  },
  itemTextSelected: {},
  checkBoxLabel: {
    fontSize: "16px"
  },
  remove: {},
  dropzone: {
    padding: "5px",
    marginTop: "10px",
    height: "10%",
    border: "1px dotted",
    background: "#FFF5EE"
  },
  dropzoneText: {
    fontSize: "18px"
  }
});

class Education extends Component {
  componentLoaded = false;
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.state,
      educationDocs: []
    };
  }

  onChange = event => {
    this.setState(
      {
        [event.target.name]: event.target.value
      },
      () => {
        this.props.handleState(this.state, this.props.index);
      }
    );
  };

  onRemoveMe = () => {
    if (this.state.key !== "") {
      this.setState(
        {
          active: false
        },
        () => {
          this.props.handleState(this.state, this.props.index);
        }
      );
    } else {
      this.props.onRemoveMe(this.props.index);
    }
  };

  handleStartYearChange = date => {
    this.setState(
      {
        startYear: moment(date)
      },
      () => {
        this.props.handleState(this.state, this.props.index);
      }
    );
  };

  handleEndYearChange = date => {
    this.setState(
      {
        endYear: moment(date)
      },
      () => {
        this.props.handleState(this.state, this.props.index);
      }
    );
  };

  handleCheckChange = name => event => {
    this.setState(
      {
        [name]: event.target.checked
      },
      () => {
        this.props.handleState(this.state, this.props.index);
      }
    );
  };

  validate = event => {
    if ("schoolName" === event.target.name) {
      if (this.state.schoolName !== "") {
        this.setState({
          schoolNameRequiredMessage: ""
        });
      } else {
        this.setState({
          schoolNameRequiredMessage: "School Name Required"
        });
      }
    }
  };

  downloadProfileDocument = key => {
    this.props.api.downloadProfileDocument(key);
  };

  deleteProfileDocument = key => {
    this.props.api.deleteProfileDocument(key).then(() => {
      const educationDocsUpdated = this.state.educationDocs.filter(item => item.key !== key);
      this.setState({
        educationDocs: educationDocsUpdated
      });
    });
  };

  onDrop = file => {
    let data = new FormData();
    data.append("file", file);
    data.append("name", file.name);
    this.props.api.uploadProfileDocument(data, "EDUCATION");
  };

  componentDidMount() {
    this.componentLoaded = true;
    this.props.api.getProfileDocuments("EDUCATION").then(educationDocs => {
      if (this.componentLoaded === true) {
        this.setState({
          educationDocs: educationDocs
        });
      }
    });
  }

  componentWillUnmount() {
    this.componentLoaded = false;
  }
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <main className={classes.main}>
          <CssBaseline />
          <Paper className={classes.paper}>
            {this.props.showRemoveMe === true ? (
              <div>
                <Button className={classes.remove} color="primary" onClick={this.onRemoveMe}>
                  <RemoveCircle />
                  &nbsp;Remove
                </Button>
              </div>
            ) : null}
            <form>
              <FormControl margin="normal" required fullWidth>
                <TextField
                  required
                  label="School Name (Or Certificate Program)"
                  placeholder="School Name (Or Certificate Program)"
                  name="schoolName"
                  value={this.state.schoolName}
                  onChange={this.onChange}
                  error={
                    this.state.schoolNameRequiredMessage === "" || this.state.schoolNameRequiredMessage === undefined
                      ? false
                      : true
                  }
                  helperText={this.state.schoolNameRequiredMessage}
                  onKeyDown={this.validate}
                  onKeyUp={this.validate}
                />
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="educationType-simple"> Education Type </InputLabel>
                <Select
                  autoWidth
                  inputProps={{
                    name: "educationType",
                    id: "educationType-simple"
                  }}
                  value={this.state.educationType}
                  name="educationType"
                  onChange={this.onChange}
                  style={{
                    textAlign: "left"
                  }}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "center"
                    }
                  }}>
                  <MenuItem value="HIGH_SCHOOL">
                    <ListItemText primary="High School" className={classes.itemTextSelected} />
                  </MenuItem>
                  <MenuItem value="VOCATIONAL_TRADE_SCHOOL">
                    <ListItemText primary="Vocational Trade School" className={classes.itemTextSelected} />
                  </MenuItem>
                  <MenuItem value="GED_PROGRAM">
                    <ListItemText primary="GED Program" className={classes.itemTextSelected} />
                  </MenuItem>
                  <MenuItem value="ASSOCIATE_DEGREE_PROGRAM">
                    <ListItemText primary="Associate Degree Program" className={classes.itemTextSelected} />
                  </MenuItem>
                  <MenuItem value="BACHELORS_DEGREE_PROGRAM">
                    <ListItemText primary="Bachelors Degree Program" className={classes.itemTextSelected} />
                  </MenuItem>
                  <MenuItem value="BACHELORS_SCIENCE">
                    <ListItemText primary="Bachelors In Science" className={classes.itemTextSelected} />
                  </MenuItem>
                  <MenuItem value="BACHELORS_TECHNOLOGY">
                    <ListItemText primary="Bachelors In Technology" className={classes.itemTextSelected} />
                  </MenuItem>
                  <MenuItem value="MASTERS_DEGREE_PROGRAM">
                    <ListItemText primary="Masters Degree Program" className={classes.itemTextSelected} />
                  </MenuItem>
                  <MenuItem value="MASTERS_BUSINESS_ADMINISTRATION">
                    <ListItemText primary="MBA" className={classes.itemTextSelected} />
                  </MenuItem>
                  <MenuItem value="MASTERS_SCIENCE">
                    <ListItemText primary="Masters In Science" className={classes.itemTextSelected} />
                  </MenuItem>
                  <MenuItem value="MASTERS_TECHNOLOGY">
                    <ListItemText primary="Masters In Technology" className={classes.itemTextSelected} />
                  </MenuItem>
                  <MenuItem value="DOCTORATE_PROGRAM">
                    <ListItemText primary="Doctorate Program" className={classes.itemTextSelected} />
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <TextField
                  label="Degree (Or Field of Study)"
                  placeholder="Degree (Or Field of Study)"
                  name="degree"
                  value={this.state.degree}
                  onChange={this.onChange}
                />
              </FormControl>
              <Grid container alignItems="center" justify="center" spacing={2}>
                <Grid item xs={6}>
                  <FormControl margin="normal" fullWidth>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        animateYearScrolling
                        margin="normal"
                        openTo="year"
                        label="Start Year"
                        value={this.state.startYear}
                        onChange={this.handleStartYearChange}
                        minDate={new Date("01/01/1971")}
                        format="yyyy"
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl margin="normal" fullWidth>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        animateYearScrolling
                        margin="normal"
                        openTo="year"
                        label="Start Year"
                        value={this.state.endYear}
                        onChange={this.handleEndYearChange}
                        minDate={new Date("01/01/1971")}
                        format="yyyy"
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>
              </Grid>
              <FormControl>
                <FormControlLabel
                  labelPlacement="start"
                  classes={{
                    label: classes.checkBoxLabel
                  }}
                  control={
                    <Checkbox
                      checked={this.state.didYouGraduate}
                      onChange={this.handleCheckChange("didYouGraduate")}
                      value="didYouGraduate"
                    />
                  }
                  label="Did you graduate?"
                />
              </FormControl>
            </form>
            <div>
              <Grid
                container
                spacing={2}
                style={{
                  paddingTop: "2%"
                }}>
                {this.state.educationDocs !== undefined
                  ? this.state.educationDocs.map((item, index) => (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        style={{
                          paddingTop: "10px",
                          background: "#FFF5EE",
                          margin: "5px",
                          textAlign: "left",
                          borderRadius: "2%"
                        }}>
                        {item.fileName}
                        <div style={{ float: "right" }}>
                          <IconButton
                            color="primary"
                            variant="outlined"
                            onClick={() => this.downloadProfileDocument(item.key)}>
                            <DownloadIcon />
                          </IconButton>
                          <IconButton
                            color="primary"
                            variant="outlined"
                            onClick={() => this.deleteProfileDocument(item.key)}>
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      </Grid>
                    ))
                  : null}
              </Grid>
              <DropzoneArea
                dropZoneClass={classes.dropzone}
                dropzoneParagraphClass={classes.dropzoneText}
                filesLimit={5}
                onDrop={this.onDrop}
                minSize={0}
                maxSize={5242880}
                multiple>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                  </div>
                )}
              </DropzoneArea>
            </div>
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(withApi(Education));
