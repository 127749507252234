import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Typography,
  Button,
  FormControl,
  withStyles,
  Radio,
  RadioGroup,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@material-ui/core";
import * as Routes from "../../common/routes";
import { withAuthorization } from "../Session";
import { compose } from "recompose";
import Images from "../../assets";
import LoadingIndicator from "../../common/loading";

const styles = theme => ({
  form: {
    padding: "50px"
  },
  group: {
    flexDirection: "row",
    justifyContent: "space-around",
    display: "flex"
  }
});

const INITIAL_STATE = {
  open: true,
  acceptTerms: true,
  acceptGDPR: true,
  roles: ["ROLE_USER"],
  loading: false
};

class RoleSelector extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  handleDisagree = () => {
    this.setState({
      open: false
    });
    this.props.history.push(Routes.TERMS_AND_CONDITIONS);
  };

  handleNext = event => {
    this.setState({
      state: false,
      loading: true
    });
    this.persist();
    event.preventDefault();
  };

  onChange = event => {
    this.setState({
      roles: [event.target.value]
    });
  };

  persist() {
    this.props.api.userOnboard(this.state).then(result => {
      this.props.api.updateMe(result);
      this.setState(
        {
          loading: false
        },
        () => {
          this.props.history.push(Routes.HOME);
        }
      );
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <LoadingIndicator display={this.state.loading} />
        <Dialog
          open={this.state.open}
          scroll={this.state.scroll}
          aria-labelledby="bhiredinc"
          fullWidth={true}
          BackdropProps={{
            style: {
              backgroundColor: "#ffffff"
            }
          }}
        >
          <DialogTitle id="bhiredinc" align="center">
            <div>
              <img
                style={{
                  width: "25%",
                  height: "auto"
                }}
                src={Images.logo}
                alt="bhiredinc"
              />
            </div>
            <div> Please Select a Role </div>
          </DialogTitle>
          <DialogContent>
            <form>
              <FormControl
                required
                fullWidth
                style={{
                  paddingBottom: "25px",
                  paddingTop: "25px"
                }}
              >
                <RadioGroup
                  aria-label="type"
                  name="role"
                  className={classes.group}
                  value={this.state.roles[0]}
                  onChange={this.onChange}
                >
                  <FormControlLabel
                    value="ROLE_USER"
                    control={<Radio />}
                    label={
                      <React.Fragment>
                        <div>
                          <img
                            src={Images.candidate}
                            style={{
                              width: "75%",
                              height: "auto"
                            }}
                            alt="candidate"
                          />
                        </div>
                        <Typography variant="body2"> Job Candidate </Typography>
                      </React.Fragment>
                    }
                  />
                  <FormControlLabel
                    value="ROLE_RECRUITER"
                    control={<Radio />}
                    label={
                      <React.Fragment>
                        <div>
                          <img
                            src={Images.recruiter}
                            style={{
                              width: "85%",
                              height: "auto"
                            }}
                            alt="recruiter"
                          />
                        </div>
                        <Typography variant="body2"> Recruiter </Typography>
                      </React.Fragment>
                    }
                  />
                </RadioGroup>
              </FormControl>
              <DialogActions>
                <Button onClick={this.handleDisagree} color="primary">
                  Go Back
                </Button>
                <Button onClick={this.handleNext} color="primary">
                  Next
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}

const condition = authUser => !!authUser;
export default compose(
  withAuthorization(condition),
  withRouter,
  withStyles(styles)
)(RoleSelector);
