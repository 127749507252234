import React from "react";
import { CssBaseline, withStyles, Grid, Typography } from "@material-ui/core";
import { withAuthorization } from "../../Session";
import Transactions from "./transactions";
import LoadingIndicator from "../../../common/loading";

const styles = theme => ({
  root: {
    height: "88%",
    width: "98%",
    overflowY: "auto",
    overflowX: "hidden",
    ...theme.mixins.toolbar
  },
  header: {
    color: "#B9090B",
    marginBottom: "10px",
    textAlign: "center",
    fontSize: theme.typography.pxToRem(16),
    flexShrink: 0
  },
  fab: {
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
    top: "2%",
    right: "40%",
    position: "absolute",
    zIndex: 1600,
    background: "#ffffff",
    transform: "translate(40%)"
  },
  postIcon: {
    border: "none",
    margin: "none"
  }
});

const updateTransactionsResult = (result, showingCount) => prevState => ({
  transactionsLoading: false,
  transactionsResultSet: [...prevState.transactionsResultSet, ...result.resultSet],
  totalCount: result.totalCount,
  showingCount: parseInt(showingCount, 10) + parseInt(result.returnedCount, 10),
  cursor: result.cursor,
  totalTokens: result.totalTokens
});

const setTransactionsResult = result => () => ({
  transactionsLoading: false,
  transactionsResultSet: result.resultSet,
  totalCount: result.totalCount,
  showingCount: result.returnedCount,
  cursor: result.cursor,
  totalTokens: result.totalTokens
});

class Wallet extends React.Component {
  dataLoaded = false;
  constructor(props) {
    super(props);
    this.state = {
      totalTokens: 0,
      cursor: "",
      transactionsLoading: false,
      transactionsResultSet: [],
      showingCount: 0,
      totalCount: 0
    };
    this.registerTokensListener();
  }

  registerTokensListener = () => {
    this.props.api.db
      .collection("tokens")
      .doc(this.props.api.auth.currentUser.email)
      .onSnapshot(doc => {
        if (this.dataLoaded === true) {
          if (doc.data() !== undefined) {
            this.setState({
              totalTokens: doc.data().totalTokens
            });
          }
        }
      });
  };

  getTransactions = () => {
    this.setState({
      transactionsLoading: true
    });
    this.props.api
      .getTransactions({
        cursor: this.state.cursor,
        pageSize: 50
      })
      .then(result => {
        if (this.dataLoaded === true) {
          this.state.showingCount === 0
            ? this.setState(setTransactionsResult(result))
            : this.setState(updateTransactionsResult(result, this.state.showingCount));
        }
      });
  };

  componentDidMount() {
    this.dataLoaded = true;
    this.refs.iScroll.addEventListener("scroll", () => {
      if (this.refs.iScroll.scrollTop + this.refs.iScroll.clientHeight + 1 >= this.refs.iScroll.scrollHeight) {
        if (!this.state.transactionsLoading && this.state.transactionsResultSet.length) {
          if (parseInt(this.state.showingCount, 5) < parseInt(this.state.totalCount, 5)) {
            this.getTransactions();
          }
        }
      }
    });
    if (this.state.transactionsResultSet.length === 0) {
      this.getTransactions();
    }
  }

  componentWillUnmount() {
    this.dataLoaded = false;
    this.refs.iScroll.removeEventListener("scroll", this.onScroll, false);
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <LoadingIndicator display={this.state.transactionsLoading} />
        <Grid container justify="center">
          <Typography className={classes.header}>
            Your Bhired Tokens Count: {this.state.totalTokens}
          </Typography>
        </Grid>
        <CssBaseline />
        <main ref="iScroll" className={classes.root}>
          <Transactions transactionsResultSet={this.state.transactionsResultSet} />
        </main>
      </React.Fragment>
    );
  }
}

const condition = authUser => !!authUser;
export default withStyles(styles)(withAuthorization(condition)(Wallet));
