import React from "react";
import { compose } from "recompose";
import { withStyles, Grid, Typography } from "@material-ui/core";
import TransactionCard from "./transactionCard";

const styles = theme => ({
  root: {
    flexGrow: 1,
    display: "flex"
  }
});

class Transactions extends React.Component {
  render() {
    const { transactionsResultSet, classes } = this.props;
    return (
      <React.Fragment>
        <Grid container className={classes.root} alignItems="center" justify="center" spacing={2}>
          {transactionsResultSet !== undefined
            ? transactionsResultSet.map((item, index) => (
                <Grid key={index} item xs={6}>
                  <TransactionCard transaction={item.bhiredObject} />
                </Grid>
              ))
            : null}
        </Grid>
        {transactionsResultSet.length === 0 ? (
          <Grid container style={{ padding: "50px" }} alignItems="center" justify="center" spacing={2}>
            <Typography
              style={{
                padding: "50px",
                color: "grey"
              }}
              variant="h5">
              No Transactions Found
            </Typography>
          </Grid>
        ) : null}
      </React.Fragment>
    );
  }
}

export default compose(withStyles(styles))(Transactions);
