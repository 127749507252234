import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import red from "@material-ui/core/colors/red";
import { withApi } from "../../Api";

const styles = theme => ({
  symbol: {
    color: "#B9090B",
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0
  }
});

class ProfileComplete extends React.Component {
  componentLoaded = false;
  constructor(props) {
    super(props);
    this.state = { profileCompleteness: this.props.profileCompletion };
  }

  componentDidMount() {
    this.componentLoaded = true;
    this.registerTokensListener();
  }

  componentWillUnmount() {
    this.componentLoaded = false;
  }

  registerTokensListener = () => {
    this.props.api.db
      .collection("profile")
      .doc(this.props.api.auth.currentUser.email)
      .onSnapshot(doc => {
        if (this.componentLoaded === true) {
          if (doc.data() !== undefined)
          {
            this.setState({
              profileCompleteness: doc.data().profileCompletion
            });
          }
        }
      });
  };

  render() {
    const { classes } = this.props;
    const symbol = this.state.profileCompleteness + "%";
    return (
      <Progress
        theme={{
          active: {
            symbol: symbol,
            trailColor: red[100],
            color: "#B9090B"
          }
        }}
        percent={this.state.profileCompleteness}
        symbolClassName={classes.symbol}
      />
    );
  }
}

ProfileComplete.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withApi(ProfileComplete));
