import React from "react";
import { CssBaseline, withStyles } from "@material-ui/core";
import { withAuthorization } from "../../Session";
import LoadingIndicator from "../../../common/loading";
import Candidates from "./candidates";

const styles = theme => ({
  root: {
    height: "98%",
    width: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    ...theme.mixins.toolbar
  }
});

class CandidatesPage extends React.Component {
  componentDidMount() {
    this.refs.iScroll.addEventListener("scroll", () => {
      if (this.refs.iScroll.scrollTop + this.refs.iScroll.clientHeight + 1 >= this.refs.iScroll.scrollHeight) {
        if (!this.props.candidatesLoading && this.props.candidatesResultSet.length) {
          if (parseInt(this.props.showingCount, 10) < parseInt(this.props.totalCount, 10)) {
            this.props.searchCandidates("BHired_CURRENT_QUERY");
          }
        }
      }
    });
    if (this.props.candidatesResultSet.length === 0) {
      this.props.searchCandidates("");
    }
  }

  componentWillUnmount() {
    this.refs.iScroll.removeEventListener("scroll", this.onScroll, false);
  }

  render() {
    const { classes, role } = this.props;
    return (
      <React.Fragment>
        {role === "ROLE_RECRUITER" ? (
          <React.Fragment>
            <LoadingIndicator display={this.props.candidatesLoading} /> <CssBaseline />
            <main ref="iScroll" className={classes.root}>
              <Candidates candidatesResultSet={this.props.candidatesResultSet} />
            </main>
          </React.Fragment>
        ) : (
          <main ref="iScroll" className={classes.root}>
            <div> UNAUTHORIZED </div>
          </main>
        )}
      </React.Fragment>
    );
  }
}

const condition = authUser => !!authUser;
export default withStyles(styles)(withAuthorization(condition)(CandidatesPage));
