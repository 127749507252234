import React from "react";
import HomeIcon from "@material-ui/icons/Home";
import ProfileIcon from "@material-ui/icons/PersonOutlined";
import JobsIcon from "@material-ui/icons/WorkOutlined";
import CandidatesIcon from "@material-ui/icons/PeopleOutlined";
import WalletIcon from "@material-ui/icons/AccountBalanceWallet";
import HiveIcon from "@material-ui/icons/People";
import AssessmentIcon from "@material-ui/icons/Assessment";
import { MenuList, withStyles, ListItemIcon, ListItemText, MenuItem, Badge } from "@material-ui/core";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import * as Routes from "../../common/routes";

const styles = theme => ({
  root: { width: "100%" },
  itemText: {
    color: theme.palette.primary.main
  },
  itemTextSelected: {
    color: "#fff"
  },
  itemIconSelected: {
    color: "#fff"
  }
});

class MenuBar extends React.Component {
  render() {
    const { classes, pathname, role } = this.props;
    let { selected } = 0;
    switch (pathname) {
      case "/":
        selected = 0;
        break;
      case "/dashboard":
        selected = 0;
        break;
      case "/profile":
        selected = 1;
        break;
      case "/jobs":
        selected = 2;
        break;
      case "/candidates":
        selected = 3;
        break;
      case "/wallet":
        selected = 4;
        break;
      case "/hive":
        selected = 5;
        break;
      case "/assessments":
        selected = 6;
        break;
      default:
        return;
    }

    return (
      <MenuList className={classes.root}>
        <MenuItem
          classes={{
            selected: classes.selected
          }}
          selected={selected === 0}
          component={Link}
          to={Routes.DASHBOARD}
        >
          <ListItemIcon className={selected === 0 ? classes.itemIconSelected : null}>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" className={selected === 0 ? classes.itemTextSelected : classes.itemText} />
        </MenuItem>
        <MenuItem selected={selected === 1} component={Link} to={Routes.PROFILE}>
          <ListItemIcon className={selected === 1 ? classes.itemIconSelected : null}>
            <ProfileIcon />
          </ListItemIcon>
          <ListItemText primary="Profile" className={selected === 1 ? classes.itemTextSelected : classes.itemText} />
        </MenuItem>
        <MenuItem selected={selected === 2} component={Link} to={Routes.JOBS}>
          <ListItemIcon className={selected === 2 ? classes.itemIconSelected : null}>
            <JobsIcon />
          </ListItemIcon>
          <ListItemText primary="Jobs" className={selected === 2 ? classes.itemTextSelected : classes.itemText} />
        </MenuItem>
        {role === "ROLE_RECRUITER" ? (
          <MenuItem selected={selected === 3} component={Link} to={Routes.CANDIDATES}>
            <ListItemIcon className={selected === 3 ? classes.itemIconSelected : null}>
              <CandidatesIcon />
            </ListItemIcon>
            <ListItemText primary="Candidates" className={selected === 3 ? classes.itemTextSelected : classes.itemText} />
          </MenuItem>
        ) : null}
        <MenuItem selected={selected === 4} component={Link} to={Routes.WALLET}>
          <ListItemIcon className={selected === 4 ? classes.itemIconSelected : null}>
            <WalletIcon />
          </ListItemIcon>
          <ListItemText primary="Wallet" className={selected === 4 ? classes.itemTextSelected : classes.itemText} />
        </MenuItem>
        <MenuItem selected={selected === 5} component={Link} to={Routes.HIVE}>
          <ListItemIcon className={selected === 5 ? classes.itemIconSelected : null}>
            <Badge badgeContent={this.props.totalMessages} max={9999999} color="secondary" title="New Messages">
              <HiveIcon />
            </Badge>
          </ListItemIcon>
          <ListItemText primary="Hive" className={selected === 5 ? classes.itemTextSelected : classes.itemText} />
        </MenuItem>
        <MenuItem selected={selected === 6} component={Link} to={Routes.ASSESSMENTS}>
          <ListItemIcon className={selected === 6 ? classes.itemIconSelected : null}>
            <AssessmentIcon />
          </ListItemIcon>
          <ListItemText primary="Assessments" className={selected === 6 ? classes.itemTextSelected : classes.itemText} />
        </MenuItem>
      </MenuList>
    );
  }
}

MenuBar.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(styles)(MenuBar);
